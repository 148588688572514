import React, { useState, useEffect } from "react";
import "../../assets/css/dashboard.css";
import "../../assets/css/metricCard.css";
import MetricCard from "../../components/MetricCard";
import HumidityCard from "../../components/HumidityCard";
import TempDoughnut from "../../components/TempDoughnut";
import HumDonut from "../../components/HumDonut";
import User from "./User";
import Battery from "./Battery";
import Notification from "./Notification";
import DashboardService from "../../Services/MatricCardService";
import NotificationService from "../../Services/NotificationService";
import BasicSettingService from '../../Services/BasicSettingService'
import Loader from "../../common/Loader";
import { Link, useNavigate } from "react-router-dom"
import LastFiveNotification from "./LastFiveNotification";
// import BatteryVoltage from "./BatteryVoltage";

const Index = (props) => {
    const [roles, setRoles] = useState("admin");
    const [humActive, setHumActive] = useState([]);
    const [humTotal, setHumTotal] = useState([]);
    const [humConnection, setHumConnection] = useState([]);
    const [tempActive, setTempActive] = useState([]);
    const [batteryCount,setBatteryCount] = useState([]);
    const [tempTotal, setTempTotal] = useState([]);
    const [tempConnection, setTempConnection] = useState([]);
    const [tempHumTherCount, setTempHumTherCount] = useState([]);
    const [user, setUser] = useState([]);
    const [notification, setNotification] = useState([]);
    const [userLoaded, setUserLoaded] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        activeTemp();
        battCount();
        totalTemp();
        connectionTemp();
        activeHum();
        totalHum();
        connectionHum();
        userInfo();
        notificationList();
        getTemHumThresholdCount()
    }, []);


    const activeTemp = () => {
        DashboardService.activeCount()
            .then(
                (res) => (
                    setTempActive(res.data.data.response),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const battCount = () => {
        DashboardService.batteryCount()
        .then((res) => {
            setBatteryCount(res.data.data.response)
        })
        .catch((err) => console.log(err))
    }

    const totalTemp = () => {
        DashboardService.totalCount()
            .then(
                (res) => (
                    setTempTotal(res.data.data.response),
                    setUserLoaded(true)
                )
            )

            .catch((err) => console.log(err));
    };

    const connectionTemp = () => {
        DashboardService.connectionCount()
            .then(
                (res) => (
                    setTempConnection(res.data.data.response),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const activeHum = () => {
        DashboardService.humActiveCount()
            .then(
                (res) => (
                    setHumActive(res.data.data.response),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const totalHum = () => {
        DashboardService.humTotalCount()
            .then(
                (res) => (
                    setHumTotal(res.data.data.response),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const connectionHum = () => {
        DashboardService.humConnectionCount()
            .then(
                (res) => (
                    setHumConnection(res.data.data.response),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const userInfo = () => {
        BasicSettingService.userDetails()
            .then(
                (res) => (
                    setUser(res.data.data.response.UserData),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const notificationList = () => {
        NotificationService.notificationCount()
            .then(
                (res) => (
                    setNotification(res.data.data.response.notificationsCount),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    };

    const getTemHumThresholdCount = () => {
        DashboardService.thresholdCount()
            .then(
                (res) => (
                    
                    setTempHumTherCount(res.data.data.response.thresholdData),
                    setUserLoaded(true)
                )
            )
            .catch((err) => console.log(err));
    }

    return (
        <>{userLoaded ? (
            <div className="dashboard-sec">
                <div className="row space">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="temp">
                            <h6 className="humidity-text">
                                Temperature Sensor
                            </h6>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4" >
                                    <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Temp"

                                    }} to={`/${roles}/activesensor`}>
                                        <MetricCard
                                            metricData={tempTotal}
                                            TotalSensor
                                            image={"total.png"} />
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                    <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Temp"
                                    }}
                                        to={`/${roles}/activesensors`}>
                                        <MetricCard
                                            metricData={tempActive}
                                            ActiveSensor
                                            image={"active.png"} />
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 point">
                                <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Temp",
                                        noComm:true
                                    }}
                                        to={`/${roles}/activesensors`}>
                                    <MetricCard
                                        metricData={tempConnection}
                                        NoComm
                                        image={"warning.png"} />
                                    </Link>

                                </div>
                            </div>
                            <h6 className="humidity-text mt-2">
                                Humidity Sensor
                            </h6>
                            <div className="row ">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                    <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Humidity"
                                    }
                                    }
                                        to={`/${roles}/activesensor`}>
                                        <HumidityCard
                                            metricData={humTotal}
                                            TotalSensor
                                            image={"h3.png"} />
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                    <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Humidity"
                                    }}
                                        to={`/${roles}/activesensors`} >
                                        <HumidityCard
                                            metricData={humActive}
                                            ActiveSensor
                                            image={"h1.png"} />
                                    </Link>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 point">
                                <Link state={{
                                        HeadingName: "All Sensor Details",
                                        tempCard: "Humidity",
                                        noComm:true

                                    }}
                                        to={`/${roles}/activesensors`}>
                                    <HumidityCard
                                        metricData={humConnection}
                                        NoComm
                                        image={"h2.png"} />
                                    </Link>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-8 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                <Notification data={notification} />
                            </div>
                            <div className="col-12 col-sm-4 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                {/* <User userData={user} /> */}
                                <LastFiveNotification />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                <div className="charts">
                                    <h6 className="hum-text m-0">
                                        Temperature Sensor
                                    </h6>
                                    <TempDoughnut temDonut={{tempCard: "Temp"}}
                                        temHumdonut={tempHumTherCount} />
                                    <h6 className="hum-text m-0">
                                        Humidity Sensor
                                    </h6>
                                    <HumDonut humdonut={{tempCard: "Humidity"}} 
                                        temHumdonut={tempHumTherCount} />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                <Battery batterydata={batteryCount} />
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <BatteryVoltage/>
                    </div> */}
                </div>
            </div>
        ) : (
            <Loader />
        )}
        </>
    );
};

export default Index;
