import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL;

const sensorRange = async () => {
    return await axios.get(API_URL + "sensorresource/sensordata/1", { headers: AuthHeader() });
};

const getSensorByClientId = async (params) => {
    return await axios.get(API_URL + "master/sensordetails/" + params.toString(), { headers: AuthHeader() });
};

const sensorByClientId = async (params) => {
    return await axios.get(API_URL + "sensorresource/sensordata/" + params.toString(), { headers: AuthHeader() });
};

const sensorBydonutTemHum = async (params) => {
    return await axios.post(API_URL + "sensorresource/sensordata",params, { headers: AuthHeader() });
};


const SensorService = {
    sensorRange,
    getSensorByClientId,
    sensorByClientId,
    sensorBydonutTemHum

};

export default SensorService;
