import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/css/sensors.css";
import AnalogCard from "../../components/AnalogCard";
import DigitalCard from "../../components/DigitalCard";
import DataView from "./DataView";
import SensorService from "../../Services/SensorService";
import { useLocation } from "react-router";
import Loader from "../../common/Loader";

const Index = () => {
    const [analogView, SetAnalogView] = useState(false);
    const [digitalView, SetDigitalView] = useState(true);
    const [analog, SetAnalog] = useState([]);
    const [view, setView] = useState("");
    const [sensorId, SetSensorId] = useState([]);
    const [userLoaded, setUserLoaded] = useState(true);

    const [dataType, SetDataType] = useState("Temperature");

    const location = useLocation()
    const isNoData = location.state?.noData;

    const NoCommunication = location.state.NoComm

    const disable = location.state.data;


    const NoComm = location.state.tempCard

    useEffect(() => {
        const payload = {
            "clientId": 1,
            "thresholdType": location?.state?.TH
        }
        { location?.state?.TH ? sensorSentByHumTem(payload) : location?.state?.id ? sensorSentByClientId(location?.state?.id) : Analog() }

    }, []);


    const handleView = (get, val, data) => {
        setView(get);
        SetDigitalView(false);
        SetAnalogView(false);
        SetSensorId(val)
        SetDataType(data)
    };

    const Analog = () => {
        if (location.state.noComm) {
            SensorService.sensorRange()
                .then((res) => (
                    // SetAnalog([]),
                    SetAnalog(res.data.data.response.sensorData.filter((data) => data.sensorDetails[0].sensorAvailability === "offline") || []),
                    setUserLoaded(false)
                )).catch((err) => console.log(err));
        } else {
            SensorService.sensorRange()
                .then((res) => (
                    // let noCommData = res.data.data.response.sensorData
                    SetAnalog(res.data.data.response.sensorData),
                    setUserLoaded(false)
                )).catch((err) => console.log(err));
        }

    };


    const sensorSentByClientId = (params) => {
        SensorService.sensorByClientId(params)
            .then((res) => (
                SetAnalog(res.data.data.response.sensorData),
                setUserLoaded(false)
            )
            ).catch((error) => {
            })
    };

    const sensorSentByHumTem = (params) => {
        SensorService.sensorBydonutTemHum(params)
            .then((res) => (
                SetAnalog(res.data.data.response.sensorData),
                setUserLoaded(false)
            )
            ).catch((error) => {
            })
    };

    const onToggleAnalog = (e) => {
        SetAnalogView(true);
        SetDigitalView(false);
    };
    const onToggleDigital = (e) => {
        SetDigitalView(true);
        SetAnalogView(false);
    };


    return (
        <>
            {!userLoaded ? (<>

                {!disable && analog?.length > 0 ? (
                    <div className="row space">
                        <div className="analog-sec sensor">
                            <div className="col-12 col-md-12 col-lg-12 ">
                                <div className=" view-text">
                                    {digitalView && disable != 0 && "Digital View"}
                                    {analogView && disable != 0 && "Analog View"}

                                    {digitalView && disable != 0 || analogView && disable != 0 ? (
                                        <span className="view-img">
                                            <svg
                                                className={
                                                    "digitalViewImg" +
                                                    " " +
                                                    (digitalView && "change-svg") ||
                                                    "dgtal svg"
                                                }
                                                onClick={(e) => onToggleDigital(e)}
                                                width="32px"
                                                height="32px"
                                                viewBox="0 -64 640 640"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M476 480H324a36 36 0 0 1-36-36V96h-96v156a36 36 0 0 1-36 36H16a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h112V68a36 36 0 0 1 36-36h152a36 36 0 0 1 36 36v348h96V260a36 36 0 0 1 36-36h140a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16H512v156a36 36 0 0 1-36 36z" />
                                            </svg>
                                            <svg
                                                className={
                                                    "analogViewSvg" +
                                                    " " +
                                                    (analogView && "change-svg") ||
                                                    "dgtal svg"
                                                }
                                                onClick={(e) => onToggleAnalog(e)}
                                                width="32px"
                                                height="32px"
                                                viewBox="0 0 512 512"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill="#3c3b54"
                                                    d="M432,32a63.973,63.973,0,0,0-34.344,117.963L355.894,296.13c-1.289-.078-2.585-.13-3.894-.13a63.659,63.659,0,0,0-38.193,12.678l-77.154-64.295A64,64,0,1,0,131.259,269.7L85.967,360.288C84,360.105,82.013,360,80,360a64.082,64.082,0,1,0,36.243,11.29l42.8-85.589a63.845,63.845,0,0,0,59.982-14.356l74.7,62.252a64,64,0,1,0,92.621-27.56L428.106,159.87c1.289.078,2.585.13,3.894.13a64,64,0,0,0,0-128ZM80,456a32,32,0,1,1,32-32A32.036,32.036,0,0,1,80,456Zm96-200a32,32,0,1,1,32-32A32.036,32.036,0,0,1,176,256ZM352,392a32,32,0,1,1,32-32A32.036,32.036,0,0,1,352,392Zm80-264a32,32,0,1,1,32-32A32.036,32.036,0,0,1,432,128Z"
                                                    class="ci-primary"
                                                />
                                            </svg>
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            {digitalView && (
                                <>
                                    <DigitalCard digitalData={analog} getData={(get, val, dataType) => handleView(get, val, dataType)} />
                                </>
                            )}

                            {analogView && (
                                <>
                                    <AnalogCard analogData={analog} getData={(get, val, dataType) => handleView(get, val, dataType)} />

                                </>
                            )}
                            {view && <DataView digitalData={analog} sensorId={sensorId} selectSensorType={dataType} />}
                        </div>
                    </div>
                ) : (
                    <div className='commonTable'>
                        <div className="activeSensorCard mt-5 p-4">
                            <div className="d-flex justify-content-center align-items-center">
                                <div className='view-text' >
                                    <h3 className="">No Data Available</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                )
                }
            </>) : (
                <Loader />
            )}
        </>
    );
};
export default Index;
