import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DefaultLayout from "../layouts/AdminLayouts";
import Dashboard from "../pages/Dashboard";
import Notification from "../pages/Notification";
import Sensors from "../pages/Sensors";
import Report from "../pages/Report";
import Protected from "../Services/Protected";
import AllSensorView from "../pages/ManageDevice/AllSensorView";
import Gateway from "../pages/ManageDevice/Gateway";
import ActiveSensorDetails from "../components/ActiveSensorDetails";
import Users from "../pages/BasicSettings/Users";
import Roles from "../pages/BasicSettings/Roles";
import UserInfo from "../pages/BasicSettings/UserInfo";
import Permission from "../pages/BasicSettings/Permission";
import Clients from "../pages/BasicSettings/Clients";
import SensorGroup from "../pages/ManageDevice/SensorGroup";
import NotificationReport from "../pages/Report/NotificationReport";
import BatteryVoltage from "../pages/Dashboard/BatteryVoltage";

const AdminRoutes = () => {
    return (
        <>
            <Routes>
                <Route exact path="admin" element={<DefaultLayout />}>
                    <Route path="dashboard" element={<Protected><Dashboard /></Protected>} />
                    <Route path="users" element={<Protected><Users /> </Protected>} />
                    <Route path="activesensors" element={<Protected><Sensors /></Protected>} />
                    <Route path="report" element={<Protected><Report /></Protected>} />
                    <Route path="notification" element={<Protected><Notification /></Protected>} />
                    <Route path="allsensor" element={<Protected><AllSensorView /></Protected>} />
                    <Route path="gateway" element={<Protected><Gateway /></Protected>} />
                    <Route path="activesensor" element={<Protected><ActiveSensorDetails /></Protected>} />
                    <Route path="roles" element={<Protected><Roles /></Protected>} />
                    <Route path="permission" element={<Protected><Permission /></Protected>} />
                    <Route path="userinfo" element={<Protected><UserInfo /></Protected>} />
                    <Route path="clients" element={<Protected><Clients /></Protected>} />
                    <Route path="sensorgroups" element={<Protected><SensorGroup /></Protected>} />
                    <Route path="notificationreport" element={<Protected><NotificationReport /></Protected>} />
                    <Route path="batteryVoltage" element={<Protected><BatteryVoltage /></Protected>} />
                </Route>
            </Routes>
        </>
    );
};

export default AdminRoutes;
