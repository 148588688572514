import React, { useEffect, useRef, useState } from "react";
import "../assets/css/header.css";
import profileImg from "../assets/images/UserProfile.png";
import { useNavigate } from "react-router-dom";
import NotificationService from "../Services/NotificationService";
import Breadcrumbs from "../common/BreadCrumb";
import { Offcanvas } from 'react-bootstrap';
import Sidebar from "./Sidebar";



const Header = () => {
    const [notification, setNotification] = useState([]);
    const [notifyBar, setNotifyBar] = useState(false);
    const [logout, setLogout] = useState(false);
    const [url, seturl] = useState("")
    const [show, setShow] = useState(false);

    const nav = useNavigate();
    useEffect(() => {
        notificationList();
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let menuRef = useRef()

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current?.contains(e.target)) {
                setNotifyBar(false);
                setLogout(false)

            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)

        }
    }
    )
    const notificationList = () => {
        NotificationService.notification()
            .then((res) => setNotification(res.data.data.response.notifications))
            .catch((err) => console.log(err));
    };

    const filt = notification.sort((a, b) =>
        b.sensorTime.localeCompare(a.sensorTime)
    );

    const handleLogout = (e) => {
        localStorage.removeItem("isAuthenticated");
        localStorage.clear();
        window.location.replace("/");
        e.preventDefault();
    };

    const handleShowLogout = () => {
        setLogout(!logout)
    }

    return (
        <> <div id="header" className="desk-view">
            <div className="mobileSidebar">
                <Offcanvas className="headerCanvas" show={show} onHide={handleClose} >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Body>
                            <Sidebar />
                        </Offcanvas.Body>
                    </Offcanvas.Header>
                </Offcanvas>
            </div>
            <div className="top-header d-flex justify-content-between" >
                <div className='headerSideBar' onClick={handleShow} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" class="bi bi-justify" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </div>
                <div className="headerContent">
                    <div className="profile-img">
                        <img src={profileImg} onClick={() => handleShowLogout()} />
                    </div>
                    {logout && (
                        <div ref={menuRef} className="logout-content" onClick={(e) => handleLogout(e)} >
                            <div className="notifyTexts">
                                <b >logout</b>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

export default Header;
