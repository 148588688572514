import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../assets/css/report.css";
import DailyReport from "./DailyReport";
import DailySummary from "./DailySummary";
import MonthlyReport from "./MonthlyReport";
import ReportServices from "../../Services/ReportServices";
import BasicSettingService from '../../Services/BasicSettingService'


const Index = () => {
    const [key, setKey] = useState("daily report");
    const [devices, setDevices] = useState([]);
    const [clientDetails, SetClientDetails] = useState([])

    const ListContext = React.createContext();

    useEffect(() => {
        clientsInfoDetails()
    }, [])

    const clientsInfoDetails = () => {
        BasicSettingService.getClients()
            .then((res) => (
                SetClientDetails(res.data.data.response.clientData)
            )
            ).catch((error) => {

            })
    };

    return (
        <>
            <ListContext.Provider >
                <div className="rep-container reports mt-1">
                    <div className="space">
                        <div className="topReportTab">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                justify
                                className="reporttab"
                            >
                                <Tab eventKey="daily report" title="Daily Report">
                                    <DailyReport />
                                </Tab>
                                <Tab eventKey="daily Summary" title="Daily Summary">
                                    <DailySummary dailySummaryClient={clientDetails} />
                                </Tab>
                                <Tab eventKey="monthly report" title="QA Report">
                                    <MonthlyReport monthlyReportClient={clientDetails} />
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>
            </ListContext.Provider>
        </>
    );
};
export default Index;
