import axios from "axios";
import { useNavigate } from "react-router";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
    baseURL: BASE_URL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            const navigate = useNavigate();
            navigate("/login");
        }
        return Promise.reject(error);
   }
);

export default api;