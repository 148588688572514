import React, { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";

const AnalogCard = (props) => {
    const [view, SetView] = useState(false);

    const location = useLocation()
    const isNoData = location.state?.noData;

    const handleView = (e, val,dataType) => {
        props.getData(!view, val,dataType);
    };

    const getDate = (dateStr) => {
        let result = moment(dateStr);
        return result.format('DD-MM-YYYY, h:mm:ss A');
    }

    var TemperatureCardDetails =
        props.analogData &&
        props.analogData.map((val, key) => {
            const sensorAvailability = val?.sensorDetails[0]?.sensorAvailability

            return (
                <>
                    <div
                        className={`analog-card ps-0 pe-0 point ${sensorAvailability === "online" ? '' : 'disabled'}`} onClick={(e) => handleView(e, val,"Temperature")}
                    >
                        {sensorAvailability === "offline" ? 
                        <div className={"title" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                     ? "blue-bg": "disable-bg")}
                        >
                            <p>{val.sensorDetails[0]?.sensorName}</p>
                            <p>{val.sensorId}</p>
                        </div> 
                        : 
                        <div className={"title" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                     ? "blue-bg": "green-bg")}
                        >
                            <p>{val.sensorDetails[0]?.sensorName}</p>
                            <p>{val.sensorId}</p>
                        </div>}
                        
                        {sensorAvailability === "offline" ? 
                         <div className="meter" style={{height: 159, display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <p className={ "range" + " " +(val.tempReading >= val.sensorDetails[0]?.tempHigh? "red-digi": val.tempReading <= val.sensorDetails[0]?.tempLow 
                                     ? "blue-digi": "green-digi")}></p>
                           
                            <p className={val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                            ? "blue-digi" : "disable-digi"}
                            >
                               No data
                            </p>
                            <div className={"range" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                            ? "blue-digi" : "green-digi")}
                            > </div>
                        </div>
                         : 
                        <div className="meter">
                            <p className={"range" +" " + (val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                        ? "blue-digi" : "green-digi")}>Temperature</p>
                                <ReactSpeedometer
                                    minValue={val.sensorDetails[0]?.tempLow - 10}
                                    maxValue={val.sensorDetails[0]?.tempHigh + 10}
                                    customSegmentStops={[val.sensorDetails[0]?.tempLow - 10, val.sensorDetails[0]?.tempLow, val.sensorDetails[0]?.tempHigh, val.sensorDetails[0]?.tempHigh + 10]}
                                    value={val.tempReading}
                                    segments={3}
                                    width={150}
                                    height={80}
                                    ringWidth={20}
                                    paddingHorizontal={25}
                                    maxSegmentLabels={0}
                                    needleColor={val.tempReading >= val.sensorDetails[0]?.tempHigh ? "#bf3f3f" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                                ? "#0d96e7" : "green"}
                                    needleHeightRatio={0.5} segmentColors={["#359fdd", "#229f5b", "#d13b3b" ]}
                                />
                            <p className={val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                            ? "blue-digi" : "green-digi"}
                            >
                                {val.tempReading}
                            </p>
                            <div className={"range" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                            ? "blue-digi": "green-digi")}
                            >
                                Range : {val.sensorDetails[0]?.tempLow}°C - {val.sensorDetails[0]?.tempHigh}°C
                            </div>
                        </div>
                        }
                        {sensorAvailability === "offline" ?  
                        <div className={"info" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                        ? "blue-bg" : "disable-bg")}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <div className="date">
                                        {getDate(val.readingDate)}
                                    </div>
                                </div>
                            </div>
                        </div> 
                        : 
                        <div className={"info" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                        ? "blue-bg" : "green-bg")}
                        >
                            <div className="row">
                                <div className="col-12">
                                    <div className="date">
                                        {getDate(val.readingDate)}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        
                    </div>
                </>
            );
        });
    var HumidityCardDetails =
        props.analogData &&
        props.analogData.map((val, key) => {
            const sensorAvailability = val?.sensorDetails[0]?.sensorAvailability

            return (
                <>
                    {val.humidityReading === 0 ? (
                        null
                    ) : (
                        <div
                            className={`analog-card ps-0 pe-0 point ${sensorAvailability === "online" ? '' : 'disabled'}`} onClick={(e) => handleView(e, val,"Humidity")}
                        >
                            {sensorAvailability === "offline" ?  
                            <div className={"title" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg" : "disable-bg")}
                            >
                                <p>{val.sensorDetails[0]?.sensorName}</p>
                                <p>{val.sensorId}</p>
                            </div> 
                            :  
                            <div className={"title" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg" : "green-bg")}
                            >
                                <p>{val.sensorDetails[0]?.sensorName}</p>
                                <p>{val.sensorId}</p>
                            </div>}
                           
                            {sensorAvailability === "offline" ?   
                            <div className="meter" style={{height: 159, display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <p className={"range" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-digi" : "green-digi")}></p>
                                <p className={(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "blue-digi" : "disable-digi")}
                                >
                                   No Data
                                </p>
                                <div className={"range" + " " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "blue-digi" : "green-digi")}
                                > </div>
                            </div>
                             : 
                            <div className="meter">
                              <p className={"range" + " " + (val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                          ? "blue-digi": "green-digi")}>Humidity</p>
                                <ReactSpeedometer
                                    minValue={val.sensorDetails[0]?.humidityLow - 20}
                                    maxValue={val.sensorDetails[0]?.humidityHigh + 20}
                                    customSegmentStops={[val.sensorDetails[0]?.humidityLow - 20, val.sensorDetails[0]?.humidityLow, val.sensorDetails[0]?.humidityHigh, val.sensorDetails[0]?.humidityHigh + 20]}
                                    value={val.humidityReading}
                                    segments={3}
                                    width={150}
                                    height={80}
                                    ringWidth={20}
                                    paddingHorizontal={25}
                                    maxSegmentLabels={0}
                                    needleColor={val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "#bf3f3f" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "#0d96e7" : "green" } needleHeightRatio={0.5}
                                    segmentColors={["#359fdd", "#229f5b", "#d13b3b"]}
                                />
                              <p className={(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                              ? "blue-digi" : "green-digi")}
                              >
                                  {val.humidityReading}
                              </p>
                              <div className={"range" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                              ? "blue-digi" : "green-digi")}
                              >
                                  Range :  {val.sensorDetails[0]?.humidityLow}% - {val.sensorDetails[0]?.humidityHigh}%
                              </div>
                          </div>
                          }
                            {sensorAvailability === "offline" ?  
                            <div className={"info" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg": val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg": "disable-bg")}
                            >
                            <div className="row">
                                <div className="col-12">
                                    <div className="date">
                                        {getDate(val.readingDate)}
                                    </div>
                                </div>
                            </div>
                            </div>
                             : 
                            <div className={"info" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg": val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg": "green-bg")}
                            >
                            <div className="row">
                                <div className="col-12">
                                    <div className="date">
                                        {getDate(val.readingDate)}
                                    </div>
                                </div>
                            </div>
                            </div>
                            }
                          
                        </div>
                    )}
                </>);
        });
    return (
        <>
            {isNoData  ?  <>
                <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                        <div className="battery-no-data">
                                            <div className="battery-no-data-heading"> 
                                                No Data Available
                                            </div>
                                        </div>
                                    </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                </div>
                            </div>
                        </> : location.state.tempCard == "Temp" &&
                <>
                    <h5 className=" view-text mt-4 ">Temperature Sensor</h5>
                    {props.analogData && props.analogData.length > 0 && (
                        <div className="row sensor">{TemperatureCardDetails}</div>
                    )}
                </>
            }
            {isNoData  ? <div></div> :
                location.state.tempCard == "Humidity" &&
                <>
                    <h5 className="view-text mt-4">Humdity Sensor</h5>
                    {props.analogData && props.analogData.length > 0 && (
                        <div className="row sensor">{HumidityCardDetails}</div>
                    )}
                </>
            }
            {isNoData  ? <div></div> :
                location.state.tempCard == null &&
                <>
                    <h5 className=" view-text mt-4 ">Temperature Sensor</h5>
                    {props.analogData && props.analogData.length > 0 && (
                        <div className="row sensor">{TemperatureCardDetails}</div>
                    )}
                    <h5 className="view-text mt-4">Humdity Sensor</h5>
                    {props.analogData && props.analogData.length > 0 && (
                        <div className="row sensor">{HumidityCardDetails}</div>
                    )}
                </>
            }

        </>
    );
};
export default AnalogCard;
