import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import SensorService from "../../Services/SensorService";
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import ReportServices from "../../Services/ReportServices";
import Loader from "../../common/Loader";
import Toasters from "../../common/Toaster";

function MonthlyDrop(props) {
    const [clientOption, setClientOption] = useState([])
    const [sensorDetails, setSensorDetails] = useState([])
    const [sensorOption, setSensorOption] = useState([])
    const [qaReportData, setQaReportData] = useState([])
    const [userLoaded, setUserLoaded] = useState(true);
    const [errorMessage, setErrorMessage] = useState({})
    const [displayToast, setdisplayToast] = useState(false)

    const [tabSwitch, SetTabSwitch] = useState("Temperature");

    const validation = Yup.object({
        Client: Yup.object().nullable().required('*Please Select Client'),
        fromDate: Yup.date()
            .required("*Select a Valid Date")
            .max(new Date(), 'Select a Valid Date')
            .nullable(),
        toDate: Yup.date()
            .required("*Select a Valid Date")
            .max(new Date(), 'Select a Valid Date')
            .when('fromDate', (fromDate, schema) => {
                if (fromDate) {
                    const maxDate = new Date(fromDate);
                    maxDate.setMonth(maxDate.getMonth() + 3);
                    return schema
                        .test('date-range', 'End Date must be within 3 months of Start Date', function (toDate) {
                            if (toDate) {
                                return new Date(toDate) <= maxDate;
                            }
                            return true;
                        })
                        .test('date-range', 'End Date must be greater than Start Date', function (toDate) {
                            if (toDate) {
                                return new Date(toDate) > new Date(fromDate);
                            }
                            return true;
                        });
                }
                return schema;
            })
            .nullable(),
    })

    const { register, clearErrors, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) });


    let watchClientId = watch("Client");
    let sensorId = watch("SensorId");
    let fromDate = watch("fromDate");
    let toDate = watch("toDate")

    const PreDay = new Date();
    PreDay.setDate(PreDay.getDate() - 1);
    const previousDate = PreDay.toISOString().split('T')[0];

    const oneWeek = new Date();
    oneWeek.setDate(oneWeek.getDate() - 7);
    const lastWeek = oneWeek.toISOString().split('T')[0];

    useEffect(() => {
        if (watchClientId) {
            allSensorDetails(watchClientId?.value)
            setSensorOption([])
        }

    }, [watchClientId])

    useEffect(() => {
        const payload = {
            "clientId": null,
            "fromDate": lastWeek,
            "toDate": previousDate
        }
        getAllQaReport(payload)

    }, [])
    useEffect(() => {
        if (tabSwitch === "Humidity") {
            const payload = {
                "clientId": null,
                "fromDate": lastWeek,
                "toDate": previousDate
            }
            getAllQaSummaryReport(payload)
        }else{
            const payload = {
                "clientId": null,
                "fromDate": lastWeek,
                "toDate": previousDate
            }
            getAllQaReport(payload)
        }
    }, [tabSwitch])


    const apply = (e) => {
        if (tabSwitch === "Humidity") {
            const payload = {
                "clientId": watchClientId?.value,
                "sensorId": sensorId?.value,
                "fromDate": fromDate,
                "toDate": toDate
            }
            { sensorId?.value ? getQaSummaryReport(payload) : getAllQaSummaryReport(payload) }
        } else {
            const payload = {
                "clientId": watchClientId?.value,
                "sensorId": sensorId?.value,
                "fromDate": fromDate,
                "toDate": toDate
            }
            { sensorId?.value ? qaReport(payload) : getAllQaReport(payload) }
            if (watchClientId) {
                props.selectClient(watchClientId?.label)
            }
            setUserLoaded(true)
        }
    }


    useEffect(() => {

        if (props?.monthlyDropClient) {
            let clientData = [];
            props?.monthlyDropClient !== undefined &&
                props?.monthlyDropClient?.length > 0 &&
                props?.monthlyDropClient.map((x, key) => {
                    var list = { label: x.clientName, value: x.id };
                    clientData.push(list);
                    setClientOption(clientData);
                });
        }

    }, [props?.monthlyDropClient])

    useEffect(() => {

        if (sensorDetails) {
            let sensorDetailsData = [];
            sensorDetails !== undefined &&
                sensorDetails?.length > 0 &&
                sensorDetails.map((x, key) => {
                    var list = { label: x.sensorName, value: x.sensorId };
                    sensorDetailsData.push(list);
                    setSensorOption(sensorDetailsData);
                });
        }

    }, [sensorDetails])


    const allSensorDetails = (params) => {
        SensorService.getSensorByClientId(params)
            .then((res) => (
                setSensorDetails(res.data.data.response.sensorData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)

            })
    };


    const qaReport = (params) => {
        ReportServices.qaReportDetails(params)
            .then((res) => (
                setQaReportData([res.data.data.response.sensorData]),
                props.fromQareportData([res.data.data.response.sensorData], fromDate, toDate),
                // props.fromDailySumaryData(res.data.data.response.sensorData , date)
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }

    const getAllQaReport = (params) => {
        ReportServices.allQaReportDetails(params)
            .then((res) => (
                setQaReportData(res.data.data.response.sensorData),
                props.fromQareportData(res.data.data.response.sensorData, fromDate || lastWeek, toDate || previousDate),
                // props.fromDailySumaryData(res.data.data.response.sensorData , date)
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })

    }

    const getQaSummaryReport = (params) => {
        ReportServices.dailyHumidityQaSummary(params)
            .then((res) => {
                props?.selectedQaSumary([res.data.data.response.sensorData], fromDate, toDate)
            }).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }
    const getAllQaSummaryReport = (params) => {
        ReportServices.allSensorhumidityQaSummary(params)
            .then((res) => {
                props?.selectedQaSumary(res?.data?.data?.response?.sensorData)
            }).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }


    const handleToastClose = () => {
        setdisplayToast(false)
        setErrorMessage({})
    }

    const options = [
        { value: 'Temperature', label: 'Temperature' },
        { value: 'Humidity', label: 'Humidity' },
    ]

    const handleReadings = (selectedOption) => {
        SetTabSwitch(selectedOption?.value)
        props?.getTempChange(selectedOption?.value);
        setValue("Client",null)
        setValue("SensorId",null)
        setValue("fromDate","")
        setValue("toDate","")
    }

    return (
        <>
            {displayToast && (<Toasters messages={errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <form onSubmit={handleSubmit(apply)}>
                    <div className="Report-Drop row d-flex justify-content-between">
                        <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                            <Form.Label>Select Condition</Form.Label>
                            <Select options={options} defaultValue={options.find(option => option.value === tabSwitch)} onChange={(e) => handleReadings(e)} />
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2">
                            <Form.Group>
                                <Form.Label>Select Client</Form.Label>
                                <Controller
                                    control={control}
                                    name="Client"
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            name="Client"
                                            classNamePrefix="select"
                                            options={clientOption}
                                        />} />
                            </Form.Group>
                            <span className="error-text">
                                {errors.Client?.message}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2">
                            <Form.Group>
                                <Form.Label>Select Sensor</Form.Label>

                                <Controller
                                    control={control}
                                    name="SensorId"
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            name="SensorId"
                                            classNamePrefix="select"
                                            options={sensorOption}
                                        />} />
                            </Form.Group>
                            <span className="error-text">
                                {errors.SensorId?.message}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                            <Form.Group>
                                <Form.Label>Select Start Date</Form.Label>
                                <InputGroup.Text>
                                    <Controller
                                        control={control}
                                        // defaultValue={lastWeek}
                                        name="fromDate"
                                        render={({ field }) =>
                                            <Form.Control
                                                {...field}
                                                name="fromDate"
                                                type="date"
                                                // min={new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0]}
                                                max={new Date().toISOString().split('T')[0]}
                                            />} />
                                </InputGroup.Text>
                            </Form.Group>
                            <span className="error-text">
                                {errors.fromDate?.message}
                            </span>
                        </div>

                        <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                            <Form.Group>
                                <Form.Label>Select End Date</Form.Label>
                                <InputGroup.Text>
                                    <Controller
                                        control={control}
                                        name="toDate"
                                        // defaultValue={previousDate}
                                        render={({ field }) =>
                                            <Form.Control
                                                {...field}
                                                name="toDate"
                                                type="date"
                                                max={new Date().toISOString().split('T')[0]}
                                            />} />
                                </InputGroup.Text>
                            </Form.Group>
                            <span className="error-text">
                                {errors.toDate?.message}
                            </span>
                        </div>
                        <div className="drop-btn  col-md-12 col-lg-2 col-xl-2">
                            <button
                                className="drop-down-btn"
                                size="m"
                                type='submit'
                            >
                                Apply
                            </button>

                        </div>
                    </div>
                </form>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default MonthlyDrop;
