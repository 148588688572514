import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import DailySummaryDrop from "./DailySummaryDrop";
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, BlobProvider, Image } from "@react-pdf/renderer";
import { date } from "yup";
import moment from "moment";
import logo from "../../assets/images/logo-white.png";
import { color } from "echarts";


const styles = StyleSheet.create({
    body: {
        padding: "10",
        backgroundColor: "#edebf2"

    },
    image: {
        position: 'absolute',
        height: "6%",
        width: "4%",
        backgroundColor: "#3c3b54",
        // top: 0,
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 32,
        right: 0,
        color: "white",
        backgroundColor: "#3c3b54",
        height: "6%",
        width: "100%",
        paddingTop: "5",
        fontSize: 20,
    },
    heading: {
        textAlign: 'center',
        fontSize: 20,
        color: "#000",
        // paddingBottom: "15"
        // marginBottom: 10,
        marginTop: 25,
    },
    text: {
        textAlign: 'right',
        color: "white",
        fontSize: 10,
        // marginBottom: 10,
    },
    reportHeading: {
        textAlign: 'center',
        fontSize: 15,
        marginTop: 20,
    },
    table: {
        // borderRadius:"5px",
        marginTop: 20,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        backgroundColor: "white"
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        width: "100%"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 11,
    },
    tableCellHeading: {
        margin: "auto",
        marginTop: 5,
        fontSize: 13,

    },
    tableCellColspan2: {
        width: "16%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellColspan4Am: {
        width: "28%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#e8adad",
        color: "white"
    },
    tableCellColspan4PM: {
        width: "28%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#54b2b1",
        color: "white"
    },
    tableCellColspan4Entire: {
        width: "28%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#c317ac8c",
        color: "white"
    },
    tableCelldata1: {
        width: "8%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCelldata2: {
        width: "7%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },

});

const DailySummary = (props) => {

    const [summaryData, setSummaryData] = useState([])
    const [summaryDate, setSummaryDate] = useState([])
    const [label, setLabel] = useState([])

    const [humiditySummaryData, setHumiditySummaryData] = useState([]);
    const [tabSwitch, SetTabSwitch] = useState("Temperature");

    const handleExportPDF = () => {
        const MyDocument = () => (
            <Document>
                <Page orientation="landscape" style={styles.body}>

                    <Image
                        style={styles.image}
                        src={logo} />
                    <Text style={styles.logo} >Thermolog</Text>
                    <Text style={styles.text}>Report Date:{" "}{moment(new Date(summaryDate)).format('D-M-Y')}</Text>
                    <Text style={styles.heading}>Daily Summary</Text>
                    {
                        label.length > 0 ?
                            <Text style={styles.reportHeading}>
                                Showing DailySummary Report of {label} sensor from {moment(new Date(summaryDate)).format('D-M-Y')}
                            </Text>
                            :
                            <Text style={styles.reportHeading}>
                                Showing DailySummary Report for all sensors from {moment(new Date(summaryDate)).format('D-M-Y')}
                            </Text>
                    }

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCellColspan2}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                            <View style={styles.tableCellColspan4Am}>
                                <Text style={styles.tableCellHeading}>AM</Text>
                            </View>
                            <View style={styles.tableCellColspan4PM}>
                                <Text style={styles.tableCellHeading}>PM</Text>
                            </View>
                            <View style={styles.tableCellColspan4Entire}>
                                <Text style={styles.tableCellHeading}>Entire Day</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCelldata1}>
                                <Text style={styles.tableCell}>Sensor ID</Text>
                            </View>
                            <View style={styles.tableCelldata1}>
                                <Text style={styles.tableCell}>Sensor Name</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Location</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Client Name</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Low Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>High Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Avg Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Total Sample Taken</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Low Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>High Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Avg Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Total Sample Taken</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Low Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>High Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Avg Reading</Text>
                            </View>
                            <View style={styles.tableCelldata2}>
                                <Text style={styles.tableCell}>Total Sample Taken</Text>
                            </View>
                        </View>
                        {tabSwitch === "Temperature" && summaryData?.map((sumdata, index) => {
                            return (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCelldata1}>
                                            <Text style={styles.tableCell}>{sumdata?.sensorId}</Text>
                                        </View>
                                        <View style={styles.tableCelldata1}>
                                            <Text style={styles.tableCell}>{sumdata?.sensorName}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata?.sensorLocation == null ? "No Data" : sumdata?.sensorLocation}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata?.clientName == null ? "No Data" : sumdata?.clientName}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.amMin}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.amMax}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.amAvg}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.amCount}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.pmMin}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.pmMax}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.pmAvg}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.pmCount}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.dayMin}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.dayMax}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.dayAvg}°C</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{sumdata.dayCount}</Text>
                                        </View>

                                    </View>
                                </>)
                        })}
                        {tabSwitch === "Humidity" && Array.isArray(humiditySummaryData) && tabSwitch === "Humidity" ?
                            humiditySummaryData?.map((humData, index) => {
                                return (
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCelldata1}>
                                            <Text style={styles.tableCell}>{humData?.sensorId}</Text>
                                        </View>
                                        <View style={styles.tableCelldata1}>
                                            <Text style={styles.tableCell}>{humData?.sensorName}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData?.sensorLocation == null ? "No Data" : humData?.sensorLocation}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData?.clientName == null ? "No Data" : humData?.clientName}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.amMin}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.amMax}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.amAvg}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.amCount}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.pmMin}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.pmMax}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.pmAvg}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.pmCount}</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.dayMin}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.dayMax}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.dayAvg}%</Text>
                                        </View>
                                        <View style={styles.tableCelldata2}>
                                            <Text style={styles.tableCell}>{humData.dayCount}</Text>
                                        </View>

                                    </View>
                                );
                            }) : ""

                        }
                    </View>
                </Page>
            </Document>
        );

        const handleRender = ({ blob }) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${summaryDate}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        };

        return (
            <BlobProvider document={<MyDocument />}>
                {({ blob }) => (
                    <Button className="drop-down-btn mt-0" size="m" onClick={() => handleRender({ blob })}>
                        Export PDF
                    </Button>
                )}
            </BlobProvider>
        );
    };

    const summaryDataDetails = (data, data2) => {
        setSummaryData(data)
        setSummaryDate(data2)
    }

    const selectedClientName = (data) => {
        setLabel(data);
    }

    const humidityDataDetails = (data, data2) => {
        setHumiditySummaryData(data);
        setSummaryDate(data2)
    }

    const handleChangetemp = (data) => {
        SetTabSwitch(data?.value)
    }

    return (
        <>
            <DailySummaryDrop dailySummaryDropClient={props?.dailySummaryClient}
                selectClient={(data) => selectedClientName(data)}
                fromDailySumaryData={summaryDataDetails}
                fromHumiditySummary={humidityDataDetails}
                getTempChange={handleChangetemp}
            />
            {summaryData.length > 0 ? <div className="daily-summary daily-report">
                <div className="drop-btn  d-flex justify-content-end mt-0">
                    {handleExportPDF()}
                </div>
                <div>
                    {label?.length > 0 ?
                        <>
                            <div className="container report-table">
                                <h4>Showing DailySummary Report of {label} sensor from {moment(new Date(summaryDate)).format('D-M-Y')} </h4>
                            </div>
                        </>
                        :
                        <>
                            <div className="container report-table">
                                <h4>Showing DailySummary Report for all sensors from {moment(new Date(summaryDate)).format('D-M-Y')} </h4>
                            </div>
                        </>
                    }
                    <Table striped bordered responsive>
                        <thead>
                            <tr>
                                <th colSpan={4}></th>
                                <th
                                    className="summary-time"
                                    style={{
                                        backgroundColor:
                                            "rgb(189 20 20 / 35%)",
                                    }}
                                    colSpan={4}
                                >
                                    AM
                                </th>
                                <th
                                    className="summary-time"
                                    style={{
                                        backgroundColor: "rgb(84 178 177)",
                                    }}
                                    colSpan={4}
                                >
                                    PM
                                </th>
                                <th
                                    className="summary-time"
                                    style={{
                                        backgroundColor:
                                            "rgb(195 23 172 / 55%)",
                                    }}
                                    colSpan={4}
                                >
                                    Entire Day
                                </th>
                            </tr>
                        </thead>
                        <tbody className="summary-heading">
                            <tr>
                                {/* <th className="line">Date</th> */}
                                <th>Sensor ID</th>
                                <th>Sensor Name</th>
                                <th>Location</th>
                                <th>Client Name</th>
                                <th>Low Reading</th>
                                <th>High Reading</th>
                                <th>Avg Reading</th>
                                <th className="line">Total Sample Taken</th>
                                <th>Low Reading</th>
                                <th>High Reading</th>
                                <th>Avg Reading</th>
                                <th className="line">Total Sample Taken</th>
                                <th>Low Reading</th>
                                <th>High Reading</th>
                                <th>Avg Reading</th>
                                <th className="line">Total Sample Taken</th>
                            </tr>
                            {tabSwitch === "Temperature" && summaryData?.map((sumdata, index) => {
                                return (
                                    <>
                                        <tr className="summary-readings">
                                            {/* <td>{moment(new Date(summaryDate)).format('D-M-Y')}</td> */}
                                            <td>{sumdata?.sensorId}</td>
                                            <td className="line">{sumdata?.sensorName}</td>
                                            <td>{sumdata?.sensorLocation == null ? "No Data" : sumdata?.sensorLocation}</td>
                                            <td>{sumdata?.clientName == null ? "No Data" : sumdata?.clientName}</td>
                                            <td>{sumdata?.amMin}&#8451;</td>
                                            <td>{sumdata?.amMax}&#8451;</td>
                                            <td>{sumdata?.amAvg}&#8451;</td>
                                            <td className="line">{sumdata?.amCount}</td>
                                            <td>{sumdata?.pmMin}&#8451;</td>
                                            <td>{sumdata?.pmMax}&#8451;</td>
                                            <td>{sumdata?.pmAvg}&#8451;</td>
                                            <td className="line">{sumdata?.pmCount}</td>
                                            <td>{sumdata?.dayMin}&#8451;</td>
                                            <td>{sumdata?.dayMax}&#8451;</td>
                                            <td>{sumdata?.dayAvg}&#8451;</td>
                                            <td className="line">{sumdata?.dayCount}</td>
                                        </tr>
                                    </>
                                )
                            })}
                            {Array.isArray(humiditySummaryData) && tabSwitch === "Humidity" ?
                                humiditySummaryData?.map((humData, index) => {
                                    return (
                                        <>
                                        {humData.amMin !== 0 && <tr key={index} className="summary-readings">
                                            <td>{humData?.sensorId}</td>
                                            <td className="line">{humData?.sensorName}</td>
                                            <td>{humData?.sensorLocation == null ? "No Data" : humData?.sensorLocation}</td>
                                            <td>{humData?.clientName == null ? "No Data" : humData?.clientName}</td>
                                            <td>{humData?.amMin}%</td>
                                            <td>{humData?.amMax}%</td>
                                            <td>{humData?.amAvg}%</td>
                                            <td className="line">{humData?.amCount}</td>
                                            <td>{humData?.pmMin}%</td>
                                            <td>{humData?.pmMax}%</td>
                                            <td>{humData?.pmAvg}%</td>
                                            <td className="line">{humData?.pmCount}</td>
                                            <td>{humData?.dayMin}%</td>
                                            <td>{humData?.dayMax}%</td>
                                            <td>{humData?.dayAvg}%</td>
                                            <td className="line">{humData?.dayCount}</td>
                                        </tr>}
                                        </>
                                    );
                                }) : ""
                            }
                        </tbody>
                    </Table>
                </div>
            </div> : <><div className="commonTable">
                <div className=" heading ">No Data Available
                </div>
            </div>
            </>
            }

        </>
    );
};

export default DailySummary;