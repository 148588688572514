import React, { useState, useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Height } from "@mui/icons-material";

const DigitalCard = (props) => {

    const [view, SetView] = useState(false);

    const handleView = (e, val,dataType) => {
            props.getData(!view, val,dataType);
    };

    const location = useLocation()
    const isNoData = location.state?.noData;


    const getDate = (dateStr) => {
        let result = moment(dateStr);
        return result.format('DD-MM-YYYY, h:mm:ss A');
    }

    // TEMPERATURE SENSOR //
    var TemperatureCardDetails =
        props.digitalData &&
        props.digitalData.map((val, key) => {
            const sensorAvailability = val?.sensorDetails[0]?.sensorAvailability
           
        return (
                <div key={key} className={`analog-card ps-0 pe-0 point ${sensorAvailability === "offline" ? 'disabled' : ''}`} onClick={(e) => handleView(e, val,"Temperature")}>
                    {sensorAvailability === "offline"  ? 
                      <div className={"title" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow ? "blue-bg" : "disable-bg")}
                      >
                        <p>{val.sensorDetails[0]?.sensorName}</p>
                        <p>{val.sensorId}</p>
                      </div>
                      : 
                    <div className={"title" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow ? "blue-bg" : "green-bg")}
                    >
                        <p>{val.sensorDetails[0]?.sensorName}</p>
                        <p>{val.sensorId}</p>
                    </div>}
                    {sensorAvailability === "offline" ? 
                      <div className="meter" style={{height: 88, display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <p className={"temp-val-digi" + " " + (
                                val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow ? "blue-digi": "disable-digi" )}
                        >
                           No Data
                        </p>
                        </div>
                        : 
                        <div className="meter">
                        <p className={"range" + " " + (val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                    ? "blue-digi" : "green-digi")}>
                            Temperature
                        </p>
                        <p className={"temp-val-digi" + " " + (val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                    ? "blue-digi": "green-digi"
                            )
                            }
                        > {val.tempReading}°C
                        </p>
                        <div className={"range" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh? "red-digi" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                        ? "blue-digi": "green-digi") }
                        >
                            Range : {val.sensorDetails[0]?.tempLow}°C - {val.sensorDetails[0]?.tempHigh}°C
                        </div>
                        </div>
                     
                    }
                    {sensorAvailability === "offline"  ? 
                    <div className={"info" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg": val.tempReading <= val.sensorDetails[0]?.tempLow
                                    ? "blue-bg": "disable-bg") }
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="date">
                                    {getDate(val.readingDate)}
                                </div>
                            </div>
                        </div>
                    </div> 
                    : 
                    <div className={ "info" +" " +(val.tempReading >= val.sensorDetails[0]?.tempHigh ? "red-bg" : val.tempReading <= val.sensorDetails[0]?.tempLow
                                    ? "blue-bg": "green-bg")}
                    >
                        <div className="row">
                            <div className="col-12">
                                <div className="date">
                                    {getDate(val.readingDate)}
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            );
        });

    // HUMIDITY SENSOR //
    var humidityCardDetails =
        props.digitalData &&
        props.digitalData.map((val, key) => {
            const sensorAvailability = val?.sensorDetails[0]?.sensorAvailability
            return (
                <>
                    {val.humidityReading ===
                        0 ? (
                        null
                    ) : (
                        <div key={key} className={`analog-card ps-0 pe-0 point ${sensorAvailability === "offline" ? 'disabled' : ''}`} onClick={(e) => handleView(e, val,"Humidity")}>
                            {sensorAvailability === "offline" ? 
                              <div className={ "title" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh? "red-bg" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                        ? "blue-bg" : "disable-bg")}
                            >
                                <p>{val.sensorDetails[0]?.sensorName}</p>
                                <p>{val.sensorId}</p>
                            </div> : 
                            <div className={"title" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg": val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                        ? "blue-bg" : "green-bg")}
                            >
                                <p>{val.sensorDetails[0]?.sensorName}</p>
                                <p>{val.sensorId}</p>
                            </div> 
                            }
                            
                            {sensorAvailability === "offline" ? 
                            
                                <div className="meter" style={{height:88,display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <p className={"temp-val-digi" + " " + (val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "blue-digi" : "disable-digi")}
                                >
                                    No Data
                                </p>
                              </div>
                            : 
                            
                             <div className="meter">
                                <p className={"range" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi": val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-digi" : "green-digi")}
                                >
                                    Humidity
                                </p>
                                <p className={"temp-val-digi" + " " + (val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "blue-digi" : "green-digi")}
                                >
                                    {val.humidityReading}%
                                </p>
                                <div className={"range" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-digi" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                                ? "blue-digi" : "green-digi")}
                                >
                                    Range : {val.sensorDetails[0]?.humidityLow}% - {val.sensorDetails[0]?.humidityHigh}%
                                </div>
                            </div>
                         
                            }
                            {sensorAvailability === "offline" ?  
                              <div className={"info" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh ? "red-bg" : val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg" : "disable-bg")}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="date">
                                            {getDate(val.readingDate)}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            :  
                            <div className={"info" +" " +(val.humidityReading >= val.sensorDetails[0]?.humidityHigh? "red-bg": val.humidityReading <= val.sensorDetails[0]?.humidityLow
                                            ? "blue-bg": "green-bg")
                                }
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="date">
                                            {getDate(val.readingDate)}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                           
                        </div>
                    )
                    }
                </>
            );
        });
    return (
        <>
     
            {isNoData  ?  <>
                <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="battery-no-data">
                                    <div className="battery-no-data-heading"> 
                                        No Data Available
                                    </div>
                                </div>
                            </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        </div>
                    </div>
                        </> :
                        location.state.tempCard == "Temp" &&
                <>

                    <h5 className=" view-text mt-4 ">Temperature Sensor</h5>
                    {props.digitalData && props.digitalData.length > 0 && (
                        <div className="row sensor">{TemperatureCardDetails}</div>
                    )}
                </>
            }

            {isNoData  ? <div></div> : location.state.tempCard == "Humidity" &&

                <>
                    <h5 className="view-text mt-4">Humdity Sensor</h5>
                    {props.digitalData && props.digitalData.length > 0 && (
                        <div className="row sensor">{humidityCardDetails}</div>
                    )}

                </>
            }

            {isNoData  ? <div></div> : location.state.tempCard == null &&
                <>
                    <h5 className=" view-text mt-4 ">Temperature Sensor</h5>
                    {props.digitalData && props.digitalData.length > 0 && (
                        <div className="row sensor">{TemperatureCardDetails}</div>
                    )}
                    <h5 className="view-text mt-4">Humdity Sensor</h5>
                    {props.digitalData && props.digitalData.length > 0 && (
                        <div className="row sensor">{humidityCardDetails}</div>
                    )}

                </>
            }
        </>
    );
};

export default DigitalCard;
