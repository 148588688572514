
import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function Batteryhealth(props) {

  const sortedBatteryHealth = [...props?.batteryHealth].sort((a, b) => {
    return new Date(b.readingDate) - new Date(a.readingDate);
  });

const recentBatteryHealth = sortedBatteryHealth[0];

const batteryVoltageRecent = recentBatteryHealth?.batteryVoltage;

const maxbattery = 3.9;

const recentBattery = Math.min(batteryVoltageRecent, maxbattery);

const percentage = (recentBattery / maxbattery) * 100;

const color = recentBattery >= 2.9 ? "#2d7c14" : "#b73939"

  return (
    <>
      {props?.batteryHealth?.length > 0 ?
        <>
          <h6 className="user-text">Battery Health</h6>
          <div className="row">

            <div className="col-12">
              <div className="battery-dataview">
                <div className="circle-valb">
                  <CircularProgressbar
                    value={percentage}
                    text={`${recentBattery.toFixed(2)} V`}
                    styles={buildStyles({
                      textSize: "18px",
                      // pathColor: `rgba(32, 117, 5, ${percentage / 100
                      //   })`,
                      pathColor : color,
                      textColor : "#3c3b54",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <h6 className="user-text mt-5 p-5 d-flex justify-content-center">No Data Available</h6>

      }
    </>
  );
}