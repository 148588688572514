import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "../../assets/css/commonTable.css";
import BasicSettingService from '../../Services/BasicSettingService'
import Select from "react-select";
import Toasters from "../../common/Toaster";
import Loader from "../../common/Loader";
import { Button, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form';
import * as Yup from "yup";
import ReactPaginate from "react-paginate";


const validation = Yup.object({
    FirstName: Yup.string().required("*Required").nullable().min(2, '* Client Name must be at least 2 characters').max(50, '*  Client Name maximum limit of 50'),
    LastName: Yup.string().required("*Required").nullable().min(2, '* Client Name must be at least 2 characters').max(50, '*  Client Name maximum limit of 50'),
    Email: Yup.string().required('*Required').nullable().email("please Enter vaild Email Id"),
    PhoneNo: Yup.string().required("Required").matches(new RegExp('[0-9]{10}'), "Phone number is not valid").min(10, '* must be at least 10 characters').max(20, '* only allow 20 Characters'),
    Password: Yup.string().required('Password is required').min(6, "requires a minimum of 6 characters").max(10, "requires limit 10 characters"),
    ConfirmPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match'),
})

const Users = () => {
    const [user, setUser] = useState([]);
    const [rolesDetails, setRolesDetails] = useState([])
    const [edit, setEdit] = useState({})
    const [userLoaded, setUserLoaded] = useState(true);
    const [show, setShow] = useState(false);
    const [rolesOption, setRolesOption] = useState([])
    const [addUserSuccess, setAddUserSuccess] = useState({})
    const [editUserSuccess, setEditUserSuccess] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [editId, setEditId] = useState({})
    const [displayToast, setdisplayToast] = useState(false)
    const [errorMessage, setErrorMessage] = useState({})
    const [pageNumber, setPageNumber] = useState(0);

    const { register, clearErrors, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) });

    useEffect(() => {
        userInfo();
    }, []);

    useEffect(() => {
        if (addUserSuccess?.status === 200) {
            handleClose()
            userInfo();
            setdisplayToast(true)
        }
    }, [addUserSuccess]);

    useEffect(() => {
        if (editUserSuccess?.status === 200) {
            handleClose()
            userInfo();
            setdisplayToast(true)
        }
    }, [editUserSuccess]);

    useEffect(() => {
        rolesInfoDetails();

    }, []);

    useEffect(() => {

        if (rolesDetails) {
            let rolesData = [];
            rolesDetails !== undefined &&
                rolesDetails?.length > 0 &&
                rolesDetails.map((x, key) => {
                    var list = { label: x.roleName, value: x.id };
                    rolesData.push(list);
                    setRolesOption(rolesData);
                });
        }
    }, [rolesDetails]);

    const addUser = (params) => {
        BasicSettingService.postUser(params).then((res) => {
            setAddUserSuccess(res)

        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setdisplayToast(true)
        })
    }

    const editUserdata = (params, editId) => {
        BasicSettingService.editUser(params, editId).then((res) => {
            setEditUserSuccess(res)
        }).catch((error) => {
            setErrorMessage(error.response.data.message)
            setdisplayToast(true)
        })
    }

    const rolesInfoDetails = () => {
        BasicSettingService.getRoles()
            .then((res) => (
                setRolesDetails(res.data.data.response.userRolesData)

            )).catch((error) => {
                setErrorMessage(error.response.data.message)
                setdisplayToast(true)
            })
    };

    const userInfo = () => {
        BasicSettingService.userDetails()
            .then((res) => (
                setUser(res.data.data.response.UserData), 
                setUserLoaded(false)
            ))
            .catch((err) => console.log(err));

    };

    const userSubmit = (data) => {
        const payload = {
            "firstName": data?.FirstName,
            "lastName": data?.LastName,
            "email": data?.Email,
            "contactNumber": data?.PhoneNo,
            "password": data?.Password,
            "confirmPassword": data?.ConfirmPassword,
            "roleId": data?.roles?.value,
            "status": data?.Status?.value
        }
        if (editValue) {
            editUserdata(payload, editId)

        } else {
            addUser(payload)
        }
    }

    const userDataPerPage = 10;
    const pagesVisited = pageNumber * userDataPerPage;
    const pageCount = Math.ceil(user.length / userDataPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );

        if (filtered[0]) {

            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const getRolesById = (value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            rolesOption &&
            rolesOption?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        return filtered && filtered[0]?.label
    };

    const handleClose = () => {
        setShow(false)
        setEditValue(false)
        setEdit({})
        setEditId({})
        clearErrors()
        reset()

    };

    const handleclick = (data) => {
        setEdit(data)
        setShow(true);
        setEditValue(true)
        setValue('FirstName', data?.firstName);
        setValue('LastName', data?.lastName);
        setValue('Email', data?.emailId);
        setValue('PhoneNo', data?.phoneNumber);
        setValue('Password', data?.password);
        setValue('ConfirmPassword', data?.confirmPassword);
        filterObject("roles", rolesOption, data?.roleId === 0 ? "Super Admin" : data?.roleId);
        filterObject("Status", Options, data?.status);
        setEditId(data?.id)

    }

    const handleShow = () => {
        setShow(true);
        reset()
    }
    const Options = [
        {
            label: "Active",
            value: "Active"
        },
        {
            label: "Inactive",
            value: "InActive"
        },
    ];
    const handleToastClose = () => {
        setAddUserSuccess({})
        setEditUserSuccess({})
        setdisplayToast(false)
        setErrorMessage({})
    };
    return (
        <>{displayToast && (<Toasters messages={addUserSuccess?.data?.data?.response?.status || editUserSuccess?.data?.data?.response?.status || errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <div className="">
                    <div className="space commonTable">
                        <div className=" heading ">Users
                            <div className="d-flex justify-content-end">
                                <Button className="addUser mb-1" size="sm" onClick={handleShow} > Add User</Button>
                                <Modal show={show} onHide={handleClose}>
                                    <form onSubmit={handleSubmit(userSubmit)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{!edit.firstName ? "Add User" : "Edit User"}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form className="row">
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control {...register('FirstName')} name="FirstName" type="Text" />
                                                    <span className="error-text">
                                                        {errors.FirstName?.message}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control {...register('LastName')} name="LastName" type="Text" />
                                                    <span className="error-text">
                                                        {errors.LastName?.message}
                                                    </span>

                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12" >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control {...register('Email')} name="Email" type="Text" />
                                                    <span className="error-text">
                                                        {errors.Email?.message}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Phone No</Form.Label>
                                                    <Form.Control {...register('PhoneNo')} name="PhoneNo" type="Text" />
                                                    <span className="error-text">
                                                        {errors.PhoneNo?.message}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Role</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="roles"
                                                        render={({ field }) =>
                                                            <Select
                                                                {...field}
                                                                name="role"
                                                                classNamePrefix="select"
                                                                options={rolesOption} />} />
                                                    <span className="error-text">
                                                        {errors.roles?.message}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control {...register('Password')} name="Password" type="password" autoFocus />
                                                    <span className="error-text">
                                                        {errors.Password?.message}
                                                    </span>
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control {...register('ConfirmPassword')} name="ConfirmPassword" type="password" autoFocus />
                                                    <span className="error-text">
                                                        {errors.ConfirmPassword?.message}
                                                    </span>
                                                </Form.Group>
                                                {edit.firstName && <Form.Group className="mb-3 col-12" >
                                                    <Form.Label>Status</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="Status"
                                                        render={({ field }) =>
                                                            <Select
                                                                {...field}
                                                                name="Status"
                                                                classNamePrefix="select"
                                                                options={Options} />} />
                                                </Form.Group>}
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button type='submit' className="modalSave" >
                                                Save Changes
                                            </Button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <Table className="table-responsive-xxl">
                                    <thead className="head">
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Phone No</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {user?.sort((a, b) => b.id - a.id).slice(
                                            pagesVisited,
                                            pagesVisited +
                                            userDataPerPage
                                        ).map((userData, index) => (
                                            <tr>
                                                <td>{(pageNumber * userDataPerPage) + index + 1}</td>
                                                <td>{userData.firstName}</td>
                                                <td>{userData.lastName}</td>
                                                <td>{userData.emailId}</td>
                                                <td>{userData.phoneNumber}</td>
                                                <td> {userData.roleId == "0" ? "Super Admin" : getRolesById(userData.roleId)}</td>
                                                <td><div className="d-flex justify-content-evenly point"><svg onClick={() => handleclick(userData)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                        </svg> */}
                                                </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default Users;
