import React from 'react'
import { useState, useEffect } from "react";
import NotificationService from "../../Services/NotificationService";
import { useLocation, useNavigate } from 'react-router'


function LastFiveNotification(props) {
    const [showLoader, setShowLoader] = useState(true);
    const [lastFiveNotification, setLastFiveNotification] = useState()

    const location = useLocation()

    const navigate = useNavigate()

    useEffect(() => {
        notificationList();
    }, []);


    useEffect(() => {
        const loaderTimeout = setTimeout(() => {
            setShowLoader(false);
        }, 2000);

        return () => clearTimeout(loaderTimeout);
    }, []);


    const notificationList = () => {
        NotificationService.notification()
            .then((res) => setLastFiveNotification(res.data.data.response.notifications))
            .catch((err) => console.log(err));
    };

    const handleNavigate = (status) => {
        navigate("/admin/notification",
            {
                state: status
            })

    }

    return (
        <>
            <div className="noti-dash" onClick={() => handleNavigate("Unresolved")} >
                <h6 className="user-text ">
                    <svg
                        className="me-3"
                        width="18px"
                        height="18px"
                        viewBox="0 0 20 20"
                        fill="#b73939"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M4 8a6 6 0 0 1 4.03-5.67 2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8zm8 10a2 2 0 1 1-4 0h4z" />
                    </svg>
                    Last 5 Notification
                </h6>
                {showLoader ? (
                    <div class="widgetLoader" id="loader-4">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ) : <>
                    { }
                    <div className="mt-0 point">
                        <h6 className="lastNotificationUserText">

                            {lastFiveNotification?.sort((a, b) => new Date(b.sensorTime) - new Date(a.sensorTime)).filter((item, i) => i < 5)
                                .map((val, index) => {
                                    return (< p >
                                        Sensor {val.sensorId}, Temperature{" "}
                                        {val.tempValue} and Humidity {val.humidityValue}
                                    </p>)
                                })
                            }
                        </h6>
                    </div>
                </>}
            </div >
        </>
    )
}

export default LastFiveNotification