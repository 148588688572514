import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import BasicSettingService from '../Services/BasicSettingService'
import SensorService from '../Services/SensorService';
import { useState, useEffect } from 'react'


function ActiveSensorDetails() {
    const [clientDetails, SetClientDetails] = useState([])


    useEffect(() => {
        clientsInfoDetails();
      
    }, []);

    const navigate = useNavigate()
    const location = useLocation()

    var { tempCard } = location.state


    const clientsInfoDetails = () => {
        BasicSettingService.getClients()
            .then((res) => {
                SetClientDetails(res.data.data.response.clientData)
            }
            ).catch((error) => {
            })
    };

    const handleNavigate = (id) => {
        navigate("/admin/activesensors", {
            state: { tempCard , id }
        })

    }
    return (
        <div className=''>
            <div className='space'>
                <div className="activeSensorCard">
                    <div className='view-text mt-3'>
                        <h3>All Group Details</h3>
                    </div>
                    <div className='row point'>
                        {clientDetails.map((clientDetailsGroup, index) => (
                            <div className='col-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3'>
                                <div className='activeSensor' onClick={() => handleNavigate(clientDetailsGroup.id)}>
                                    <div className='sensorDetails  row '>
                                        <div className='col-8'>
                                            <div className='sensorLocation'>
                                                <h6 >{clientDetailsGroup.clientName}</h6>
                                                {/* <p>{sensorLoc.location}</p> */}
                                            </div>
                                        </div>
                                        <div className='activeSensorDetails col-4'>
                                            <p className='totalActive'>{clientDetailsGroup.sensorCount}</p>
                                            <h6 className='totalHeading'>Total Sensor</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveSensorDetails