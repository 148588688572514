import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "../../assets/css/commonTable.css";
import Modal from 'react-bootstrap/Modal';
import { Badge, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import SensorService from "../../Services/SensorService";
import { Controller, useForm, useWatch } from 'react-hook-form';
import BasicSettingService from '../../Services/BasicSettingService'
import Loader from '../../common/Loader';
import Toasters from "../../common/Toaster";
import ManageDeviceService from '../../Services/ManageDeviceService';
import ReportServices from "../../Services/ReportServices";
import ReactPaginate from "react-paginate";
import moment from "moment";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, BlobProvider, Image, Svg, Path,Polygon,Rect, Circle, Line} from "@react-pdf/renderer";
import logo from "../../assets/images/logo-white.png";
import svgIcon from '../../assets/images/logo-white.png';


const styles = StyleSheet.create({
    body: {
        padding: "10",
        backgroundColor: "#edebf2"
    },
    image: {
        position: 'absolute',
        height: "6%",
        width: "4%",
        backgroundColor: "#3c3b54",
        // top: 0,

    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 32,
        right: 0,
        color: "white",
        backgroundColor: "#3c3b54",
        height: "6%",
        width: "100%",
        paddingTop: "5",
        fontSize: 20,
    },
    heading: {
        textAlign: 'center',
        fontSize: 20,
        color: "#000",
        marginTop: 25,
    },
    text: {
        textAlign: 'right',
        color: "white",
        fontSize: 10,
    },
    reportHeading:{
        textAlign: 'center',
        fontSize:15,
        marginTop:20,
    },
    table: {
        marginTop: 20,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        backgroundColor: "white"

    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",

    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 11,
    },
    tableCellHeading: {
        margin: "auto",
        marginTop: 5,
        fontSize: 13,

    },
    tableCellColspan2: {
        width: "16%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCelldata1: {
        width: "8%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCelldata2: {
        width: "8%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        alignItems:"center",
    },
    tableCellbg1: {
        width: "8%",
        color:"white",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#3c3b54"

    },
    tableCellbg2: {
        width: "8%",
        color:"white",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: "#3c3b54"
    },
    svgIcon:{
        marginTop:10
    },
    line:{
        width:"20%"
    }

});

function NotificationReport() {

    const [showViewNotes, setShowViewNotes] = useState(false);
    const [showNotifyNotes, setShowNotifyNotes] = useState(false);
    const [notifystatus, setNotifystatus] = useState([]);
    const [sensorGroupOption, setSensorGroupOption] = useState([])
    const [errorMessage, setErrorMessage] = useState({})
    const [userLoaded, setUserLoaded] = useState(true);
    const [displayToast, setdisplayToast] = useState(false)
    const [sensorGroupDetails, setSensorGroupDetails] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [notificationReportDetails, setNotificationReportDetails] = useState([])
    const [viewNotes, setViewNotes] = useState([])

    const validation = Yup.object({
        SensorGroup: Yup.object().nullable().required('*Please Select Group'),
        fromDate: Yup.date().required("*Select a Valid Date").nullable().max(new Date(), 'Select a Valid Date').min(new Date(new Date().setMonth(new Date().getMonth() - 3)), 'Available Data 3 Months Only'),
        toDate: Yup.date().required("*Select a Valid Date").max(new Date(), 'Select a Valid Date').min(new Date(new Date().setMonth(new Date().getMonth() - 3)), 'Available Data 3 Months Only').when('fromDate', (fromDate, schema) =>
            schema.test('date-range', 'End Date must be greater than Start Date', function (toDate) {
             return !(fromDate && toDate) || new Date(toDate) > new Date(fromDate);
            })
        ).nullable(),
    })

    const { register, clearErrors, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) });

    useEffect(() => {
        sensorGroupAllData()
    }, []);

    useEffect(() => {

        if (sensorGroupDetails) {
            let sensorGroupDetailsData = [];
            sensorGroupDetails !== undefined &&
                sensorGroupDetails?.length > 0 &&
                sensorGroupDetails.map((x, key) => {
                    var list = { label: x.groupName, value: x.groupDetails[0].userGroupId };
                    sensorGroupDetailsData.push(list);
                    setSensorGroupOption(sensorGroupDetailsData);
             });
           }

    }, [sensorGroupDetails])

    let sensorGroupId = watch("SensorGroup");
    let fromDate = watch("fromDate");
    let toDate = watch("toDate")

    const apply = (e) => {
        const payload = {
            "groupId": sensorGroupId?.value,
            "fromDate": fromDate,
            "toDate": toDate
        }
        notificationReportData(payload)
        setUserLoaded(true)
    }

    const sensorGroupAllData = () => {
        ManageDeviceService.getAllSensorGroup()
            .then((res) => (
                setSensorGroupDetails(res.data.data.response.sensorGrouptData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })

    };

    const notificationReportData = (params) => {
        ReportServices.getNotificationReportDetails(params)
            .then((res) => (
                setNotificationReportDetails(res.data.data.response.notificationReports),
                // props.fromNotificationSummaryDetails(res.data.data.response.notificationReports),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)

            })
    };

    const handleShowViewNotes = (data) => {
        setViewNotes(data)
        setShowViewNotes(true);

    }

    const handleShowNotify = (data) => {
        setNotifystatus(data)
        setShowNotifyNotes(true)

    }
    const handleClose = () => {
        setShowNotifyNotes(false)
        setShowViewNotes(false)

    }
    const handleToastClose = () => {
        setdisplayToast(false)
        setErrorMessage({})
    }

    const notifiReportDataPerPage = 10;

    const pagesVisited = pageNumber * notifiReportDataPerPage;

    const pageCount = Math.ceil(notificationReportDetails.length / notifiReportDataPerPage);

    const changePage = ({ selected }) => {

        setPageNumber(selected);

    };

    const handleExportPDF = () => {
        const MyDocument = () => (
            <Document>
                <Page orientation="landscape" style={styles.body}>
                    <Image
                        style={styles.image}
                        src={logo}/>   
                        <Text style={styles.logo} >Thermolog</Text>
                    <Text style={styles.heading}>Notification Reports</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCellbg1}>
                                <Text style={styles.tableCell}>Sl.no</Text>
                            </View>
                            <View style={styles.tableCellbg1}>
                                <Text style={styles.tableCell}>Sensor Id</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Alert Type</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Sensor Name</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Alert Time</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Alert End Time</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Sensor Temperature</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Sensor Humidity</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>View Notes</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Notify Log</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Last Note / Corrective Action</Text>
                            </View>
                            <View style={styles.tableCellbg2}>
                                <Text style={styles.tableCell}>Clear Time</Text>
                            </View>
                        </View>
                        {notificationReportDetails?.map((notify, index) => {
                            return (
                            <>
                                <View style={styles.tableRow} key={index}>
                                    <View style={styles.tableCelldata1}>
                                        <Text style={styles.tableCell}>{(pageNumber * notifiReportDataPerPage) + index + 1}</Text>
                                    </View>
                                    <View style={styles.tableCelldata1}>
                                        <Text style={styles.tableCell}>{notify?.sensorId}</Text>
                                    </View>
                                    <View style={styles.tableCelldata1}>
                                        <Text style={styles.tableCell}>{notify?.tempThresholdStatus}{notify?.tempThresholdStatus && notify?.humidityThresholdStatus ? '/' : ''}{notify?.humidityThresholdStatus}</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.sensorName}</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{moment(new Date(notify?.sensorTime)).format('D-M-Y')}</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.updatedAt == null ? "No Data" : notify?.updatedAt}</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.tempValue}&#8451;</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.humidityValue}% </Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                    <Svg style={styles.svgIcon} width={20} height={30} xmlns="http://www.w3.org/2000/svg">
                                        <Rect x={0} y={0} width={20} height={20} stroke="#ff8c00" strokeWidth={2} fill="none" />
                                        {/* First Checkmark Symbol (Column 1) */}
                                        <Line x1={2} y1={7} x2={4} y2={9} stroke="#ff8c00" strokeWidth={2} />
                                        <Line x1={4} y1={9} x2={8} y2={5} stroke="#ff8c00" strokeWidth={2} />
                                        {/* Horizontal Line (Parallel to the Checkmark) */}
                                        {/* Second Checkmark Symbol (Column 2) */}
                                        <Line x1={2} y1={14} x2={4} y2={16} stroke="#ff8c00" strokeWidth={2} />
                                        <Line x1={4} y1={16} x2={8} y2={12} stroke="#ff8c00" strokeWidth={2} />
                                        {/* Horizontal Line (Parallel to the Second Checkmark) */}
                                        </Svg>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                         <Svg style={styles.svgIcon} width={20} height={20} xmlns="http://www.w3.org/2000/svg">
                                        {/* Clock Circle */}
                                        <Circle cx="10" cy="10" r="9" fill="none" stroke="red" strokeWidth="1" />
                                        {/* Clock Hands */}
                                        <Line x1="10" y1="10" x2="10" y2="4" stroke="red" strokeWidth="2" />
                                        <Line x1="10" y1="10" x2="14" y2="10" stroke="red" strokeWidth="2" />
                                        {/* Clock Center (Optional) */}
                                        <Circle cx="10" cy="10" r="2" fill="red" />
                                    </Svg>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.comments == null ? "No Data" : notify?.comments}</Text>
                                    </View>
                                    <View style={styles.tableCelldata2}>
                                        <Text style={styles.tableCell}>{notify?.updatedAt == null ? "No Data" : notify?.updatedAt}</Text>
                                    </View>
                                </View>
                            </>)
                        })}
                    </View>
                </Page>
            </Document>
        );

        const handleRender = ({ blob }) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${notificationReportDetails[0]?.groupName}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        };

        return (
            <BlobProvider document={<MyDocument />}>
                {({ blob }) => (
                    <Button className="drop-down-btn mt-0" size="m" onClick={() => handleRender({ blob })}>
                        Export PDF
                    </Button>
                )}
            </BlobProvider>
        );
    };

    return (
        <>
        {displayToast && (<Toasters messages={errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <div className="space ">
                    <form onSubmit={handleSubmit(apply)}>
                        <div className="Report-Drop mt-3 row d-flex justify-content-between">
                            <div className="col-md-6 col-lg-2 col-xl-2">
                                <Form.Group>
                                    <Form.Label>Select Group</Form.Label>
                                    <Controller
                                        control={control}
                                        name="SensorGroup"
                                        render={({ field }) =>
                                            <Select
                                                {...field}
                                                name="SensorGroup"
                                                classNamePrefix="select"
                                                options={sensorGroupOption}
                                            />} />
                                </Form.Group>
                                <span className="error-text">
                                    {errors.SensorGroup?.message}
                                </span>
                            </div>
                            <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                                <Form.Group>
                                    <Form.Label>Select Start Date</Form.Label>
                                    <InputGroup.Text>
                                        <Controller
                                            control={control}
                                            // defaultValue={lastWeek}
                                            name="fromDate"
                                            render={({ field }) =>
                                                <Form.Control
                                                    {...field}
                                                    name="fromDate"
                                                    type="date"
                                                    // min={new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0]}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />} />
                                    </InputGroup.Text>
                                </Form.Group>
                                <span className="error-text">
                                    {errors.fromDate?.message}
                                </span>
                            </div>
                            <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                                <Form.Group>
                                    <Form.Label>Select End Date</Form.Label>
                                    <InputGroup.Text>
                                        <Controller
                                            control={control}
                                            name="toDate"
                                            // defaultValue={previousDate}
                                            render={({ field }) =>
                                                <Form.Control
                                                    {...field}
                                                    name="toDate"
                                                    type="date"
                                                    max={new Date().toISOString().split('T')[0]}
                                                />} />
                                    </InputGroup.Text>
                                </Form.Group>
                                <span className="error-text">
                                    {errors.toDate?.message}
                                </span>
                            </div>
                            <div className="drop-btn  col-md-12 col-lg-2 col-xl-2">
                                <button className="drop-down-btn" size="m" type='submit' > Apply</button>
                            </div>
                        </div>
                    </form>

                    {notificationReportDetails.length > 0 ? <>
                            <div className="">
                                <div className="commonTable">
                                        <div className="drop-btn  d-flex justify-content-end mt-0 me-0">
                                            {handleExportPDF()}
                                        </div>
                                    <div className=" heading ">Notification Reports / Corrective Actions
                                    </div>
                                    <div className=" heading ">Group Name : {notificationReportDetails[0]?.groupName}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12">
                                            <Table className="table-responsive-xxl">
                                                <thead className="head">
                                                    <tr>
                                                        <th>Sl.no</th>
                                                        <th>Sensor Id</th>
                                                        <th>Alert Type</th>
                                                        <th>Sensor Name</th>
                                                        <th>Alert Time</th>
                                                        <th>Alert End Time</th>
                                                        <th>Sensor Temperature</th>
                                                        <th>Sensor Humidity</th>
                                                        <th>View Notes</th>
                                                        <th>Notify Log</th>
                                                        <th>Last Note / Corrective Action</th>
                                                        <th>Clear Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-body">
                                                    <>
                                                        {notificationReportDetails?.slice(
                                                            pagesVisited,
                                                            pagesVisited +
                                                            notifiReportDataPerPage
                                                        ).map((notifiy, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{(pageNumber * notifiReportDataPerPage) + index + 1}</td>
                                                                    <td>{notifiy?.sensorId}</td>
                                                                    <td>{notifiy?.tempThresholdStatus}{notifiy?.tempThresholdStatus && notifiy?.humidityThresholdStatus ? '/' : ''}{notifiy?.humidityThresholdStatus}</td>
                                                                    <td>{notifiy?.sensorName}</td>
                                                                    <td>{moment(new Date(notifiy?.sensorTime)).format('D-M-Y,LT')}</td>
                                                                    <td >{notifiy?.updatedAt == null ? "No Data" : notifiy?.updatedAt}</td>
                                                                    <td>{notifiy?.tempValue}&#8451; </td>
                                                                    <td>{notifiy?.humidityValue}%  </td>
                                                                    <td onClick={() => handleShowViewNotes(notifiy)} >  
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="darkorange" class="bi bi-card-checklist" viewBox="0 0 16 16">
                                                                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                                                        </svg>
                                                                    </td>
                                                                    {/* {notifiy?.notificationForStatus.filter(data => data?.status === "Failed").length > 0 ?
                                                                <td onClick={() => handleShowNotify(notifiy?.notificationForStatus)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="red" class="bi bi-alarm" viewBox="0 0 16 16">
                                                                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
                                                                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
                                                                    </svg></td>
                                                                :
                                                                <td onClick={() => handleShowNotify(notifiy?.notificationForStatus)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="green" class="bi bi-alarm" viewBox="0 0 16 16">
                                                                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
                                                                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
                                                                    </svg></td>
                                                            } */}
                                                                    <td onClick={() => handleShowNotify(notifiy)} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="red" class="bi bi-alarm" viewBox="0 0 16 16">
                                                                            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
                                                                            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
                                                                        </svg></td>
                                                                    <td>{notifiy?.comments == null ? "No Data" : notifiy?.comments}</td>
                                                                    <td>{notifiy?.updatedAt == null ? "No Data" : notifiy?.updatedAt}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-3">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={changePage}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </div>
                            <Modal show={showViewNotes} onHide={handleClose}>   
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>View Notes</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="notificationReportCard">
                                                                <Form className="row">
                                                                    <Form.Label className="col-5">Sensor Id</Form.Label> :
                                                                    <Form.Label className="col-5"> {viewNotes.sensorId}</Form.Label>
                                                                    <Form.Label className="col-5">Alert Type</Form.Label> :
                                                                    <Form.Label className="col-5">{viewNotes.tempThresholdStatus}{viewNotes?.tempThresholdStatus && viewNotes?.humidityThresholdStatus ? '/' : ''}{viewNotes.humidityThresholdStatus}</Form.Label>
                                                                    <Form.Label className="col-5">Sensor Name</Form.Label>:
                                                                    <Form.Label className="col-5">{viewNotes.sensorName}</Form.Label>
                                                                    <Form.Label className="col-5">Alert Time</Form.Label>:
                                                                    <Form.Label className="col-5">{moment(new Date(viewNotes?.sensorTime))?.format('DD-MM-YYYY, h:mm:ss A')}</Form.Label>
                                                                    <Form.Label className="col-5">Alert End Time</Form.Label>:
                                                                    <Form.Label className="col-5">{viewNotes?.updatedAt === null ? "No Data" : viewNotes?.updatedAt}</Form.Label>
                                                                    <Form.Label className="col-5">Temperature Value	</Form.Label>:
                                                                    <Form.Label className="col-5">{viewNotes.tempValue}℃</Form.Label>
                                                                    <Form.Label className="col-5">Humidity Value</Form.Label>:
                                                                    <Form.Label className="col-5">{viewNotes.humidityValue}% </Form.Label>
                                                                </Form>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Header className='groupHeader'>
                                                            <Modal.Title>Standard Action</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <p className="mb-1">{viewNotes?.comments == null ? "No Data" : viewNotes?.comments}</p>
                                                            {/* <p style={{ "color": "Blue" }}>{viewNotes?.updatedAt == null ? "No Data" : viewNotes?.updatedAt}</p> */}
                                                            {/* <p className="mb-1">Notified Maintenance </p>
                                                <p style={{ "color": "Blue" }}>Ravi @ 2/2/23 12:30 PM</p> */}
                                                        </Modal.Body>
                                                    </Modal>
                                                    <Modal size="lg" show={showNotifyNotes} onHide={handleClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Notify Log</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="notificationReportCard">
                                                                <Form className="row">
                                                                    <Form.Label className="col-5">Sensor Id</Form.Label> :
                                                                    <Form.Label className="col-5"> {notifystatus.sensorId}</Form.Label>
                                                                    <Form.Label className="col-5">Alert Type</Form.Label> :
                                                                    <Form.Label className="col-5">{notifystatus.tempThresholdStatus}{notifystatus?.tempThresholdStatus && notifystatus?.humidityThresholdStatus ? '/' : ''}{notifystatus.humidityThresholdStatus}</Form.Label>
                                                                    <Form.Label className="col-5">Sensor Name</Form.Label>:
                                                                    <Form.Label className="col-5">{notifystatus.sensorName}</Form.Label>
                                                                    <Form.Label className="col-5">Alert Time</Form.Label>:
                                                                    <Form.Label className="col-5">{moment(new Date(notifystatus?.sensorTime))?.format('D-M-Y,LT')}</Form.Label>
                                                                    <Form.Label className="col-5">Alert End Time</Form.Label>:
                                                                    <Form.Label className="col-5">{notifystatus?.updatedAt === null ? "No Data" : notifystatus?.updatedAt}</Form.Label>
                                                                    <Form.Label className="col-5">Temperature Value	</Form.Label>:
                                                                    <Form.Label className="col-5">{notifystatus.tempValue}℃</Form.Label>
                                                                    <Form.Label className="col-5">Humidity Value</Form.Label>:
                                                                    <Form.Label className="col-5">{notifystatus.humidityValue}% </Form.Label>
                                                                </Form>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Header className='groupHeader'>
                                                            <Modal.Title>Notification Status</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Table className="table-responsive-xxl">
                                                                <thead className="head">
                                                                    <tr>
                                                                        <th>Notification Type</th>
                                                                        <th>Notification Timestamp</th>
                                                                        <th>Status</th>
                                                                        <th>Other Information</th>
                                                                    </tr>
                                                                </thead>
                                                                {/* <tbody className="table-body">
                                                        {notifystatus?.map((noti, index) => {
                                                            return (
                                                                <tr>

                                                                    <td>{noti?.notificationType} </td>
                                                                    <td>{noti?.notificationTimeStamp}</td>
                                                                    <td><Badge className={noti?.status === "Success" ? "statusGreen" : "statusRed"}>{noti?.status}</Badge></td>
                                                                    <td>{noti?.othernotification}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody> */}
                                                                <tbody className="table-body">
                                                                    <tr>

                                                                        <td>Email</td>
                                                                        <td>{moment(new Date(notifystatus?.sensorTime))?.format('D-M-Y,LT')}</td>
                                                                        <td><Badge className={notifystatus.sensorTime === "Success" ? "statusGreen" : "statusOrange"}>Proccesing</Badge></td>
                                                                        <td>No Data</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Modal.Body>
                                                    </Modal>
                        </> : <><div className="commonTable">
                            <div className=" heading ">Notification Reports / Corrective Actions
                            </div>
                            <div className=" heading ">No Data Available
                            </div>
                        </div></>
                    }
                </div>
            ) : (
                <Loader />
            )}
        </>
    )
}

export default NotificationReport


                                                    