import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL;

// Gateway

const getGateway = async () => {
    return await axios.get(API_URL + "master/gatewaydetails", { headers: AuthHeader() });
};
const postGateway = async params => {
    return await axios.post(API_URL + "gatewayresource/details/add",
        params,
        { headers: AuthHeader() })
};
const editGateway = async (params, id) => {
    return await axios.post(API_URL + "gatewayresource/details/edit/" + id,
        params,
        { headers: AuthHeader() })
}

// AllsensorView

const editAllSensorDetails = async (params, id) => {
    return await axios.post(API_URL + "sensorresource/sensor/details/edit/" + id,
        params,
        { headers: AuthHeader() })
}
const postAllSensorDetails = async params => {
    return await axios.post(API_URL + "sensorresource/sensor/details/add",
        params,
        { headers: AuthHeader() })
};
const getHumidityThreshold = async () => {
    return await axios.get(API_URL + "master/humidity/threshold/types", { headers: AuthHeader() });
};
const getTemperatureThreshold = async () => {
    return await axios.get(API_URL + "master/temperature/threshold/types", { headers: AuthHeader() });
};
const getSensorType = async () => {
    return await axios.get(API_URL + "master/sensortypes", { headers: AuthHeader() });
};
const getAllSensorDetails = async () => {
    return await axios.get(API_URL + "master/sensordetails", { headers: AuthHeader() });
};

// SensorGroup

const getAllSensorGroup = async () => {
    return await axios.get(API_URL + "master/sensor/groupdetails", { headers: AuthHeader() });
};
const postGatewayById = async (params) => {
    return await axios.post(API_URL + "master/sensordetails",
        params,
        { headers: AuthHeader() })
};
const editSensorGroup = async (params, id) => {
    return await axios.post(API_URL + "sensorresource/sensor/groups/edit/" + id,
        params,
        { headers: AuthHeader() })
}
const postSensorGroup = async params => {
    return await axios.post(API_URL + "sensorresource/sensor/groups/add",
        params,
        { headers: AuthHeader() })
};




const ManageDeviceService = {
    getGateway,
    postGateway,
    editGateway,
    getSensorType,
    getTemperatureThreshold,
    getHumidityThreshold,
    getAllSensorDetails,
    postAllSensorDetails,
    editAllSensorDetails,
    getAllSensorGroup,
    postSensorGroup,
    editSensorGroup,
    postGatewayById,

}





export default ManageDeviceService;