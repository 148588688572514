import React, { useState, useEffect } from 'react'
import { Badge, Button, Table } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import BasicSettingService from '../../Services/BasicSettingService'
import ManageDeviceService from '../../Services/ManageDeviceService';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Toasters from "../../common/Toaster";
import ReactPaginate from "react-paginate";
import Loader from '../../common/Loader';



function Gateway() {
    const [gatewayDetails, setGatewayDetails] = useState([])
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({})
    const [clientDetails, SetClientDetails] = useState([])
    const [clientOption, setClientOption] = useState([])
    const [addGatewaySuccess, setAddGatewaySuccess] = useState({})
    const [editGatewaySuccess, setEditGatewaySuccess] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [editId, setEditId] = useState({})
    const [displayToast, setdisplayToast] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState({})
    const [userLoaded, setUserLoaded] = useState(true);


    const validation = Yup.object({
        GatewayId: Yup.string().required("*Required").nullable().min(8, '*GatewayId must be at least 8 characters').max(20, '*GatewayId maximum limit of 20'),
        GatewayName: Yup.string().required("*Required").nullable().min(2, '*Gateway Name must be at least 2 characters').max(50, '*Gateway Name  maximum limit of 50'),
    })

    const { register, clearErrors, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) });

    useEffect(() => {
        clientsInfoDetails();
        gatewayInfoDetails()

    }, []);

    useEffect(() => {
        if (addGatewaySuccess?.status === 200) {
            handleClose()
            gatewayInfoDetails()
            setdisplayToast(true)

        }
    }, [addGatewaySuccess]);

    useEffect(() => {
        if (editGatewaySuccess?.status === 200) {
            handleClose()
            gatewayInfoDetails()
            setdisplayToast(true)

        }
    }, [editGatewaySuccess]);

    useEffect(() => {

        if (clientDetails) {
            let clientData = [];
            clientDetails !== undefined &&
                clientDetails?.length > 0 &&
                clientDetails.map((x, key) => {
                    var list = { label: x.clientName, value: x.id };
                    clientData.push(list);
                    setClientOption(clientData);
                });
        }

    }, [clientDetails]);


    const clientsInfoDetails = () => {
        BasicSettingService.getClients()
            .then((res) => (
                SetClientDetails(res.data.data.response.clientData),
                setUserLoaded(false)
            )
            ).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const gatewayInfoDetails = () => {
        ManageDeviceService.getGateway()
            .then((res) => (
                setGatewayDetails(res.data.data.response.gatewaytData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const addGatewayData = (params) => {
        ManageDeviceService.postGateway(params).then((res) => (
            setAddGatewaySuccess(res),
            setUserLoaded(false)
        )).catch((error) => {
            setErrorMessage({'message': error.response.data.message, "status":"error"})
            setdisplayToast(true)
        })
    }
    const editGatewaydata = (params, editId) => {
        ManageDeviceService.editGateway(params, editId).then((res) => (
            setEditGatewaySuccess(res),
            setUserLoaded(false)
        )).catch((error) => {
            setErrorMessage({'message': error.response.data.message, "status":"error"})
            setdisplayToast(true)
        })
    }

    const gatewaySubmit = (data) => {
        const payload = {
            "gatewayId": data?.GatewayId,
            "gatewayName": data?.GatewayName,
            "clientId": data?.Client?.value,
            "devicePassword": data?.DevicePassword,
            "deviceInterval": data?.DeviceInterval,
            "deviceIMEI": data?.IMEI,
            "activeStatus": `${data?.Status ? data?.Status?.value : 1}`
        }
        if (editValue) {
            editGatewaydata(payload, editId)

        } else {
            addGatewayData(payload)
        }

    }

    const gatewayDataPerPage = 10;
    const pagesVisited = pageNumber * gatewayDataPerPage;
    const pageCount = Math.ceil(gatewayDetails.length / gatewayDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );

        if (filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const handleclick = (data) => {
        setEdit(data)
        setShow(true)
        setEditValue(true)
        setEditId(data?.id)
        setValue('GatewayId', data?.gatewayId);
        setValue('GatewayName', data?.gatewayType);
        setValue('DevicePassword', data?.emailId);
        setValue('DeviceInterval', data?.phoneNumber);
        setValue('IMEI', data?.password);
        setValue('ConfirmPassword', data?.confirmPassword);
        filterObject("Client", clientOption, data?.clientId);
        filterObject("Status", Options, data?.activeStatus);
    }
    const handleClose = () => {
        setEdit({})
        setShow(false)
        setEditValue(false)
        setEditId({})
        clearErrors()
        reset()
    };

    const handleShow = () => {
        setShow(true);
        reset()
    }

    const getClientById = (value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            clientOption &&
            clientOption?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        return filtered && filtered[0]?.label
    };

    const handleToastClose = () => {
        setEditGatewaySuccess({})
        setAddGatewaySuccess({})
        setdisplayToast(false)
        setErrorMessage({})

    };
    const Options = [
        {
            label: "Active",
            value: 1
        },
        {
            label: "Inactive",
            value: 0
        },
    ];

    return (<>
        {displayToast && (<Toasters messages={addGatewaySuccess?.data?.data?.response?.status || editGatewaySuccess?.data?.data?.response?.status || errorMessage} handleToastClose={handleToastClose} />)}
        {!userLoaded ? (
            <div className="">
                <div className="space commonTable">
                    <div className=" heading ">Gateway
                        <div className="d-flex justify-content-end">
                            <Button className="gateWayAdd mb-1" size="sm" onClick={handleShow} > Add Gateway</Button>
                            <Modal show={show} onHide={handleClose}>
                                <form onSubmit={handleSubmit(gatewaySubmit)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{!edit.gatewayId ? "Add Gateway" : "Edit Gateway"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form className="row">
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Gateway Id</Form.Label>
                                                <Form.Control disabled={edit.gatewayId} {...register('GatewayId')} name="GatewayId" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.GatewayId?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Gateway Name</Form.Label>
                                                <Form.Control  {...register('GatewayName')} name="GatewayName" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.GatewayName?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Client </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="Client"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="Client"
                                                            classNamePrefix="select"
                                                            options={clientOption}
                                                        />} />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Device Password</Form.Label>
                                                <Form.Control {...register('DevicePassword')} name="DevicePassword" type="Text" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Device Interval</Form.Label>
                                                <Form.Control {...register('DeviceInterval')} name="DeviceInterval" type="Text" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>IMEI/SN/DEVEUI</Form.Label>
                                                <Form.Control {...register('IMEI')} name="IMEI" type="Text" autoFocus />
                                            </Form.Group>
                                            {edit.gatewayId && <Form.Group className="mb-3 col-12" >
                                                <Form.Label>Status</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="Status"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="Status"
                                                            classNamePrefix="select"
                                                            options={Options}
                                                        />} />
                                            </Form.Group>}
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='modalSave' type='submit'>Save Changes </Button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </div></div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <Table className="table-responsive-xxl">
                                <thead className="head">
                                    <tr>
                                        <th>Sl.no</th>
                                        <th>Gateway Id</th>
                                        <th>Gateway Name</th>
                                        <th>Client </th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <>{gatewayDetails.sort((a, b) => b.id - a.id).slice(
                                        pagesVisited,
                                        pagesVisited +
                                        gatewayDataPerPage
                                    ).map((gatewayDataDetails, index) => (
                                        <tr>
                                            <td>{(pageNumber * gatewayDataPerPage) + index + 1}</td>
                                            <td>{gatewayDataDetails.gatewayId}</td>
                                            <td>{gatewayDataDetails.gatewayType}</td>
                                            <td>  {getClientById(gatewayDataDetails.clientId)}</td>
                                            <td><Badge className={gatewayDataDetails.activeStatus === 1 ? "statusGreen" : gatewayDataDetails.activeStatus === 0 ? "statusRed" : ""}>{gatewayDataDetails.activeStatus === 1 ? "Active" : "InActive"}</Badge>
                                            </td>
                                            <td><div className="d-flex justify-content-evenly point">
                                                <svg onClick={(e) => handleclick(gatewayDataDetails)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg> */}
                                            </div></td>
                                        </tr>
                                    ))}
                                    </>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        ) : (
            <Loader />
        )}
    </>
    )
}

export default Gateway
