import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../assets/css/login.css";
import jwtDecode from "jwt-decode";

const LoginLayout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let isUserAuthenticated = isAuthenticated();
        if (isUserAuthenticated) {
            navigate(-1)
        }
    }, [])

    const isAuthenticated = () => {

        const loggedInUser = JSON.parse(localStorage.getItem("user"));

        if (!loggedInUser || !loggedInUser.accessToken) {
            return false;
        } else {
            const decoded = jwtDecode(loggedInUser.accessToken);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.log("access token expired");
            } else {
                return true;
            }
        }
    };
    return (
        <>
            <div className="login-Contaniner">
                <Outlet />
            </div>
        </>
    );
};

export default LoginLayout;
