import React, { useState } from "react";
import "../assets/css/sidebar.css";
import Nav from "react-bootstrap/Nav";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/images/logo-white.png";
import Accordion from 'react-bootstrap/Accordion';
import { Button } from "react-bootstrap";


const Sidebar = (props) => {
    const [roles, setRoles] = useState("admin");
    const [isActive, setIsActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [sensorDrop, setSensorDrop] = useState(false)
    const [basicDrop, setBasicDrop] = useState(false)
    const [sideBar, setSideBar] = useState(true)

    const handleSensorDrop = (e) => {
        setSensorDrop(!sensorDrop);

    }
    const handlebasicdrop = (e) => {
        setBasicDrop(!basicDrop)
    }

    const handleDropDown = (e) => {
        setOpen(!open);
    };
    const sidebarClick = (e) => {
        setSideBar(!sideBar)
        props?.getSideBarButton(!sideBar)


    }



    return (
        <>
            {sideBar ? (<div id="sidebar" className="desk-sidebar ">
                <header>
                    <p className="logo-text">
                        <img src={logo} className="mb-1" />
                        THERMO LOG
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" onClick={(e) => sidebarClick(e)} fill="#a3a0fb" className="point ms-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </p>
                </header>

                <Nav className="flex-column side-left">
                    <NavLink
                        state={"Dashboard"}
                        to={`/${roles}/dashboard`}
                        className={isActive ? "active" : "link"} >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            fill="#c3c2ef"
                            xmlns="http://www.w3.org/2000/svg" >
                            <g>
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                            </g>
                        </svg>{" "}
                        Dashboard
                    </NavLink>
                    {/* <div onClick={(e) => handlebasicdrop(e)}
                        state={"User"}
                        className={isActive ? "active" : "link"} >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            fill="#c3c2ef"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 11.796C14.7189 11.796 16.9231 9.60308 16.9231 6.89801C16.9231 4.19294 14.7189 2.00005 12 2.00005C9.28106 2.00005 7.07692 4.19294 7.07692 6.89801C7.07692 9.60308 9.28106 11.796 12 11.796Z"
                                fill="#c3c2ef" />
                            <path
                                d="M14.5641 13.8369H9.4359C6.46154 13.8369 4 16.2859 4 19.245C4 19.9593 4.30769 20.5716 4.92308 20.8777C5.84615 21.3879 7.89744 22.0001 12 22.0001C16.1026 22.0001 18.1538 21.3879 19.0769 20.8777C19.5897 20.5716 20 19.9593 20 19.245C20 16.1838 17.5385 13.8369 14.5641 13.8369Z"
                                fill="#c3c2ef"
                            />
                        </svg>
                        Basic Settings
                        <svg
                            onClick={(e) => handlebasicdrop(e)}
                            className={`basicDrop ${basicDrop ? " sensorDropActive" : "sensorDropNon"}`}
                            width="35px"
                            height="35px"
                            fill="#a3a0fb"

                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect x="0" fill="none" width="24" height="24" />
                            <g>
                                <path d="M7 10l5 5 5-5" />
                            </g>
                        </svg>
                    </div> */}
                    {/* {basicDrop && (
                        <> <NavLink to={`/${roles}/userinfo`}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp;
                            User Info
                        </NavLink>
                            <NavLink
                                to={`/${roles}/roles`}
                                className={isActive ? "active" : "link"}
                            >
                                &nbsp;&nbsp;
                                Roles
                            </NavLink>
                            <NavLink
                                to={`/${roles}/permission`}
                                className={isActive ? "active" : "link"}
                            >
                                &nbsp;&nbsp;
                                Permission
                            </NavLink>
                            <NavLink to={`/${roles}/users`}
                                className={isActive ? "active" : "link"}
                            >
                                &nbsp;&nbsp;
                                Users
                            </NavLink>
                            <NavLink to={`/${roles}/clients`}
                                className={isActive ? "active" : "link"}
                            >
                                &nbsp;&nbsp;
                                Clients
                            </NavLink>
                        </>
                    )} */}
                    <div
                        state={"Device Details"}
                        to={`/${roles}/sensors`}
                        className={isActive ? "active" : "link"}
                        onClick={(e) => handleSensorDrop(e)}
                    >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            fill="#c3c2ef"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M6 8v11h12V8h-3V2h2v4h5v2h-2v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V2h2v6H6zm7-6v6h-2V2h2z" />
                            </g>
                        </svg>{" "}
                        Manage Device
                        <svg
                            onClick={(e) => handleSensorDrop(e)}
                            className={`manageDrop ${sensorDrop ? " sensorDropActive" : "sensorDropNon"}`}
                            width="35px"
                            height="35px"
                            fill="#a3a0fb"

                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect x="0" fill="none" width="24" height="24" />
                            <g>
                                <path d="M7 10l5 5 5-5" />
                            </g>
                        </svg>
                    </div>
                    {sensorDrop && (<>
                        {/* <NavLink
                            state={"Gateway"}
                            to={`/${roles}/gateway`}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp;
                            Gateway
                        </NavLink>
                        <NavLink state={"All Sensor Details"}
                            to={`/${roles}/allsensor`}
                            className={isActive ? "active" : "link"}>
                            &nbsp;&nbsp;
                            Sensors
                        </NavLink>
                        <NavLink
                            state={"All Group"}
                            to={`/${roles}/sensorgroups`}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp;
                            Sensor Groups
                        </NavLink> */}
                        <NavLink
                            state={"Activesensors"}
                            to={`/${roles}/activesensors`}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp;
                            Active Sensors
                        </NavLink>
                    </>
                    )
                    }  <NavLink
                        state={"Unresolved"}
                        to={`/${roles}/notification`}
                        className={isActive ? "active" : "link"}
                    >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            fill="#c3c2ef"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z" />
                            <path d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z" />
                        </svg>{" "}
                        Notification
                    </NavLink>
                    <div
                        className={isActive ? "active" : "link"}
                        onClick={(e) => handleDropDown(e)}
                    >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            fill="#c3c2ef"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9 21H15M9 21V16M9 21H3.6C3.26863 21 3 20.7314 3 20.4V16.6C3 16.2686 3.26863 16 3.6 16H9M15 21V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H15.6C15.2686 3 15 3.26863 15 3.6V9M15 9H9.6C9.26863 9 9 9.26863 9 9.6V16"
                                stroke="#c3c2ef"
                                stroke-width="1.5"
                            />
                        </svg>{" "}
                        Reports
                        <svg
                            onClick={(e) => handleDropDown(e)}
                            className={`reportDrop ${open ? "sensorDropActive" : "sensorDropNon"}`}
                            width="35px"
                            height="35px"
                            fill="#a3a0fb"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"

                        >
                            <rect x="0" fill="none" width="24" height="24" />
                            <g>
                                <path d="M7 10l5 5 5-5" />
                            </g>
                        </svg>
                    </div>
                    {open && (<>
                        <NavLink
                            state={{
                                deviceReport: "DeviceReport"
                            }}
                            to={`/${roles}/report`}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp; Device Report

                        </NavLink>

                        <NavLink
                            to={`/${roles}/notificationreport`}
                            state={"Notification Report "}
                            className={isActive ? "active" : "link"}
                        >
                            &nbsp;&nbsp;Notification Report
                        </NavLink>
                    </>
                    )}
                </Nav>
            </div>) : <>   <div id="sidebar" className="desk-sidebar ">
                <header>
                    <p className="logo-text">
                        {/* <img src={logo} className="mb-1" /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" onClick={(e) => sidebarClick(e)} fill="#a3a0fb" className="point" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </p>
                </header>
                <Nav className="flex-column side-left">
                    <NavLink
                        state={"Dashboard"}
                        to={`/${roles}/dashboard`}
                        className={isActive ? "active" : "link"} >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            fill="#c3c2ef"
                            xmlns="http://www.w3.org/2000/svg" >
                            <g>
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                            </g>
                        </svg>
                    </NavLink>
                    <div
                        state={"User"}
                        className={isActive ? "active" : "link"} >
                        <div className="sub1 point">

                            <svg
                                className="mb-1"
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                fill="#c3c2ef"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 11.796C14.7189 11.796 16.9231 9.60308 16.9231 6.89801C16.9231 4.19294 14.7189 2.00005 12 2.00005C9.28106 2.00005 7.07692 4.19294 7.07692 6.89801C7.07692 9.60308 9.28106 11.796 12 11.796Z"
                                    fill="#c3c2ef" />
                                <path
                                    d="M14.5641 13.8369H9.4359C6.46154 13.8369 4 16.2859 4 19.245C4 19.9593 4.30769 20.5716 4.92308 20.8777C5.84615 21.3879 7.89744 22.0001 12 22.0001C16.1026 22.0001 18.1538 21.3879 19.0769 20.8777C19.5897 20.5716 20 19.9593 20 19.245C20 16.1838 17.5385 13.8369 14.5641 13.8369Z"
                                    fill="#c3c2ef"
                                />
                            </svg>
                            <div className="subone">
                                <> <NavLink to={`/${roles}/userinfo`}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp;
                                    User Info
                                </NavLink>
                                    <NavLink
                                        to={`/${roles}/roles`}
                                        className={isActive ? "active" : "link"}
                                    >
                                        &nbsp;&nbsp;
                                        Roles
                                    </NavLink>
                                    <NavLink
                                        to={`/${roles}/permission`}
                                        className={isActive ? "active" : "link"}
                                    >
                                        &nbsp;&nbsp;
                                        Permission
                                    </NavLink>
                                    <NavLink to={`/${roles}/users`}
                                        className={isActive ? "active" : "link"}
                                    >
                                        &nbsp;&nbsp;
                                        Users
                                    </NavLink>
                                    <NavLink to={`/${roles}/clients`}
                                        className={isActive ? "active" : "link"}
                                    >
                                        &nbsp;&nbsp;
                                        Clients
                                    </NavLink>
                                </>
                            </div>
                        </div>
                    </div>
                    <div
                        state={"Device Details"}
                        to={`/${roles}/sensors`}
                        className={isActive ? "active" : "link"}
                        onClick={(e) => handleSensorDrop(e)}
                    >
                        <div className="sub2 point">
                            <svg
                                className="mb-1"
                                width="18px"
                                height="18px"
                                fill="#c3c2ef"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M6 8v11h12V8h-3V2h2v4h5v2h-2v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V2h2v6H6zm7-6v6h-2V2h2z" />
                                </g>
                            </svg>
                            <div className="subtwo">

                                <NavLink
                                    state={"Gateway"}
                                    to={`/${roles}/gateway`}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp;
                                    Gateway
                                </NavLink>
                                <NavLink state={"All Sensor Details"}
                                    to={`/${roles}/allsensor`}
                                    className={isActive ? "active" : "link"}>
                                    &nbsp;&nbsp;
                                    Sensors
                                </NavLink>
                                <NavLink
                                    state={"All Group"}
                                    to={`/${roles}/sensorgroups`}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp;
                                    Sensor Groups
                                </NavLink>
                                <NavLink
                                    state={"Activesensors"}
                                    to={`/${roles}/activesensors`}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp;
                                    Active Sensors
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <NavLink
                        state={"Unresolved"}
                        to={`/${roles}/notification`}
                        className={isActive ? "active" : "link"}
                    >
                        <svg
                            className="mb-1"
                            width="18px"
                            height="18px"
                            fill="#c3c2ef"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z" />
                            <path d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z" />
                        </svg>
                    </NavLink>
                    <div
                        className={isActive ? "active" : "link"}
                        onClick={(e) => handleDropDown(e)}
                    >
                        <div className="sub3 point">
                            <svg
                                className="mb-1"
                                width="18px"
                                height="18px"
                                fill="#c3c2ef"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 21H15M9 21V16M9 21H3.6C3.26863 21 3 20.7314 3 20.4V16.6C3 16.2686 3.26863 16 3.6 16H9M15 21V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H15.6C15.2686 3 15 3.26863 15 3.6V9M15 9H9.6C9.26863 9 9 9.26863 9 9.6V16"
                                    stroke="#c3c2ef"
                                    stroke-width="1.5"
                                />
                            </svg>
                            <div className="subthree">
                                <NavLink
                                    state={{
                                        deviceReport: "DeviceReport"
                                    }}
                                    to={`/${roles}/report`}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp; Device Report

                                </NavLink>

                                <NavLink
                                    to={`/${roles}/notificationreport`}
                                    state={"Notification Report "}
                                    className={isActive ? "active" : "link"}
                                >
                                    &nbsp;&nbsp;Notification Report
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </Nav>
            </div> </>}
        </>
    );
};

export default Sidebar;
