import React, { useContext } from "react";
import Table from "react-bootstrap/Table";
import ListContext from "./index"
import DataView from "../Sensors/DataView"

const DailyReport = (props) => {
    const devices = useContext(ListContext);
    return (
        <div className="groupTable">
            <div className="">
                <div className="groupDataView">
                    <DataView />
                </div>
            </div>
        </div>
    );
};

export default DailyReport;
