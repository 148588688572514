import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-white.png";
import AuthService from "../../Services/AuthService.js.js";
import Toasters from "../../common/Toaster";

const Login = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([{ email: "", password: "" }]);
    const [fields, setFields] = useState([{ email: "", password: "" }]);
    const [errorRes, setErrorRes] = useState("");
    const [toastClose, setToastClose] = useState(true);

    useEffect(() => { }, []);

    const gmails =
        /([a-zA-Z0-9]+)([\_\.\-{1}])?([a-zA-Z0-9]+)\@([a-zA-Z0-9]+)([\.])([a-zA-Z\.]+)/g;

    const handleValidation = () => {
        let formIsValid = true;
        //email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Required";
        } else if (!gmails.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "*Enter Valid Email";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password Cannot be empty ";
        }

        setErrors({ ...errors, ["email"]: errors["email"] });
        setErrors({ ...errors, ["password"]: errors["password"] });

        return formIsValid;
    };

    const handleToastClose = () => {
        setToastClose(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (handleValidation()) {
            let emailId = fields["email"];
            let password = fields["password"];

            AuthService.login(emailId, password).then(
                (response) => {
                    navigate("/admin/dashboard", { state: "Dashboard" });
                },
                (error) => {
                    const responeMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setToastClose(true);
                    setErrorRes({ 'message': responeMessage, "status": "error" });
                }
            );
        }
    };


    return (
        <>
            {errorRes && (
                <Toasters 
                    messages={errorRes}
                    open={toastClose}
                    handleToastClose={handleToastClose}
                />
            )}
            <div className="container logins">
                <div className="row justify-content-center ">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="card shadow login-card  ">
                            <div className="card-title text-center ">
                                <p className="logo-text">
                                    <img src={logo} className="mb-1" />
                                    THERMO LOG
                                </p>
                                <h2 className="login-title">
                                    IOT Client Panel
                                </h2>
                            </div>
                            <div className="login-body">
                                <form>
                                    <div className="mb-3">
                                        <label
                                            for="username"
                                            class="form-label"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="mail"
                                            name="gmail"
                                            id="gmail"
                                            class="form-control"
                                            placeholder="Email Address"
                                            onChange={(e) => {
                                                setFields({
                                                    ...fields,
                                                    email: e.target.value
                                                });
                                                setErrors({
                                                    ...errors,
                                                    ["email"]: false
                                                });
                                            }}
                                        />
                                        {errors["email"] && (
                                            <span className="error-text">
                                                {errors["email"]}
                                            </span>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            for="username"
                                            class="form-label"
                                        >
                                            Password
                                        </label>

                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            class="form-control"
                                            placeholder="Password"
                                            onChange={(e) => {
                                                setFields({
                                                    ...fields,
                                                    password: e.target.value
                                                });
                                                setErrors({
                                                    ...errors,
                                                    ["password"]: false
                                                });
                                            }}
                                        />

                                        {errors["password"] && (
                                            <span className="error-text">
                                                {errors["password"]}
                                            </span>
                                        )}
                                    </div>

                                    <div className="login-submit ">
                                        <button
                                            type="submit"
                                            className="btn-submit "
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div className="mb-4 mt-3">
                                        <Link to="/Forgot">
                                            <p className="login-footer">
                                                Have you forgot your username or
                                                password ?
                                            </p>
                                        </Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Login;
