import React from "react";
import { useNavigate } from "react-router";

const MetricCard = (props) => {

    return (
        <>
            <div
                className={
                    "card-sensor" +
                    ((props.TotalSensor && " total-card") ||
                        (props.ActiveSensor && " active-card") ||
                        (props.NoComm && " warn-card"))
                }
            >
                <div className="row">
                    <div className="col-3">
                        {" "}
                        <div
                            className={
                                "image-sensors" +
                                ((props.TotalSensor && " total-sen") ||
                                    (props.ActiveSensor && " active-sen") ||
                                    (props.NoComm && " warn-sen"))
                            }
                        >
                            <img
                                src={require(`../assets/images/${props.image}`)}
                            />
                        </div>
                    </div>
                    <div className="col-9" >
                        <p className="sensor-degree">
                            {props?.metricData?.noOfSensor ? props?.metricData?.noOfSensor : 0}
                        </p>{" "}
                        <h6 className="sensor-name">
                            {(props.TotalSensor && " Total Sensor") ||
                                (props.ActiveSensor && " Active Sensor") ||
                                (props.NoComm && " No Comm")}
                        </h6>
                    </div>
                </div>
            </div>

        {/* design */}
       
        </>
    );
};

export default MetricCard;
