import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const Toaster = (props) => {

    const handleClose = () => {
        props.handleToastClose()
    }

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            className="bg-dark position-relative">
            <ToastContainer position="top-end" className="p-3" > 
                <Toast bg={props?.messages?.status ? "success" : "danger"} show={props.open} onClose={(e) => handleClose(e)} autohide >
                    <Toast.Header >
                        <strong className="me-auto">{props.messages.message || props.messages}</strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Toaster;