import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL_TEMP =
    process.env.REACT_APP_BASE_URL + "dashboardresource/widget/temperature/sensordetails/";

const API_URL_HUM =
    process.env.REACT_APP_BASE_URL + "dashboardresource/widget/humidity/sensordetails/";

const API_URL_BATT = 
    process.env.REACT_APP_BASE_URL + "dashboardresource/widget/battery/";

const activeCount = () => {
    return axios.get(API_URL_TEMP + "activecount", { headers: AuthHeader() });
};

const batteryCount = () => {
    return axios.get(API_URL_BATT + "batteryinfo",{headers: AuthHeader()})
}

const totalCount = () => {
    return axios.get(API_URL_TEMP + "totalcount", { headers: AuthHeader() });
};
const connectionCount = () => {
    return axios.get(API_URL_TEMP + "connectedcount", {
        headers: AuthHeader()
    });
};

const humConnectionCount = () => {
    return axios.get(API_URL_HUM + "connectedcount", {
        headers: AuthHeader()
    });
};

const humTotalCount = () => {
    return axios.get(API_URL_HUM + "totalcount", { headers: AuthHeader() });
};

const humActiveCount = () => {
    return axios.get(API_URL_HUM + "activecount", { headers: AuthHeader() });
};

const thresholdCount = () => {
    return axios.get(API_URL_HUM + "thresholdcount", { headers: AuthHeader() })
}
const DashboardService = {
    activeCount,
    batteryCount,
    totalCount,
    connectionCount,
    humConnectionCount,
    humTotalCount,
    humActiveCount,
    thresholdCount
};

export default DashboardService;
