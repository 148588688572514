import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import DailyReport from '../../Report/DailyReport';

const ApexChart = (props) => {

    const [timeline, setTimeline] = useState('24h');
    const [download, setDownload] = useState(false);

    const [selectedTab,setSelectedTab] = useState("Temp");

    const temperature = props?.graphDetails?.map(data => ({
        x: new Date(data?.readingDate).getTime(),
        y: data?.tempReading
    }));

    const humidity = props?.graphDetails?.map((data) => ({
        x: new Date(data?.readingDate).getTime(),
        y: data?.humidityReading
    }));

    const handleTimelineSwitch = (value) => {
        setTimeline(value);
    };

    const generateCSVData = () => {

        const heading = 'Daily Report';
        let subHeading = '';
        if (props?.selectedSensor?.label) {
            subHeading = `${props?.selectedSensor?.label}`;
        }
        const csvData = [heading, subHeading, 'Time, Temperature'];
        temperature.forEach((data) => {
            const timestamp = moment(data.x).format('h:mm A');
            const temperature = `${data.y}°C`;
            const row = `${timestamp}, ${temperature}`;
            csvData.push(row);
        });
        return csvData.join('\n');
    };

    const handleExportCSV = () => {
        const csvData = "\ufeff" + generateCSVData();
        const blob = new Blob(["\uFEFF" + csvData], { type: 'text/csv; charset=utf-8' });
        const csvURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = csvURL;
        a.download = `SensorID:${props.graphDetails[0]?.sensorId}_Date:${new Date(props.graphDetails[0]?.readingDate).toLocaleDateString()}.csv`;
        a.click();
        window.URL.revokeObjectURL(csvURL);
    };

    const handleExportImage = async () => {
        const input = document.getElementById('divToPrint');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('table-download.pdf');
        });
    };

    const handleMouseEnter = (status) => {
        setDownload(status);
    }

    const series = [{
            name: props?.selectGraphType === "Temperature" ? "Temperature" : "Humidity",
            // data :temperature
            data: props?.selectGraphType === "Temperature" ? temperature : humidity 
    }];

    const options = {
        colors: ['#ef7a7b'],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,

            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            // animations: {
            //     enabled: false
            // },

            markers: {
                size: 0
            },

            events: {
                beforeResetZoom: () => {
                    setTimeline("24h")

                },
                // exportButtonClick:(event,chartContext,config) => {
                //     if(config.name === 'csv'){
                //         handleExportCSV();
                //     }
                // }
                // click: function(event, chartContext, config) {

                //     // if(config.name === 'csv'){
                //         handleExportCSV();
                //     // }
                //   }

            },

            toolbar: {
                show: true,
                tools: {
                    selection: true,
                    download: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                },

                export: {
                    csv: {
                        filename: `SensorID:${props.graphDetails[0]?.sensorId}_Date:${new Date(props.graphDetails[0]?.readingDate).toLocaleDateString()}`,
                        columnDelimiter: ',',
                        headerCategory: 'Time',
                        headerValue: 'Temperature',
                        dateFormatter: (val) => {
                            return moment(new Date(val)).format('LT')
                        }

                    },
                    // svg: {
                    //     filename: `SensorID:${props.graphDetails[0]?.sensorId}_Date:${new Date(moment(props.graphDetails[0]?.readingDate).format('DD-MM-YYYY')).toLocaleDateString()}`,

                    // },
                    // png: {
                    //     filename: `SensorID:${props.graphDetails[0]?.sensorId}_Date:${new Date(moment(props.graphDetails[0]?.readingDate).format('DD-MM-YYYY')).toLocaleDateString()}`,

                    // }

                },
                autoSelected: 'zoom'
            },
        },
        noData: {
            text: 'No Data Available',
            align: 'center',
            offsetX: 0,
            offsetY: -25,
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 5,
            hover: {
                size: 9
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        },
        yaxis: {
            title: {
                text: props?.selectGraphType === "Temperature" ? "Temperature" : "Humidity" 
            }
        },

        xaxis: {
            type: 'datetime', // Changed from 'category' to 'datetime'
            // tickAmount: 3,
            min: (() => {
                // const lastIndex = props?.graphDetails.length - 1;
                // const currentDate = new Date(props?.graphDetails[lastIndex]?.readingDate);
                const currentDate = new Date(props?.graphDetails[0]?.readingDate);
                const currentTime = currentDate.getTime();
                const oneHourAgo = new Date(currentTime);
                const twoHoursAgo = new Date(currentTime);
                const fourHoursAgo = new Date(currentTime);
                const sixHoursAgo = new Date(currentTime);
                const twelveHoursAgo = new Date(currentTime);
                switch (timeline) {
                    case '1h':
                        return oneHourAgo.getTime()
                    case '2h':
                        return twoHoursAgo.getTime()
                    case '4h':
                        return fourHoursAgo.getTime()
                    case '6h':
                        return sixHoursAgo.getTime()
                    case '12h':
                        return twelveHoursAgo.getTime()
                    case '24h':
                        return undefined
                    default:
                        return undefined;
                }
            })(),

            max: (() => {
                const currentDate = new Date(props?.graphDetails[0]?.readingDate);
                const currentTime = currentDate.getTime();
                const oneHourAgo = new Date(currentTime - 1 * 60 * 60 * 1000);
                const twoHoursAgo = new Date(currentTime - 2 * 60 * 60 * 1000);
                const fourHoursAgo = new Date(currentTime - 4 * 60 * 60 * 1000);
                const sixHoursAgo = new Date(currentTime - 6 * 60 * 60 * 1000);
                const twelveHoursAgo = new Date(currentTime - 12 * 60 * 60 * 1000);
                switch (timeline) {
                    case '1h':
                        return oneHourAgo.getTime()
                    case '2h':
                        return twoHoursAgo.getTime()
                    case '4h':
                        return fourHoursAgo.getTime()
                    case '6h':
                        return sixHoursAgo.getTime()
                    case '12h':
                        return twelveHoursAgo.getTime()
                    case '24h':
                        return undefined
                    default:
                        return undefined;
                }
            })(),

            title: {
                text: 'Time',
                offsetY: 0,
            },

            labels: {
                // show: true,
                // rotate: -20,
                style: {
                    // colors: "black",
                    fontSize: '11px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                },
                // offsetY: 40,
                formatter: (val) => {
                    return moment(new Date(val)).format('D MMMM LT')
                }
            }
        },
        tooltip: {
            shared: false,
            y: {
                formatter: (val) => {
                    console.log(val,"myval");
                    return props?.selectGraphType === "Temperature" ? `${val}°C` :  ` ${val}%`;
                }
            },
            x: {
                formatter: (val) => {
                    const options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        // second: 'numeric',
                        // hour12: false 
                    };
                    return new Date(val).toLocaleString(undefined, options);
                }
            }
        },

    };

    return (
        <>
            <div className='newGraph mt-4'>
                {props?.graphDetails?.length > 0 &&
                    <>
                        <button
                            className={`apexButton ${timeline === '1h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('1h')}
                        >
                            1 Hour
                        </button>
                        <button
                            className={`apexButton ${timeline === '2h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('2h')}
                        >
                            2 Hours
                        </button>
                        <button
                            className={`apexButton ${timeline === '4h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('4h')}
                        >
                            4 Hours
                        </button>
                        <button
                            className={`apexButton ${timeline === '6h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('6h')}
                        >
                            6 Hours
                        </button>
                        <button
                            className={`apexButton ${timeline === '12h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('12h')}
                        >
                            12 Hours
                        </button>
                        <button
                            className={`apexButton ${timeline === '24h' ? 'active' : ''}`}
                            onClick={() => handleTimelineSwitch('24h')}
                        >
                            24 Hours
                        </button>
                        <button
                            className={`CsvBtn ${timeline ? 'active' : ''}`}
                        >
                            <svg onMouseEnter={() => handleMouseEnter(true)} onMouseLeave={() => handleMouseEnter(true)} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </button>
                        {download && (
                            <div className="hidden-section" onMouseLeave={() => handleMouseEnter(false)} >
                                <span
                                    className="mb-2"
                                    onClick={() => handleExportCSV()}
                                >
                                    Download CSV
                                </span>
                                <span
                                    className="mb-1"
                                    onClick={() => handleExportImage()}
                                >
                                    Download PDF
                                </span>
                            </div>
                        )}

                    </>
                }
            </div>

            <div id="chart">
                <ReactApexChart id="divToPrint" options={options} series={series} type="area" height={400} />
            </div>
        </>
    );
};

export default ApexChart;