import React, { useState, useEffect } from "react";
import Header from "../blocks/Header";
import Sidebar from "../blocks/Sidebar";
import { Outlet } from "react-router-dom";
import "../assets/css/common.css";

const Default = (props) => {
    const [outletWrap, setOutletWrap] = useState(false)

    const handleDataFromChild = (data) => {
        setOutletWrap(!outletWrap)
    };

    return (
        <>
            <div className="sidebarHide">
                <Sidebar getSideBarButton={handleDataFromChild} />
            </div>
           
                <Header />
                <div className={outletWrap ? "no-center-wrap" : "center-wrap"}>
                <Outlet />
            </div>
        </>
    );
};

export default Default;
