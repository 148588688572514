import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL

const login = async (emailId, password) => {
 
    const response = await axios.post(API_URL + "userresource/auth/login", {
        emailId,
        password
    });
    
    if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    localStorage.setItem("isAuthenticated", "true");
    return response.data;
};

const AuthService = {
    login
};

export default AuthService;
