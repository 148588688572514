import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation } from "react-router-dom";

function BreadcrumbExample() {

    let location = useLocation();
  

    return (
        <Breadcrumb>
            <Breadcrumb.Item active>{location?.state?.HeadingName}</Breadcrumb.Item>
        </Breadcrumb>
    );
}

export default BreadcrumbExample;
