import React, { useState, useEffect } from 'react'
import { Badge, Button, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import profileImg from "../../assets/images/UserProfile.png";
import BasicSettingService from '../../Services/BasicSettingService'
import Loader from '../../common/Loader';

function UserInfo() {
    const [userInfo, setUserInfo] = useState([])
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({})
    const [userLoaded, setUserLoaded] = useState(true);

    const handleclick = (data) => {
        setEdit(data)
        setShow(true)
    }
    const handleClose = () => {
        setEdit({})
        setShow(false)
    };

    const handleShow = () => {
        setShow(true);
    }
    useEffect(() => {
        getUserInfoDetails();
    }, []);

    const getUserInfoDetails = () => {
        BasicSettingService.getUserInfo()
            .then((res) => (
                setUserInfo(res.data),
                setUserLoaded(false)
            ))
            .catch((err) => console.log(err));
    };

    return (
        <>
            {!userLoaded ? (
                <div className="space">
                    <div className="userInfo">

                        <div className="userProfile p-4">

                            <div className="d-flex align-items-center flex-column">
                                <img className='avtarProfile' src={profileImg} height="100" width="100" ></img>
                            </div>
                            <div className="userInfo text-center">
                                <h4 className='mb-2 mt-2'> Admin-User</h4>
                                <Badge className='userInfoBadge'>User Info</Badge>
                            </div>

                            <div className=''>

                                <Form className='row mt-4'>
                                    <Form.Group className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3" controlId="formGroupEmail">
                                        <Form.Label>First Name </Form.Label>
                                        <Form.Control type="text" defaultValue={userInfo.firstName} />
                                    </Form.Group>
                                    <Form.Group className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3" >
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" defaultValue={userInfo.lastName} />
                                    </Form.Group>
                                    <Form.Group className="col-12 mb-3" controlId="formGroupEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder={userInfo.emailId} disabled />
                                    </Form.Group>
                                    <Form.Group className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3" controlId="formGroupPassword">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="number" defaultValue={userInfo.phoneNumber} />
                                    </Form.Group>
                                    <Form.Group className="col-12 col-md-6 col-lg-6 col-xl-6 mb-3" controlId="formGroupEmail">
                                        <Form.Label>Roles</Form.Label>
                                        <Form.Select disabled >
                                            <option>Super Admin</option>
                                            <option>Admin</option>
                                            <option>Manager</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="userInfobtn mb-3" >
                                        <Button className='modalClose' variant="secondary" onClick={handleShow} >Change Password</Button>
                                        <Button className='modalSave' >Save changes</Button>
                                    </Form.Group>
                                </Form>

                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Change Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form className="row">
                                            <Form.Group className="mb-3 col-12" >
                                                <Form.Label>Old Password</Form.Label>
                                                <Form.Control type="Text" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12" >
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control type="Text" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12" >
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control type="Text" autoFocus />
                                            </Form.Group>

                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='modalSave' onClick={handleClose}>Save Changes </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>

                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>

    )
}

export default UserInfo