import axios from "../utils/Axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL + "notificatoinsresource/";

const notification = async (params) => {
    const notificationUnresolved = { "status": "Unresolved" }
    return await axios.post(API_URL + "notifications", params || notificationUnresolved, { headers: AuthHeader() });
};

const updateNotification = (notificationId, comments) => {
    return axios.patch(API_URL + "notifications/resolved/1", { notificationId, comments }, { headers: AuthHeader() });
};

const notificationCount = () => {
    return axios.get(process.env.REACT_APP_BASE_URL + "dashboardresource/widget/notifications/statuscount/1", { headers: AuthHeader() });
};

const NotificationService = {
    notification, updateNotification, notificationCount
};

export default NotificationService;
