import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router'
import "../../assets/css/dashboard.css";

const Notification = (props) => {
    const [count, setCount] = useState(props.data);
    const [total, setTotal] = useState(0);
    const [corrected, setCorrected] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        filteredData();
    });

    const location = useLocation()

    const filteredData = () => {
        // props.data &&
            // props.data.filter((val) =>val.status?.toLowerCase() === "Resolved").map((i, key) => setUnsolved(i.count));

        // props.data &&
        //     props.data.filter((val) => val.status !== "RESOLVED" || val.status !== "Resolved").map((i, key) => setResolved(i.count));
    };

    const handleNavigate = (status) => {
        navigate("/admin/notification", {state: status})
    }

    let totalCount = props?.data.filter(val => val?.status?.toLowerCase() !== "resolved")[0]?.count
    let correctedCount = props?.data.filter(val => val?.status?.toLowerCase() === "resolved") && 
        props?.data.filter(val => val?.status?.toLowerCase() === "resolved")[0]?.count || 0 

    const data = props.data || [];

    // Calculate the total count
    const Totals = data.reduce((acc, obj) => acc + parseInt(obj.count), 0);
        
    return (
        <>
            <div className="noti-dash">
                <h6 className="user-text ">
                    <svg
                        className="me-3"
                        width="18px"
                        height="18px"
                        viewBox="0 0 20 20"
                        fill="#b73939"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M4 8a6 6 0 0 1 4.03-5.67 2 2 0 1 1 3.95 0A6 6 0 0 1 16 8v6l3 2v1H1v-1l3-2V8zm8 10a2 2 0 1 1-4 0h4z" />
                    </svg>
                    Notification
                </h6>
                { }

                <div className="row mt-3 point">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4" onClick={() => handleNavigate("Unresolved")} >
                        <h6 className="user-text">
                            Total
                            <p className="bl-txt mt-3 ">
                                {Totals}
                            </p>
                        </h6>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4" onClick={() => handleNavigate("Resolved")}>
                        <h6 className="user-text">
                            Corrected
                            <p className="gr-txt mt-3">
                                {props?.data.filter(val => val?.status?.toLowerCase() === "resolved") && 
                                  props?.data.filter(val => val?.status?.toLowerCase() === "resolved")[0]?.count || 0}
                            </p>
                        </h6>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-4" onClick={() => handleNavigate("EmailSent")}>
                        <h6 className="user-text">
                            UnSolved 
                            <p className="re-txt mt-3"> 
                              {parseInt(Totals) - parseInt(correctedCount)} </p>
                        </h6>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notification;
