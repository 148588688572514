import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL;


const getDailyReport = async (params) => {
    return await axios.post(API_URL + "sensorresource/sensordata/dailyreport", params, { headers: AuthHeader() })
};

const getDeviceReadings = async (id) => {
    return await axios.post (API_URL + "sensorresource/sensordata/currentreading",id,{headers:AuthHeader()})
}



const DeviceReportService = {

    getDailyReport,
    getDeviceReadings

}

export default DeviceReportService;