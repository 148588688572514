import React, { useEffect } from 'react'
import moment from 'moment'

export default function Temperature({readingDetails,switchTab}) {

//   const sortedTemperature = temperatureDetails?.slice().sort((a,b) => {
//     moment(b.readingDate).diff(moment(a.readingDate))
//   });

//   const latestTemperature = sortedTemperature.length > 0 ? sortedTemperature[0] : null

  return (
    <>
      <div className='dataview-temperature-card'>
        <div className='temp-content'>
          { readingDetails  ?
            <>
              <h6 className="user-text m-0 p-0">{switchTab === "Temperature" ? "Temperature" : "Humidity"}</h6>
              <div className='temperature'>
				<h1> 
				{switchTab === "Temperature" && readingDetails?.tempReading && `${readingDetails.tempReading}°C`}
				{switchTab === "Humidity" && readingDetails?.humidityReading && `${readingDetails.humidityReading}%`}

			  </h1>
			  </div>
              <div className='temp-date'>{moment(readingDetails?.readingDate)?.format('DD-MM-YYYY, h:mm:ss A')} </div>
            </>
            : <h6 className="user-text mt-1 p-5 d-flex justify-content-center">No Datas Available</h6>
          }
        </div>

      </div>
    </>
  )
}