import axios from "../utils/Axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL;

// Client

const postClients = async params => {
    return await axios.post(API_URL + "clientdetailsresource/details/add", params, { headers: AuthHeader() })
};

const getClients = () => {
    return axios.get(API_URL + "master/clientdetails", { headers: AuthHeader() });
};

const editClients = async (params, id) => {
    return await axios.post(API_URL + "clientdetailsresource/details/edit/" + id, params, { headers: AuthHeader() });
};

// Permission

const postPermission = async params => {
    return await axios.post(API_URL + "userroleresource/role/permission/add",
        params,
        { headers: AuthHeader() })
};
const editPermission = async (params, id) => {
    return await axios.post(API_URL + "userroleresource/role/permission/edit/" + id,
        params,
        { headers: AuthHeader() })
};

const getScreenOption = async () => {
    return await axios.get(API_URL + "userscreenresource/screens", { headers: AuthHeader() });
};
const getWidgetsOption = async (id) => {
    return await axios.get(API_URL + "userscreenresource/widgets/" + id, { headers: AuthHeader() });
};

// Roles

const postRoles = async params => {
    return await axios.post(API_URL + "userroleresource/role/add",
        params,
        { headers: AuthHeader() })
};

const editRoles = async (params, id) => {
    return await axios.post(API_URL + "userroleresource/role/edit/" + id,
        params,
        { headers: AuthHeader() })
}

const getRoles = async () => {
    return await axios.get(API_URL + "master/user/roles", { headers: AuthHeader() });
};

// UserInfo

const getUserInfo = async () => {
    return await axios.get(API_URL + "/userresource/userinfo", { headers: AuthHeader() });
};


// User

const userDetails = () => {
    return axios.get(API_URL + "userresource/user", { headers: AuthHeader() });
};

const postUser = async params => {
    return await axios.post(API_URL + "userprofileresource/user/add",
        params,
        { headers: AuthHeader() })
};
const editUser = async (params, id) => {
    return await axios.post(API_URL + "userprofileresource/user/edit/" + id,
        params,
        { headers: AuthHeader() })
}


const BasicSettingService = {
    postClients,
    editClients,
    getClients,
    postPermission,
    editPermission,
    getScreenOption,
    getWidgetsOption,
    postRoles,
    getRoles,
    editRoles,
    getUserInfo,
    userDetails,
    postUser,
    editUser
}

export default BasicSettingService;