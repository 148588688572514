import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "../../assets/css/commonTable.css";
import NotificationService from "../../Services/NotificationService";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Toasters from "../../common/Toaster";
import Loader from "../../common/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { useLocation, useNavigate } from 'react-router'


const Notification = () => {
    const [notification, setNotification] = useState([].slice(0, 50));
    const [userLoaded, setUserLoaded] = useState(true);
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState("");
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState("");
    const [Id, setId] = useState(false);
    const [toastClose, setToastClose] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [standard, setStandard] = useState([])

    const [commentsAction, setCommentsAction] = useState(false);
    const [otherComment, setOtherComment] = useState("");
    const [toast,setToast] = useState("")

    const { register, clearErrors, handleSubmit, setValue, watch, control, reset } = useForm();

    const location = useLocation()

    let payload = {
        "status": location.state
    }

    const handleStandard = (standardAction) => {
        if (standardAction === "Other") {
            setCommentsAction(!commentsAction)
        } else {
            if (!standard.includes(standardAction)) {
                standard.push(standardAction)
            } else {
                standard.splice(standard.indexOf(standardAction), 1)
            }
            setStandard([...standard])
        }
    }

    const standardAction = [
        "Adjusted Temperature Settings",
        "Notified Maintenance",
        "Closed The Door",
        "Discarded Product",
        "Under Maintenance",
        "Other"
    ]
    const notificationDataPerPage = 10;
    const pagesVisited = pageNumber * notificationDataPerPage;

    useEffect(() => {
        notificationList();
        // setUserLoaded(true)
    }, [errors]);


    const notificationList = (params) => {
        NotificationService.notification(payload)
        .then((res) =>
             (  setNotification(res.data.data.response.notifications),
                setUserLoaded(false)
        ))
            .catch((error) => {
                setToastClose(false);
            });
    };

    const handleClose = () => {
        setShow(false);
        setErrors(false);
        setComment("");
        setStandard([]);
        setCommentsAction(false)
        setOtherComment("")
    };
    const handleToastClose = () => {
        setToastClose(false);
    };

    const handleShow = (notificationId) => {
        setId(notificationId);
        setShow(true);
        setValue('Alertype', notificationId?.humidityThresholdStatus &&
            notificationId?.tempThresholdStatus ? notificationId?.humidityThresholdStatus / notificationId?.tempThresholdStatus
            : notificationId?.humidityThresholdStatus ? notificationId?.humidityThresholdStatus
                : notificationId?.tempThresholdStatus ? notificationId?.tempThresholdStatus : "Hii");
        setValue('Sensorname', notificationId?.sensorName);
        setValue('AlertTime', getDate(notificationId?.sensorTime));
        // setValue('AlertTimeEnd', currentDate);
        setValue('Sensorvalue', notificationId?.tempThresholdStatus ? notificationId?.tempValue : 
        notificationId?.humidityThresholdStatus ? notificationId?.humidityValue :
        notificationId?.humidityThresholdStatus && notificationId?.tempThresholdStatus ? notificationId?.humidityValue / notificationId?.tempValue : "");
        setValue('NormalRange',notificationId?.tempThresholdStatus ? notificationId?.tempLow + "-" + notificationId?.tempHigh
        : notificationId?.humidityLow + "-" + notificationId?.humidityHigh
        );

    };

    const handleSave = async (e) => {
        e.preventDefault();
        let notificationId = Id.id;
        let comments = standard.join(", ");
        if (commentsAction && !otherComment.trim()) {
            console.log("Other Comment is required");
            return;
        }
        if (commentsAction) {
            comments += `,${otherComment}`;
        }
        NotificationService.updateNotification(
            notificationId,
            comments
        ).then((res) => {
            setToastClose(true);
            setStatus(res?.data?.data?.response?.status);
            setUserLoaded(false);
            if (res.data.status == "OK") {
                notificationList();
            }
            handleClose();
        }).catch((error) => {
            setToastClose(true);
            setToast(error?.response?.data?.message)
        });
    };
    const pageCount = Math.ceil(notification.length / notificationDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const getDate = (dateStr) => { return moment(dateStr).format('DD-MM-YYYY, h:mm:ss A'); }
    // const currentDate = moment().format("HH:mm:ss A");


    return (
        <>
            {status ? (
                <Toasters
                    messages={status}
                    open={toastClose}
                    handleToastClose={handleToastClose}
                />
            )
             : 
            <Toasters
            messages={toast}
            open={toastClose}
            handleToastClose={handleToastClose}
        />
        }
            {!userLoaded ?
                (<>
                    <div className="">
                        <div className="space commonTable">
                            <div className=" heading ">Alert Notification</div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <Table hover className="table-responsive-xxl">
                                        <thead className="head">
                                            <tr>
                                                <th>Sl.no</th>
                                                <th>Sensor ID</th>
                                                <th>Alert Type</th>
                                                <th>Temperature Reading</th>
                                                <th>Humidity Reading</th>
                                                <th>Sensor&ensp; Date&emsp;Time</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-body">
                                            {notification.sort((a, b) => new Date(b.sensorTime) - new Date(a.sensorTime))
                                                .slice(pagesVisited, pagesVisited + notificationDataPerPage)
                                                .map((val, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{(pageNumber * notificationDataPerPage) + index + 1}</td>
                                                                <td>{val.sensorId}</td>
                                                                <td>
                                                                    {
                                                                        val?.humidityThresholdStatus || val?.tempThresholdStatus || ""
                                                                    }

                                                                </td>
                                                                <td>
                                                                    {val.tempValue}
                                                                    &#8451;
                                                                </td>
                                                                <td>
                                                                    {val.humidityValue}%
                                                                </td>
                                                                <td>
                                                                    {
                                                                        getDate(val.sensorTime)
                                                                    }
                                                                </td>
                                                                <td>{val?.status}</td>
                                                                <td className="pop-up"> {val?.status !== "Resolved" ?
                                                                    <Button className="pop" variant="primary" size="md" onClick={() => handleShow(val)}>
                                                                        Resolve
                                                                    </Button>
                                                                    :
                                                                    <Button className="resolvedBtn" variant="secondary" size="md">
                                                                        Resolved
                                                                    </Button>
                                                                }
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <Modal show={show} onHide={handleClose} size="xl">
                                <form >
                                    <Modal.Header className="" closeButton>
                                        <Modal.Title>Acknowledge/Clear</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Modal.Title> Alert Notification</Modal.Title>
                                        <Form className="row mt-1">
                                            <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Alert Type</Form.Label>
                                                <Form.Control disabled type="Text"  {...register('Alertype')} name="Alertype" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Sensor Name</Form.Label>
                                                <Form.Control disabled type="Text"  {...register('Sensorname')} name="Sensorname" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Alert Time</Form.Label>
                                                <Form.Control disabled type="Text" {...register('AlertTime')} name="AlertTime" autoFocus />
                                            </Form.Group>
                                            {/* <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Alert End Time</Form.Label>
                                                <Form.Control disabled type="Text" {...register('AlertTimeEnd')} name="AlertTimeEnd" autoFocus />
                                            </Form.Group> */}
                                            <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Sensor value</Form.Label>
                                                <Form.Control disabled type="Text" {...register('Sensorvalue')} name="Sensorvalue" autoFocus />
                                            </Form.Group>
                                            <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4" >
                                                <Form.Label>Normal Range</Form.Label>
                                                <Form.Control disabled type="Text" {...register('NormalRange')} name="NormalRange" autoFocus />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <div style={{ "marginTop": "-40px", "marginBottom": "-30px" }} className="row p-3 mt-1 mb-1">
                                        <Modal.Body className="col-12 col-md-12 col-lg-6 col-xl-6">
                                            <Modal.Title className="mb-1"> Standard Action</Modal.Title>
                                            <div className="standardAction">
                                                {standardAction.map((standardActionData, index) => {
                                                    return (
                                                        <>
                                                            <ul className="listStandardAction point mt-2" onClick={(e) => {handleStandard(standardActionData, index) }}>
                                                                <li >{standardActionData}</li>
                                                            </ul>
                                                        </>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Body className="col-12 col-md-12 col-lg-6 col-xl-6" >
                                            {(standard.length > 0 || commentsAction)&& <Modal.Title className="mb-1"> Standard Action </Modal.Title>}
                                            {
                                               ( standard.length > 0 || commentsAction ) && 
                                                <div className="standardAction">
                                                    {
                                                        standard?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <ul className="listStandardAction point mt-1">
                                                                        <li><svg xmlns="http://www.w3.org/2000/svg" width="30" height="13" fill="green" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                                        </svg>{item}</li>
                                                                    </ul>

                                                                </>
                                                            )
                                                        }
                                                        )
                                                    }
                                                    {commentsAction &&
                                                        <Form.Group className="mb-1 col-12 col-md-6 col-lg-4 col-xl-4 ms-5" style={{width:"80%"}}>
                                                            <Form.Label>Other Comments</Form.Label>
                                                            <Form.Control as="textarea" value={otherComment} onChange={(e) => setOtherComment(e.target.value)} autoFocus />
                                                            {commentsAction && !otherComment.trim() &&
                                                                <>
                                                                    <p style={{ color: "red" }}>Required*</p>
                                                                </>
                                                            }
                                                            {/* {
                                                                !(commentsAction && otherComment?.trim().length >=8 && otherComment?.trim().length <=200)  && 
                                                                <>
                                                                <p style={{ color: "red" }}>Sensor Id should be minimum 8 and maximum 200 digit</p>
                                                            </>
                                                            } */}
                                                        </Form.Group>
                                                    }
                                                    
                                                </div>
                                            }
                                        </Modal.Body>
                                    </div>
                                    <Modal.Footer>
                                        <Button className='modalSave' onClick={(e) => handleSave(e)}>
                                            ADD NOTE/CORRECTIVE
                                        </Button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </div>
                        <div className="">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </>
                ) : (
                    <Loader />
                )}
        </>
    );
};

export default Notification;
