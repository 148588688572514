import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginLayout from "../layouts/LoginLayouts";
import Login from "../pages/Auth/Login";

const CommonRoutes = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<LoginLayout />}>
                    <Route path="/" element={<Login />} />
                </Route>
            </Routes>
        </>
    );
};
export default CommonRoutes;
