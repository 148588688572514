import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import { use } from "echarts";
import ReportServices from "../../Services/ReportServices";
import SensorService from "../../Services/SensorService";
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Loader from "../../common/Loader";
import Toasters from "../../common/Toaster";

function DailySummaryDrop(props) {
    const [clientOption, setClientOption] = useState([])
    const [sensorDetails, setSensorDetails] = useState([])
    const [sensorOption, setSensorOption] = useState([])
    const [dailySummaryData, setDailySummaryData] = useState([])
    const [userLoaded, setUserLoaded] = useState(true);
    const [errorMessage, setErrorMessage] = useState({})
    const [displayToast, setdisplayToast] = useState(false);


    const [tabSwitch, SetTabSwitch] = useState("Temperature");
   
    const validation = Yup.object({
        Client: Yup.object().nullable().required('*Please Select Client'),
        date: Yup.date().required('*Select a Valid Date').max(new Date(), 'Select a Valid Date').min(new Date(new Date().setMonth(new Date().getMonth() - 3)), 'Available Data 3 Months Only'),
        // SensorId: Yup.object().nullable().required('*Please Select Sensor'),

    })

    const { register, clearErrors, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) }
    );

    let watchClientId = watch("Client");
    let sensorId = watch("SensorId");
    let date = watch("date");

    const PreDay = new Date();
    PreDay.setDate(PreDay.getDate() - 1);
    const previousDate = PreDay.toISOString().split('T')[0];


    // useEffect(() => {
    //     if (watchClientId) {
    //     }
    // }, [watchClientId])


    useEffect(() => {
        if (watchClientId) {
            allSensorDetails(watchClientId?.value)
            setSensorOption([])
        }

    }, [watchClientId])

    useEffect(() => {

        const payload = {
            "date": previousDate,
            "clientId": null
        }
        getAllDailySummary(payload);


    }, [])

    useEffect(() => {
        if (tabSwitch === "Humidity") {
            const payloads = {
                "date": previousDate,
                "clientId": null
            }
            getAllHumidityDailySummary(payloads);
        }else{
            const payload = {
                "date": previousDate,
                "clientId": null
            }
            getAllDailySummary(payload);
        }
    }, [tabSwitch])


    const Apply = (e) => {
        if (tabSwitch === "Humidity") {
            const payload = {
                "clientId": watchClientId?.value,
                "sensorId": sensorId?.value,
                "date": date
            }
            { sensorId?.value ? getHumidityDailySummary(payload) : getAllHumidityDailySummary(payload) }
        } else {
            const payload = {
                "clientId": watchClientId?.value,
                "sensorId": sensorId?.value,
                "date": date
            }
            { sensorId?.value ? dailySummaryReportDetails(payload) : getAllDailySummary(payload) }
            if (watchClientId) {
                props?.selectClient(watchClientId?.label);
            }
            setUserLoaded(true);
        }
    }

    useEffect(() => {

        if (props?.dailySummaryDropClient) {
            let clientData = [];
            props?.dailySummaryDropClient !== undefined &&
                props?.dailySummaryDropClient?.length > 0 &&
                props?.dailySummaryDropClient.map((x, key) => {
                    var list = { label: x.clientName, value: x.id };
                    clientData.push(list);
                    setClientOption(clientData);
                });
        }

    }, [props?.dailySummaryDropClient]);

    useEffect(() => {
        if (sensorDetails) {
            let sensorDetailsData = [];
            sensorDetails !== undefined &&
                sensorDetails?.length > 0 &&
                sensorDetails.map((x, key) => {
                    var list = { label: x.sensorName, value: x.sensorId };
                    sensorDetailsData.push(list);
                    setSensorOption(sensorDetailsData);
                });
        }
    }, [sensorDetails])


    const allSensorDetails = (params) => {

        SensorService.getSensorByClientId(params)
            .then((res) => (
                setSensorDetails(res.data.data.response.sensorData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    };

    const currentDate = new Date().toISOString().split('T')[0];

    const dailySummaryReportDetails = (params) => {
        ReportServices.dailySummaryDetails(params)
            .then((res) => (
                setDailySummaryData([res.data.data.response.sensorData]),
                props.fromDailySumaryData([res.data.data.response.sensorData], date),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }


    const getAllDailySummary = (params) => {
        ReportServices.allDailySummaryDetails(params)
            .then((res) => (
                setDailySummaryData(res.data.data.response.sensorData),
                props.fromDailySumaryData(res.data.data.response.sensorData, date || previousDate),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }

    const getHumidityDailySummary = (params) => {
        ReportServices.dailyHumiditySummaryDetails(params)
            .then((res) => {
                props?.fromHumiditySummary([res.data.data.response.sensorData], date)
                setUserLoaded(false)
            }).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }

    const getAllHumidityDailySummary = (params) => {
        ReportServices.allDailyHumiditySummaryDetails(params)
            .then((res) => {
                props?.fromHumiditySummary(res?.data?.data?.response?.sensorData, date || previousDate)
                setUserLoaded(false);
            }).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })
    }

    const handleToastClose = () => {
        setdisplayToast(false)
        setErrorMessage({})
    }

    const options = [
        { value: 'Temperature', label: 'Temperature' },
        { value: 'Humidity', label: 'Humidity' },
    ]

    const handleReadings = (selectedOption) => {
        SetTabSwitch(selectedOption?.value)
        setValue("Client",null)
        setValue("SensorId",null)
        setValue("date","")
        props?.getTempChange(selectedOption)
    }

    return (
        <>{displayToast && (<Toasters messages={errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <form onSubmit={handleSubmit(Apply)}>
                    <div className="Report-Drop row d-flex justify-content-between">
                        <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                            <Form.Label>Select Condition</Form.Label>
                            <Select options={options} defaultValue={options?.find(option => option?.value === tabSwitch)} onChange={(e) => handleReadings(e)} />
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2">
                            <Form.Group>
                                <Form.Label>Select Client</Form.Label>
                                <Controller
                                    control={control}
                                    name="Client"
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            name="Client"
                                            classNamePrefix="select"
                                            options={clientOption}
                                        />} />
                            </Form.Group>
                            <span className="error-text">
                                {errors.Client?.message}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2">
                            <Form.Group>
                                <Form.Label>Select Sensor</Form.Label>
                                <Controller
                                    control={control}
                                    name="SensorId"
                                    render={({ field }) =>
                                        <Select
                                            {...field}
                                            name="SensorId"
                                            classNamePrefix="select"
                                            options={sensorOption}
                                        />} />
                            </Form.Group>
                            <span className="error-text">
                                {errors.SensorId?.message}
                            </span>
                        </div>
                        <div className="col-md-6 col-lg-2 col-xl-2 slect-change">
                            <Form.Group>
                                <Form.Label>Select Date</Form.Label>
                                <InputGroup.Text>
                                    <Controller
                                        control={control}
                                        name="date"
                                        // defaultValue={currentDate}
                                        render={({ field }) =>
                                            <Form.Control
                                                {...field}
                                                name="date"
                                                type="date"
                                                max={sensorId?.value ? new Date().toISOString().split('T')[0] : previousDate}
                                            />} />
                                </InputGroup.Text>
                            </Form.Group>
                            <span className="error-text">
                                {errors.date?.message}
                            </span>
                        </div>

                        <div className="drop-btn  d-flex justify-content-center">
                            <button type='submit' className="drop-down-btn" size="m" >Apply</button>
                        </div>
                    </div>
                </form>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default DailySummaryDrop;
