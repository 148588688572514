import React, { useState, useEffect } from 'react'
import { Badge, Button, Table, Toast } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup";
import BasicSettingService from '../../Services/BasicSettingService'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import Toasters from "../../common/Toaster";
import ReactPaginate from "react-paginate";
import Loader from '../../common/Loader';

const validation = Yup.object({
    Role: Yup.string().required("*Required").nullable().min(3, '* Role Name must be at least 3 characters').max(20, '* Role Name maximum limit of 20'),
    RoleDescription: Yup.string().required('*Required').nullable().min(3, '* RoleDescription must be at least 3 characters').max(50, '*RoleDescription only allow 50 Characters'),
})

function Roles() {
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [editId, setEditId] = useState({})
    const [editSuccess, setEditSuccess] = useState({})
    const [addSuccess, setAddSuccess] = useState({})
    const [displayToast, setdisplayToast] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState({})
    const [rolesDetails, setRolesDetails] = useState([].slice(0, 50))
    const [userLoaded, setUserLoaded] = useState(true);

    const { register, clearErrors, handleSubmit, setValue, reset, formState: { errors } } = useForm({ resolver: yupResolver(validation) });

    useEffect(() => {
        rolesInfoDetails();
    }, []);

    useEffect(() => {
        if (addSuccess?.status === 200) {
            handleClose()
            rolesInfoDetails();
            // setAddSuccess({})
            setdisplayToast(true)
        }
    }, [addSuccess]);

    useEffect(() => {
        if (editSuccess?.status === 200) {
            handleClose()
            rolesInfoDetails();
            setdisplayToast(true)
            // setEditSuccess({})
            // alert(editSuccess?.data?.message)
        }
    }, [editSuccess]);

    const rolesDataPerPage = 10;
    const pagesVisited = pageNumber * rolesDataPerPage;
    const pageCount = Math.ceil(rolesDetails.length / rolesDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const addRoles = (params) => {
        BasicSettingService.postRoles(params).then((res) => (
            setAddSuccess(res),
            setUserLoaded(false)
        )
        ).catch((error) => {
            setErrorMessage(error.response.data.message)
            setdisplayToast(true)
        })
    }

    const EditRole = (params, editId) => {
        BasicSettingService.editRoles(params, editId).then((res) => (
            setEditSuccess(res),
            setUserLoaded(false)
        )).catch((error) => {
            setErrorMessage(error.response.data.message)
            setdisplayToast(true)
        })
    }

    const rolesInfoDetails = () => {
        BasicSettingService.getRoles()
            .then((res) => (
                setRolesDetails(res.data.data.response.userRolesData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage(error.response.data.message)
                setdisplayToast(true)
            })
    };

    const roleSubmit = (data) => {
        const payload = {
            "roleName": data?.Role,
            "roleDescription": data?.RoleDescription
        }
        if (editValue) {
            EditRole(payload, editId)
        } else {
            addRoles(payload)
        }
    }

    const handleclick = (data) => {
        setEdit(data)
        setShow(true)
        setEditValue(true)
        setValue('Role', data?.roleName);
        setValue('RoleDescription', data?.roleDescription);
        setEditId(data?.id)

    }
    const handleClose = () => {
        setEdit({})
        setShow(false)
        clearErrors();
        setEditId({})
        reset()
        setEditValue(false)
    }
    const handleShow = () => {
        setShow(true);

    }
    const handleToastClose = () => {
        setEditSuccess({})
        setAddSuccess({})
        setdisplayToast(false)
        setErrorMessage({})
    }
    return (
        <>
            {displayToast && (<Toasters messages={editSuccess?.data?.data?.response?.status || addSuccess?.data?.data?.response?.status || errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <div className="">
                    <div className="space commonTable">
                        <div className=" heading ">Roles
                            <div className="d-flex justify-content-end">
                                <Button className="gateWayAdd mb-1" size="sm" onClick={handleShow} > Add Roles</Button>
                                <Modal show={show} onHide={handleClose}>
                                    <form onSubmit={handleSubmit(roleSubmit)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{!edit.roleName ? "Add Role" : "Edit Role"}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group className="mb-3 col-12" >
                                                <Form.Label>Role</Form.Label>
                                                <Form.Control {...register('Role')} name="Role" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.Role?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12" >
                                                <Form.Label >Role Description</Form.Label>
                                                <Form.Control {...register('RoleDescription')} name="RoleDescription" as="textarea" rows={4} autoFocus />
                                                <span className="error-text">
                                                    {errors.RoleDescription?.message}
                                                </span>
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button className='modalSave' type='submit' id='myForm'>Save Changes </Button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            </div></div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <Table className="table-responsive-xxl">
                                    <thead className="head">
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Roles</th>
                                            <th>Role Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        {rolesDetails.sort((a, b) => b.id - a.id).slice(
                                            pagesVisited,
                                            pagesVisited +
                                            rolesDataPerPage
                                        ).map((rolesData, index) => (
                                            <tr>
                                                <td>{(pageNumber * rolesDataPerPage) + index + 1}</td>
                                                <td> {rolesData.roleName}</td>
                                                <td>{rolesData.roleDescription}</td>
                                                <td><div className="d-flex justify-content-evenly point">
                                                    <svg onClick={(e) => handleclick(rolesData)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                    </svg>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg> */}
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            ) : (
                <Loader/>
            )}
        </>
    )
}



export default Roles