import React from "react";
import { useState, useEffect } from 'react'
import Table from "react-bootstrap/Table";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import MonthlyDrop from "./MonthlyDrop";
import Modal from 'react-bootstrap/Modal';
import { Badge, Button, Toast } from 'react-bootstrap'
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, BlobProvider, Image, Svg, Circle, Path, G } from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../assets/images/logo-white.png";


const styles = StyleSheet.create({
    body: {
        padding: "10",
        backgroundColor: "#edebf2"
    },
    image: {
        position: 'absolute',
        height: "6%",
        width: "4%",
        backgroundColor: "#3c3b54",
        // top: 0,

    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 32,
        right: 0,
        color: "white",
        backgroundColor: "#3c3b54",
        height: "6%",
        width: "100%",
        paddingTop: "5",
        fontSize: 20,

    },
    heading: {
        textAlign: 'center',
        fontSize: 20,
        color: "#000",
        // paddingBottom: "15"
        // marginBottom: 10,
        marginTop: 25,
    },
    text: {
        textAlign: 'right',
        color: "white",
        fontSize: 10,
        // marginBottom: 10,
    },
    reportHeading: {
        textAlign: 'center',
        fontSize: 15,
        marginTop: 20,
    },
    table: {
        // borderRadius:"5px",
        marginTop: 20,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        backgroundColor: "white"
    },
    tableRow: {
        flexDirection: "row"
    },
    tableCol: {

        width: "30%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 6,
        fontSize: 11
    }

});

const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
];
const COLORS = ["#18c18a", "#a72a58e3"];


const MonthlyReport = (props) => {
    const [show, setShow] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [qaData, setQaData] = useState([]);
    const [fromToDate, setFromToDate] = useState([]);
    const [label, setLabel] = useState([])

    const [qaSummaryByClientId, setQaSummaryByClientId] = useState([])
    const [tabSwitch, SetTabSwitch] = useState("Temperature");

    const handleShow = (datas) => {
        setShow(true);
        setChartData(datas)

    }

    const donut = [
        {
            name: "In Range",
            color: "#18c18a",
            value: chartData?.percentage
        },
        {
            name: "Out of Range",
            color: "#a72a58e3",
            // value: chartData?.outOfRange === null ? Math.abs(chartData?.inRange - 100) : chartData?.outOfRange
            value: chartData?.outRange === null ? 0 : Math.abs(chartData?.percentage - 100)
        },

    ];
    const cx = 50;
    const cy = 50;
    const strokeWidth = 20;
    const radius = 30;
    const dashArray = 2 * Math.PI * radius;
    const startAngle = -90;

    let filled = 0;

    const sum = donut.reduce((sum, item) => {
        return sum + item.value;
    }, 0);

    const ratio = 100 / sum;

    donut.forEach((obj) => {
        const itemRatio = ratio * obj.value;

        obj.itemRatio = itemRatio;
        obj.angle = (filled * 360) / 100 + startAngle;
        obj.offset = dashArray - (dashArray * itemRatio) / 100;
        obj.filled = filled;

        filled += itemRatio;
    });

    const handleClose = () => {
        setShow(false)
    }


    const handleExportPDF = () => {

        const MyDocument = () => (
            <Document>
                <Page orientation="landscape" style={styles.body}>
                    <Image
                        style={styles.image}
                        src={logo} />
                    <Text style={styles.logo}>Thermolog</Text>
                    <Text style={styles.text}>Report Date:{" "}{moment(new Date(fromToDate[0])).format('D-M-Y')} TO {moment(new Date(fromToDate[1])).format('D-M-Y')}</Text>
                    <Text style={styles.heading}>QA Report</Text>
                    {
                        label.length > 0 ?
                            <Text style={styles.reportHeading}>
                                Showing QA Report of {label} sensor Between {moment(new Date(fromToDate[0])).format('D-M-Y')} to {moment(new Date(fromToDate[1])).format('D-M-Y')}
                            </Text>
                            :
                            <Text style={styles.reportHeading}>
                                Showing QA Report for all sensors Between {moment(new Date(fromToDate[0])).format('D-M-Y')} TO {moment(new Date(fromToDate[1])).format('D-M-Y')}
                            </Text>
                    }

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Sensor ID</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Sensor Name</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Location</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Client Name</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Low Reading</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>High Reading</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Avg Reading</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Total Sample Taken</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Out of Range Samples</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>%in Range</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Chart</Text>
                            </View>
                        </View>
                        {tabSwitch === "Temperature" && qaData?.map((Month) => {
                            const data = [
                                { value: Month?.percentage, color: '#18c18a' },
                                { value: Math.abs(Month?.percentage - 100), color: '#b1416a' },
                            ];
                            const total = data.reduce((sum, entry) => sum + entry.value, 0);
                            const startAngles = -90; // Start angle for the first slice
                            let cumulativeAngle = startAngles;
                            return (
                                <>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.sensorId}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.sensorName}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.sensorLocation === null ? "No Data" : Month?.sensorLocation}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.clientName === null ? "No Data" : Month?.clientName}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.min}°C</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.max}°C</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.avg}°C</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.count}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            {/* <Text style={styles.tableCell}>{Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange}</Text> */}
                                            <Text style={styles.tableCell}>{Month && Month?.outRange === null || Month?.outRange === undefined ? Month?.outRange : 0}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{Month?.percentage}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Svg height={40}>
                                                {data.map((entry, index) => {
                                                    const { value, color } = entry;
                                                    const percentage = (value / total) * 100;
                                                    const endAngle = cumulativeAngle + (percentage * 360) / 100;
                                                    // Calculate the coordinates using the provided radius
                                                    const radius = 25 / 2;
                                                    const x1 = radius + radius * Math.cos((cumulativeAngle * Math.PI) / 180);
                                                    const y1 = radius + radius * Math.sin((cumulativeAngle * Math.PI) / 180);
                                                    const x2 = radius + radius * Math.cos((endAngle * Math.PI) / 180);
                                                    const y2 = radius + radius * Math.sin((endAngle * Math.PI) / 180);
                                                    const largeArcFlag = percentage <= 50 ? 0 : 1;
                                                    const pathData = `M${radius},${radius} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} Z`;
                                                    cumulativeAngle = endAngle;
                                                    return (
                                                        <G transform={`translate(${30}, ${8})`} key={index}>
                                                            <Path d={pathData} fill={color} />
                                                        </G>
                                                    );
                                                })}
                                            </Svg>

                                        </View>
                                    </View>
                                </>
                            )
                        })}
                        {tabSwitch === "Humidity" &&
                            tabSwitch === "Humidity" && Array?.isArray(qaSummaryByClientId) && tabSwitch === "Humidity" ? qaSummaryByClientId?.map((Month) => {
                                const data = [
                                    { value: Month?.percentage, color: '#18c18a' },
                                    { value: Math.abs(Month?.percentage - 100), color: '#b1416a' },
                                ];
                                const total = data.reduce((sum, entry) => sum + entry.value, 0);
                                const startAngles = -90; // Start angle for the first slice
                                let cumulativeAngle = startAngles;
                                return (
                                    <>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.sensorId}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.sensorName}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.sensorLocation === null ? "No Data" : Month?.sensorLocation}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.clientName === null ? "No Data" : Month?.clientName}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.min}%</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.max}%</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.avg}%</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.count}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                {/* <Text style={styles.tableCell}>{Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange}</Text> */}
                                                <Text style={styles.tableCell}>{Month && Month?.outRange === null || Month?.outRange === undefined ? Month?.outRange : 0}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{Month?.percentage}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Svg height={40}>
                                                    {data.map((entry, index) => {
                                                        const { value, color } = entry;
                                                        const percentage = (value / total) * 100;
                                                        const endAngle = cumulativeAngle + (percentage * 360) / 100;
                                                        // Calculate the coordinates using the provided radius
                                                        const radius = 25 / 2;
                                                        const x1 = radius + radius * Math.cos((cumulativeAngle * Math.PI) / 180);
                                                        const y1 = radius + radius * Math.sin((cumulativeAngle * Math.PI) / 180);
                                                        const x2 = radius + radius * Math.cos((endAngle * Math.PI) / 180);
                                                        const y2 = radius + radius * Math.sin((endAngle * Math.PI) / 180);
                                                        const largeArcFlag = percentage <= 50 ? 0 : 1;
                                                        const pathData = `M${radius},${radius} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2} Z`;
                                                        cumulativeAngle = endAngle;
                                                        return (
                                                            <G transform={`translate(${30}, ${8})`} key={index}>
                                                                <Path d={pathData} fill={color} />
                                                            </G>
                                                        );
                                                    })}
                                                </Svg>

                                            </View>
                                        </View>
                                    </>
                                )
                            }) : ""
                        }
                    </View>
                </Page>
            </Document>
        );

        const handleRender = ({ blob }) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${moment(new Date(fromToDate[0])).format('D-M-Y')}_TO_${moment(new Date(fromToDate[1])).format('D-M-Y')}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        };

        return (
            <BlobProvider document={<MyDocument />}>
                {({ blob }) => (
                    <Button className="drop-down-btn mt-0" size="m" onClick={() => handleRender({ blob })}>
                        Export PDF
                    </Button>
                )}
            </BlobProvider>
        );
    };

    const qaDataDetails = (data, data1, data2) => {
        setQaData(data)
        setFromToDate([data1, data2]);
    }

    const selectedClientName = (data) => {
        setLabel(data);
    }

    const qaSummary = (data) => {
        setQaSummaryByClientId(data)
    }

    const handleChangetemp = (data) => {
        SetTabSwitch(data)
    }

    return (
        <>
            <MonthlyDrop monthlyDropClient={props.monthlyReportClient}
                fromQareportData={qaDataDetails} selectClient={selectedClientName}
                selectedQaSumary={qaSummary}
                getTempChange={handleChangetemp}
            />
            {qaData.length > 0 ? <>
                <div className="Monthly-Report">
                    <div className="drop-btn d-flex justify-content-end">
                        {handleExportPDF()}
                    </div>
                    <div>
                        {label?.length > 0 ?
                            <>
                                <div className="Monthly">
                                    <h4>Showing QA Report of {label} sensor Between {moment(new Date(fromToDate[0])).format('D-M-Y')} to {moment(new Date(fromToDate[1])).format('D-M-Y')}</h4>
                                </div>
                            </>
                            :
                            <>
                                <div className="Monthly">
                                    <h4>Showing QA Report for all sensors Between {moment(new Date(fromToDate[0])).format('D-M-Y')} TO {moment(new Date(fromToDate[1])).format('D-M-Y')}</h4>
                                </div>
                            </>
                        }
                        <Table striped bordered responsive="xxl">
                            <thead className="head-1">
                                <tr>
                                    <th>Sensor ID</th>
                                    <th>Sensor Name</th>
                                    <th>Location</th>
                                    <th>Client Name</th>
                                    <th>Low Reading</th>
                                    <th>High Reading</th>
                                    <th>Avg Reading</th>
                                    <th>Total Sample Taken</th>
                                    <th>Out of Range Samples</th>
                                    <th>%in Range</th>
                                    <th>Chart</th>
                                </tr>
                            </thead>
                            <tbody className="head-2">
                                {tabSwitch === "Temperature" && qaData?.map((Month, index) => {
                                    const chart = [
                                        { name: "InRange", value: Month?.percentage },
                                        // { name: "OutofRangeSamples", value: Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange }
                                        { name: "OutofRangeSamples", value: Month?.outRange === null ? 0 : Math.abs(Month?.percentage - 100) },
                                    ]
                                    return (
                                        <>
                                            <tr >
                                                <td>{Month?.sensorId} </td>
                                                <td>{Month?.sensorName}</td>
                                                <td>{Month?.sensorLocation === null ? "No Data" : Month?.sensorLocation}</td>
                                                <td>{Month?.clientName === null ? "No Data" : Month?.clientName}</td>
                                                <td>{Month?.min}&#8451;</td>
                                                <td>{Month?.max}&#8451;</td>
                                                <td>{Month?.avg}&#8451;</td>
                                                <td>{Month?.count}</td>
                                                <td>{Month && Month?.outRange === null || Month?.outRange === undefined ? Month?.outRange : 0}</td>
                                                {/* <td>{Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange}</td> */}
                                                <td>{Month?.percentage}</td>
                                                <td className="" onClick={() => handleShow(Month)} >
                                                    <PieChart width={25} height={25}>
                                                        <Pie className="point"
                                                            data={chart}
                                                            outerRadius={10}
                                                            fill="#8884d8"
                                                            paddingAngle={1}
                                                            dataKey="value"
                                                        >
                                                            {data.map(
                                                                (entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={
                                                                            COLORS[
                                                                            index %
                                                                            COLORS.length
                                                                            ]
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Pie>
                                                        <Tooltip position={{ x: -150 }} />
                                                    </PieChart>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                                {Array.isArray(qaSummaryByClientId) && tabSwitch === "Humidity" ?
                                    qaSummaryByClientId?.map((Month, index) => {
                                        const chart = [
                                            { name: "InRange", value: Month?.percentage },
                                            // { name: "OutofRangeSamples", value: Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange }
                                            { name: "OutofRangeSamples", value: Month?.outRange === null ? 0 : Math.abs(Month?.percentage - 100) },]
                                        return (
                                            <>
                                                {Month.min !== 0 && <tr >
                                                    <td>{Month?.sensorId} </td>
                                                    <td>{Month?.sensorName}</td>
                                                    <td>{Month?.sensorLocation === null ? "No Data" : Month?.sensorLocation}</td>
                                                    <td>{Month?.clientName === null ? "No Data" : Month?.clientName}</td>
                                                    <td>{Month?.min}%</td>
                                                    <td>{Month?.max}%</td>
                                                    <td>{Month?.avg}%</td>
                                                    <td>{Month?.count}</td>
                                                    <td>{Month?.outRange}</td>
                                                    {/* <td>{Month && Month?.outRange === null || Month?.outRange === undefined ? Month?.outRange : 0}</td> */}
                                                    {/* <td>{Month?.outOfRange === null ? Math.abs(Month?.inRange - 100) : Month?.outOfRange}</td> */}
                                                    <td>{Month?.percentage}</td>
                                                    <td className="" onClick={() => handleShow(Month)} >
                                                        <PieChart width={25} height={25}>
                                                            <Pie className="point"
                                                                data={chart}
                                                                outerRadius={10}
                                                                fill="#8884d8"
                                                                paddingAngle={1}
                                                                dataKey="value"
                                                            >
                                                                {data.map(
                                                                    (entry, index) => (
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={
                                                                                COLORS[
                                                                                index %
                                                                                COLORS.length
                                                                                ]
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Pie>
                                                            <Tooltip position={{ x: -150 }} />
                                                        </PieChart>
                                                    </td>
                                                </tr>}
                                            </>
                                        )
                                    }) : ""
                                }
                            </tbody>
                        </Table>
                    </div>
                </div >
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <div className="donut-chart">
                                <svg className="point" width="180px" height="180px" viewBox="0 0 100 100">
                                    {donut.map((item) => (
                                        <circle
                                            // key={index}
                                            cx={cx}
                                            cy={cy}
                                            r={radius}
                                            fill="transparent"
                                            strokeWidth={strokeWidth}
                                            stroke={item.color}
                                            strokeDasharray={dashArray}
                                            strokeDashoffset={item.offset}
                                            transform={`rotate(${item.angle} ${cx} ${cy})`}
                                        >
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="rotate"
                                                from={`${startAngle} ${cx} ${cy}`}
                                                to={`${item.angle} ${cx} ${cy}`}
                                                dur="1s"
                                            />
                                            <title>
                                                {item.name}: {item.value}
                                            </title>
                                        </circle>
                                    ))}
                                </svg>
                                <ul className="items-names`">
                                    {donut?.map((item) => (
                                        <li
                                            // key={index++}
                                            className="item-name"
                                            style={{
                                                background: `linear-gradient(90deg, ${item.color} 0, ${item.color} 20px, transparent 20px, transparent 100%)`
                                            }}
                                        >
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                        <div className="chart-Pop-Up">
                            <div className="row mt-3 ">
                                <div className="col-6">
                                    <h6 className="user-text">
                                        Low Reading
                                        <p className="mt-3">
                                            {chartData?.min}
                                        </p>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    <h6 className="user-text">
                                        High Reading
                                        <p className=" mt-3">{chartData?.max}</p>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    <h6 className="user-text">
                                        Avg Reading
                                        <p className=" mt-3">{chartData?.avg}</p>
                                    </h6>
                                </div>
                                <div className="col-6">
                                    <h6 className="user-text">
                                        Total Sample Taken
                                        <p className=" mt-3">{chartData?.count}</p>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal></> : <><div className="commonTable">
                    <div className=" heading ">No Data Available
                    </div>
                </div></>}
        </>
    );
};

export default MonthlyReport;