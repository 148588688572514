import React, { useState } from "react";
import "../assets/css/loader.css";

const Loader = () => {
  return (
    <>
      <div className="loading"></div>
    </>
  );
};

export default Loader;
