import React, { useState, useEffect } from 'react'
import { Badge, Modal, Table, Button, Form } from 'react-bootstrap'
import Select from "react-select";
import SensorService from "../../Services/SensorService";
import BasicSettingService from '../../Services/BasicSettingService'
import ManageDeviceService from '../../Services/ManageDeviceService';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Toasters from "../../common/Toaster";
import ReactPaginate from "react-paginate";
import Loader from '../../common/Loader';


function AllSensorView() {
    const [sensorDetails, setSensorDetails] = useState([])
    const [gatewayDetails, setGatewayDetails] = useState([])
    const [clientDetails, SetClientDetails] = useState([])
    const [clientOption, setClientOption] = useState([])
    const [gatewayOption, setGatewayOption] = useState([])
    const [sensorOption, setSensorOption] = useState([])
    const [temperatureThresOption, setTemperatureThresOption] = useState([])
    const [humidityThresOption, setHumidityThresOption] = useState([])
    const [show, setShow] = useState(false);
    const [editSensor, setEditSensor] = useState({})
    const [addSensorSuccess, setAddSensorSuccess] = useState({})
    const [editSensorSuccess, setEditSensorSuccess] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [editId, setEditId] = useState({})
    const [displayToast, setdisplayToast] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState({})
    const [sensorTypeData, setSensorTypeData] = useState([])
    const [temperatureThresholdData, setTemperatureThresholdData] = useState([])
    const [humidityThresholdData, setHumidityThresholdData] = useState([])
    const [userLoaded, setUserLoaded] = useState(true);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const validation = Yup.object({
        SensorId: Yup.string().required("*Required").nullable().min(8, '*SensorId must be at least 8 characters').max(12, '*SensorId maximum limit of 12'),
        SensorName: Yup.string().required("*Required").nullable().min(2, '*SensorName Name must be at least 2 characters').max(20, '*SensorName Name  maximum limit of 20'),
        // SensorType: Yup.string().required("Required").min(2, '* must be at least 2 characters').max(6, '* only allow 6 Characters'),
        Location: Yup.string().required("Required").min(2, '* must be at least 2 characters').max(100, '* only allow 100 Characters'),
        NotificationEmail: Yup.string().required("Required").test(
            "is-valid-emails",
            "Invalid email format",
            (value) => {
              if (!value) return true; // Allow an empty field
              const emailArray = value.split(",");
              return emailArray.every((email) => emailRegex.test(email.trim()));
            }
          ),
        BatteryAlert: Yup.string().required("Required"),
        Interval: Yup.string().min(1, 'Required Minimum One Number').required('required').test('no-leading-zero', 'Enter valid Interval', (value, context) => { return context.originalValue && !context.originalValue.startsWith('0'); }),
    })

    const { register, clearErrors, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm(

        { resolver: yupResolver(validation) }

    );

    const temperatureMinMax = watch("TemThreshold")
    const humidityMinMax = watch("HumThreshold")


    useEffect(() => {
        if (temperatureMinMax?.value === "Freezer") {
            setValue("tempHigh", -22)
            setValue("tempLow", -16)
        } else if (temperatureMinMax?.value === "Chiller") {
            setValue("tempHigh", 5)
            setValue("tempLow", 0)
        } else if (temperatureMinMax?.value === "Room Temperature") {

            setValue("tempHigh", editSensor?.tempHigh || 30)
            setValue("tempLow", editSensor?.tempLow || 20)
        }
        if (temperatureMinMax?.value === "Room Temperature") {
            setValue("humLow", editSensor?.humidityLow || 50)
            setValue("humHigh", editSensor?.humidityHigh || 70)
        }
        // else if (humidityMinMax?.value === "Chiller") {
        //     setValue("humLow", 25)
        //     setValue("humHigh", 55)
        // }


    }, [temperatureMinMax, humidityMinMax]);


    useEffect(() => {
        clientsInfoDetails();
        allSensorDetails();
        gatewayInfoDetails();
        allSensorType()
        allHumidityThershold()
        allTemperatureThershold()

    }, []);

    useEffect(() => {
        if (addSensorSuccess?.status === 200) {
            handleClose()
            gatewayInfoDetails()
            allSensorDetails();
            setdisplayToast(true)

        }
    }, [addSensorSuccess]);

    useEffect(() => {
        if (editSensorSuccess?.status === 200) {
            handleClose()
            gatewayInfoDetails()
            allSensorDetails();
            setdisplayToast(true)

        }
    }, [editSensorSuccess]);

    useEffect(() => {

        if (clientDetails) {
            let clientData = [];
            clientDetails !== undefined &&
                clientDetails?.length > 0 &&
                clientDetails.map((x, key) => {
                    var list = { label: x.clientName, value: x.id };
                    clientData.push(list);
                    setClientOption(clientData);
                });
        }

    }, [clientDetails]);

    useEffect(() => {

        if (gatewayDetails) {
            let gatewayData = [];
            gatewayDetails !== undefined &&
                gatewayDetails?.length > 0 &&
                gatewayDetails.map((x, key) => {
                    var list = { label: x.gatewayType, value: x.gatewayId };
                    gatewayData.push(list);
                    setGatewayOption(gatewayData);
                });
        }

    }, [gatewayDetails]);

    useEffect(() => {

        if (sensorTypeData) {
            let sensorTypeDetails = [];
            sensorTypeData !== undefined &&
                sensorTypeData?.length > 0 &&
                sensorTypeData.map((x, key) => {
                    var list = { label: x, value: x };
                    sensorTypeDetails.push(list);
                    setSensorOption(sensorTypeDetails);
                });
        }

    }, [sensorTypeData]);

    useEffect(() => {

        if (temperatureThresholdData) {
            let temperatureThresholdDetails = [];
            temperatureThresholdData !== undefined &&
                temperatureThresholdData?.length > 0 &&
                temperatureThresholdData.map((x, key) => {
                    var list = { label: x, value: x };
                    temperatureThresholdDetails.push(list);
                    setTemperatureThresOption(temperatureThresholdDetails);
                });
        }

    }, [temperatureThresholdData]);

    useEffect(() => {

        if (humidityThresholdData) {
            let humidityThresholdDetails = [];
            humidityThresholdData !== undefined &&
                humidityThresholdData?.length > 0 &&
                humidityThresholdData.map((x, key) => {
                    var list = { label: x, value: x };
                    humidityThresholdDetails.push(list);
                    setHumidityThresOption(humidityThresholdDetails);
                });
        }

    }, [humidityThresholdData]);

    const clientsInfoDetails = () => {
        BasicSettingService.getClients()
            .then((res) => (
                SetClientDetails(res.data.data.response.clientData),
                setUserLoaded(false)
            )
            ).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };
    const gatewayInfoDetails = () => {
        ManageDeviceService.getGateway()
            .then((res) => (
                setGatewayDetails(res.data.data.response.gatewaytData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const allSensorDetails = () => {
        ManageDeviceService.getAllSensorDetails()
            .then((res) => (
                setSensorDetails(res.data.data.response.sensorDetailstData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const allSensorType = () => {
        ManageDeviceService.getSensorType()
            .then((res) => (
                setSensorTypeData(res.data.data.response.sensorTypes)
            ))
    };

    const allTemperatureThershold = () => {
        ManageDeviceService.getTemperatureThreshold()
            .then((res) => (
                setTemperatureThresholdData(res.data.data.response.temperatureTypes)
            ))
    };

    const allHumidityThershold = () => {
        ManageDeviceService.getHumidityThreshold()
            .then((res) => (
                setHumidityThresholdData(res.data.data.response.humidityTypes)
            ))
    };

    const addAllSensorDatadetails = (params) => {
        ManageDeviceService.postAllSensorDetails(params).then((res) => (
            setAddSensorSuccess(res),
            setUserLoaded(false)
        )).catch((error) => {
            setErrorMessage({'message': error.response.data.message, "status":"error"})
            setdisplayToast(true)
        })
    }
    const editSensorData = (params, editId) => {
        ManageDeviceService.editAllSensorDetails(params, editId).then((res) => (
            setEditSensorSuccess(res),
            setUserLoaded(false)
        )).catch((error) => {
            setErrorMessage({'message': error.response.data.message, "status":"error"})
            setdisplayToast(true)
        })
    }

    const sensorSubmit = (data) => {
        const payload = {
            "sensorId": data?.SensorId,
            "sensorName": data?.SensorName,
            "sensorType": data?.SensorType?.value,
            "location": data?.Location,
            "batteryAlert": data?.BatteryAlert,
            "interval": data?.Interval,
            "tempThresholdType": data?.TemThreshold?.value,
            "humidityThresholdType": data?.HumThreshold?.value || "Room Humidity",
            "tempHigh": data?.tempHigh,
            "tempLow": data?.tempLow,
            "humidityHigh": data?.humHigh || 70,
            "humidityLow": data?.humLow || 50,
            "emails": data?.NotificationEmail?.split(','),
            "clientId": data?.Client?.value,
            "gatewayId": data?.GatewayId?.value,
            "activeStatus": `${data?.Status ? data?.Status?.value : 1}`
        }
        if (editValue) {
            editSensorData(payload, editId)
        }
        else {
            addAllSensorDatadetails(payload)
        }
      
    }

    const allSensorDataPerPage = 10;
    const pagesVisited = pageNumber * allSensorDataPerPage;
    const pageCount = Math.ceil(sensorDetails.length / allSensorDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        if (filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const handleclick = (data) => {
        let tem = ""
        data?.emails.forEach((email, index) => {
            if (index > 0) {
                tem += ", "
                tem += email.emailId
            }
            else {
                tem += email.emailId
            }
        })
        setEditSensor(data)
        setShow(true)
        setEditValue(true)
        setEditId(data?.id)
        setValue('SensorId', data?.sensorId);
        setValue('SensorName', data?.sensorName);
        setValue('Location', data?.location);
        setValue('BatteryAlert', data?.batteryAlert);
        setValue('Interval', data?.deviceInterval);
        setValue('tempHigh', data?.tempHigh);
        setValue('tempLow', data?.tempLow);
        setValue('humHigh', data?.humidityHigh);
        setValue('humLow', data?.humidityLow);
        filterObject("Client", clientOption, data?.clientId);
        filterObject("GatewayId", gatewayOption, data?.gatewayId);
        filterObject("SensorType", sensorOption, data?.sensorType)
        filterObject("TemThreshold", temperatureThresOption, data?.tempThreshold)
        filterObject("HumThreshold", humidityThresOption, data?.humidityThreshold)
        filterObject("Status", Options, data?.activeStatus);
        setValue('NotificationEmail', tem);

    }


    const getGatewaybyId = (value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            gatewayOption &&
            gatewayOption?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        return filtered && filtered[0]?.label
    };

    const getClientById = (value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            clientOption &&
            clientOption?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        return filtered && filtered[0]?.label
    };

    const Options = [
        {
            label: "Active",
            value: 1
        },
        {
            label: "Inactive",
            value: 0
        },
    ];

    const handleClose = () => {
        setEditSensor({})
        setEditValue(false)
        setEditId({})
        setShow(false)
        clearErrors()
        reset()
    };

    const handleShow = () => {
        setShow(true);
        reset()
        filterObject("TemThreshold", temperatureThresOption, "Freezer")
        // filterObject("HumThreshold", humidityThresOption, "Freezer")
    }
    const handleToastClose = () => {
        setAddSensorSuccess({})
        setEditSensorSuccess({})
        setdisplayToast(false)
        setErrorMessage({})

    };

    return (<>
        {displayToast && (<Toasters messages={addSensorSuccess?.data?.data?.response?.status || editSensorSuccess?.data?.data?.response?.status || errorMessage} handleToastClose={handleToastClose} />)}
        {!userLoaded ? (
            <div className="">
                <div className="space commonTable">
                    <div className=" heading ">All Sensor
                        <div className="d-flex justify-content-end">
                            <Button className="gateWayAdd mb-1" size="sm" onClick={handleShow} >Add Sensor</Button>
                            <Modal show={show} onHide={handleClose} >
                                <form onSubmit={handleSubmit(sensorSubmit)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{!editSensor.sensorId ? "Add Sensor" : "Edit Sensor"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form className="row">
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Sensor Name</Form.Label>
                                                <Form.Control  {...register('SensorName')} name="SensorName" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.SensorName?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Sensor Id</Form.Label>
                                                <Form.Control disabled={editSensor.sensorId}  {...register('SensorId')} name="SensorId" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.SensorId?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Sensor Type</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="SensorType"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="SensorType"
                                                            classNamePrefix="select"
                                                            options={sensorOption} />} />

                                                <span className="error-text">
                                                    {errors.SensorType?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Gateway Name</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="GatewayId"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="GatewayId"
                                                            classNamePrefix="select"
                                                            options={gatewayOption} />} />
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Client</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="Client"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="Client"
                                                            classNamePrefix="select"
                                                            options={clientOption}
                                                        />} />
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Location</Form.Label>
                                                <Form.Control   {...register('Location')} name="Location" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.Location?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Temperature Threshold</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="TemThreshold"
                                                    render={({ field }) =>
                                                        <Select

                                                            {...field}
                                                            name="TemThreshold"
                                                            // value={temperatureThresOption[0]}
                                                            classNamePrefix="select"
                                                            // onChange={(e) => temperatureOption(e)}
                                                            options={temperatureThresOption}
                                                        />} />

                                            </Form.Group>
                                            <Form.Group className="mb-3 col-6 col-md-3 col-lg-3 col-xl-3" >
                                                <Form.Label>MIN</Form.Label>
                                                <Form.Control type="Text" autoFocus placeholder='MIN'  {...register('tempLow')}
                                                    readOnly={temperatureMinMax?.value !== "Room Temperature" ? true : false}

                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-6 col-md-3 col-lg-3 col-xl-3" >
                                                <Form.Label>MAX</Form.Label>
                                                <Form.Control type="Text" autoFocus placeholder='MAX' {...register('tempHigh')}
                                                    readOnly={temperatureMinMax?.value !== "Room Temperature" ? true : false}

                                                />
                                            </Form.Group>
                                            {temperatureMinMax?.value === "Room Temperature" && <>
                                                <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                                    <Form.Label>Humidity Threshold</Form.Label>
                                                    <Form.Control readOnly defaultValue="Room Humidity" name="HumThreshold" type="Text" autoFocus />
                                                    {/* <Controller
                                                    control={control}
                                                    name="HumThreshold"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}F
                                                            name="HumThreshold"
                                                            classNamePrefix="select"
                                                            options={humidityThresOption} />} /> */}
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-6 col-md-3 col-lg-3 col-xl-3" >
                                                    <Form.Label>MIN</Form.Label>
                                                    <Form.Control type="Text" autoFocus placeholder='MIN' {...register('humLow')}
                                                    // readOnly={humidityMinMax?.value !== "Room Humidity" ? true : false}
                                                    // value={humidityMinMax?.value !== "Freezer" ? "20" : "15"}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3 col-6 col-md-3 col-lg-3 col-xl-3" >
                                                    <Form.Label>MAX</Form.Label>
                                                    <Form.Control type="Text" autoFocus placeholder='MAX' {...register('humHigh')}
                                                    // readOnly={humidityMinMax?.value !== "Room Humidity" ? true : false}
                                                    // value={humidityMinMax?.value !== "Freezer" ? "12" : "8"} 
                                                    />
                                                </Form.Group></>}
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Interval</Form.Label>
                                                <Form.Control {...register('Interval')} name="Interval" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.Interval?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-6 col-lg-6 col-xl-6" >
                                                <Form.Label>Battery Alert</Form.Label>
                                                <Form.Control  {...register('BatteryAlert')} name="BatteryAlert" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.BatteryAlert?.message}
                                                </span>
                                            </Form.Group>
                                            <Form.Group className="mb-3  col-12 col-md-12 col-lg-12 col-xl-12" >
                                                <Form.Label>Notification Email</Form.Label>
                                                <Form.Control  {...register('NotificationEmail')} name="NotificationEmail" type="Text" autoFocus />
                                                <span className="error-text">
                                                    {errors.NotificationEmail && errors.NotificationEmail?.message}
                                                </span>
                                            </Form.Group>
                                            {editSensor.sensorId && <Form.Group className="mb-3  col-12 col-md-12 col-lg-12 col-xl-12" >
                                                <Form.Label>Status</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="Status"
                                                    render={({ field }) =>
                                                        <Select
                                                            {...field}
                                                            name="Status"
                                                            classNamePrefix="select"
                                                            options={Options}
                                                        />} />
                                            </Form.Group>}
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='modalSave' type='submit'>Save Changes </Button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                        </div></div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <Table className="table-responsive-xxl">
                                <thead className="head">
                                    <tr>
                                        <th>Sl.no</th>
                                        <th>Sensor Name</th>
                                        <th>Sensor Id</th>
                                        <th>Sensor Type</th>
                                        <th>Gateway Name</th>
                                        <th>Temperature Threshold</th>
                                        <th>Humidty Threshold</th>
                                        <th>Client</th>
                                        <th>Location</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <> 
                                    {sensorDetails.sort((a, b) => b.id - a.id).slice(pagesVisited, pagesVisited +allSensorDataPerPage).map((sensorDataDetails, index) => (
                                        <tr>
                                            <td>{(pageNumber * allSensorDataPerPage) + index + 1}</td>
                                            <td>{sensorDataDetails.sensorName}</td>
                                            <td>{sensorDataDetails.sensorId}</td>
                                            <td>{sensorDataDetails.sensorType}</td>
                                            <td>{getGatewaybyId(sensorDataDetails.gatewayId)}</td>
                                            <td>{sensorDataDetails.tempLow}&#8451; - {sensorDataDetails.tempHigh}&#8451;</td>
                                            <td>{sensorDataDetails.humidityLow}% - {sensorDataDetails.humidityHigh}% </td>
                                            <td>{getClientById(sensorDataDetails.clientId)}</td>
                                            <td>{sensorDataDetails.location}</td>
                                            <td><Badge className={sensorDataDetails.activeStatus === 1 ? "statusGreen" : "statusRed"}>{sensorDataDetails.activeStatus === 1 ? "Active" : "InActive"}</Badge></td>
                                            <td><div className="d-flex justify-content-evenly point">
                                                <svg onClick={(e) => handleclick(sensorDataDetails)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg> */}
                                            </div></td>
                                        </tr>
                                    ))}
                                    </>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={4}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    />
                </div>
            </div>
        ) : (
            <Loader />
        )}
    </>
    )
}

export default AllSensorView