import React, { useEffect, useState } from "react";
import "../../assets/css/dashboard.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router";
import Loader from '../../common/Loader';


const Battery = (props) => {

    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(loaderTimeout);
  }, []);

    const percentage = 100;
    const percentage2 = 0;

    const handleClick = (data) => {
        if(data >= 2.9){
            navigate(`/admin/batteryVoltage`,{ state: {noOfSensor: "positive" } });
        }else if(data >= 0 && data < 2.9 ){
            navigate(`/admin/batteryVoltage`,{ state: {noOfSensor: "negative" } });
        }else {
           return null
        }
    }
    
    // if (props?.batterydata?.batteryInfo?.lowBatteryCount === undefined) {
    //     return <Loader />; 
    // }

    return (
        <>
          
         <div className="battery-dash" >
        <h6 className="user-text">Battery Health</h6>
        {(props?.batterydata?.batteryInfo?.lowBatteryCount === undefined) && showLoader ? 
        <div class="widgetLoader" id="loader-4">
          <span></span>
          <span></span>
          <span></span>
        </div>
         : 
        <div className="row ">
            <div className="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-6 col-xxl-6 point">
                <div className="circle-val" onClick={() => handleClick(props?.batterydata?.batteryInfo?.normalBatteryCount)}>
                    <CircularProgressbar 
                        value={percentage}
                        text={props?.batterydata?.batteryInfo?.normalBatteryCount}
                        styles={buildStyles({textSize: "18px",pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                            textColor: "#3c3b54",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7"
                        })}
                    />
                </div>
            </div>
            <div className="col-6 col-sm-6 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                <div className="circle-val2 point" onClick={() => handleClick(props?.batterydata?.batteryInfo?.lowBatteryCount)}>
                    <CircularProgressbar 
                        value={percentage2}
                        text={props?.batterydata?.batteryInfo?.lowBatteryCount + ""}
                        styles={buildStyles({textSize: "18px", pathColor: props?.batterydata?.batteryInfo?.lowBatteryCount === 0
                        ? "#d6d6d6" 
                        : (props?.batterydata?.batteryInfo?.lowBatteryCount > 0 && props?.batterydata?.batteryInfo?.lowBatteryCount < 2.9)
                            ? "red" 
                            : `rgba(62, 152, 199, ${percentage2 / 100})`,  
                    textColor: "#3c3b54",
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                        textColor: "#3c3b54",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7"
                    })}
                    />
                </div>
            </div>
        </div>
        }
       </div>
    </>
        
       
    );
};

export default Battery;
