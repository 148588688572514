import { Navigate, useLocation } from "react-router-dom";
import AuthHeader from "./AuthHeader";
import jwtDecode from "jwt-decode";

const Protected = ({ children }) => {
    let location = useLocation();
    let user = isAuthenticated();

    const auth = JSON.parse(localStorage.getItem("isAuthenticated"));
    if (!user) {
        return <Navigate to="/" state={{ from: location }} replace />;
    } else {
        AuthHeader();
    }
    return children;
};

const isAuthenticated = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));

    if (!loggedInUser || !loggedInUser.accessToken) {
        return false;
    } else {
        const decoded = jwtDecode(loggedInUser.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            
            console.log("access token expired");
           
        } else {
            return true;
        }
    }
};

export default Protected;
