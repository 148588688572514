import React, { useState, useEffect } from 'react'
import { Badge, Button, Table } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router';
import SensorService from "../../Services/SensorService";
import ManageDeviceService from '../../Services/ManageDeviceService';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Select from "react-select";
import BasicSettingService from '../../Services/BasicSettingService'
import Toasters from "../../common/Toaster";
import ReactPaginate from "react-paginate";
import Loader from '../../common/Loader';



function Group() {
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({})
    const [selecteAll, setSelectAll] = useState([])
    const [sensorGroupDetails, setSensorGroupDetails] = useState([])
    const [sensorDetails, setSensorDetails] = useState([])
    const [gatewayOption, setGatewayOption] = useState([])
    const [gatewayDetails, setGatewayDetails] = useState([])
    const [user, setUser] = useState([]);
    const [userOption, setUserOption] = useState([])
    const [addSensorGroupSuccess, setAddSensorGroupSuccess] = useState({})
    const [editSensorGroupSuccess, setEditsensorGroupSuccess] = useState({})
    const [editId, setEditId] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [displayToast, setdisplayToast] = useState(false)
    const [checkedSensor, setCheckedSensor] = useState([])
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState({})
    const [userLoaded, setUserLoaded] = useState(true);


    const validation = Yup.object({
        GroupName: Yup.string().required("*Required").nullable().min(4, '*GroupName must be at least 4 characters').max(100, '*GroupName maximum limit of 100'),
        GroupDescription: Yup.string().required("*Required").nullable().min(2, '*GroupDescription Name must be at least 2 characters').max(100, '*GroupDescription Name  maximum limit of 100'),
    })


    const { register, clearErrors, handleSubmit, setValue, control, reset, watch, formState: { errors } } = useForm({ resolver: yupResolver(validation) });


    const groupSensorDataPerPage = 10;
    const pagesVisited = pageNumber * groupSensorDataPerPage;
    const pageCount = Math.ceil(sensorGroupDetails.length / groupSensorDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    useEffect(() => {
        sensorGroupAllData()
        gatewayInfoDetails()
        userInfo()
        allSensorDetails()

    }, []);

    useEffect(() => {

    }, [checkedSensor]);

    useEffect(() => {

        if (gatewayDetails) {
            let gatewayData = [];
            gatewayDetails !== undefined &&
                gatewayDetails?.length > 0 &&
                gatewayDetails.map((x, key) => {
                    var list = { label: x.gatewayType, value: x.gatewayId };
                    gatewayData.push(list);
                    setGatewayOption(gatewayData);
                });
        }

    }, [gatewayDetails]);


    useEffect(() => {

        if (user) {
            let userData = [];
            user !== undefined &&
                user?.length > 0 &&
                user.map((x, key) => {
                    var list = { label: x.firstName + " " + x.lastName, value: x.id };
                    userData.push(list);
                    setUserOption(userData);
                });
        }

    }, [user]);

    let watchGatewayId = watch("GatewayId");

    useEffect(() => {
        if (watchGatewayId) {
            postGatewatId(watchGatewayId?.value)
        }

    }, [watchGatewayId])

    useEffect(() => {
        if (addSensorGroupSuccess?.status === 200) {
            handleClose()
            sensorGroupAllData()
            setdisplayToast(true)

        }
    }, [addSensorGroupSuccess]);

    useEffect(() => {
        if (editSensorGroupSuccess?.status === 200) {
            handleClose()
            sensorGroupAllData()
            setdisplayToast(true)

        }
    }, [editSensorGroupSuccess]);


    const gatewayInfoDetails = () => {
        ManageDeviceService.getGateway()
            .then((res) => (
                setGatewayDetails(res.data.data.response.gatewaytData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };


    const userInfo = () => {
        BasicSettingService.userDetails()
            .then((res) => (
                setUser(res.data.data.response.UserData),
                setUserLoaded(false)
            ))
            .catch((err) => console.log(err));

    };

    const addSensorGroupDetails = (params) => {
        ManageDeviceService.postSensorGroup(params)
            .then((res) => (
                setAddSensorGroupSuccess(res),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const editSensorGroupDetails = (params, editId) => {
        ManageDeviceService.editSensorGroup(params, editId)
            .then((res) => (
                setEditsensorGroupSuccess(res),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const postGatewatId = (params) => {
        let payload = {
            "gatewayId": params
        }
        ManageDeviceService.postGatewayById(payload)
            .then((res) => {

                setSelectAll(res.data.data.response.sensorData)
            }
            ).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })
    };

    const sensorGroupAllData = () => {
        ManageDeviceService.getAllSensorGroup()
            .then((res) => (
                setSensorGroupDetails(res.data.data.response.sensorGrouptData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage({'message': error.response.data.message, "status":"error"})
                setdisplayToast(true)
            })

    };

    const allSensorDetails = () => {
        ManageDeviceService.getAllSensorDetails()
            .then((res) => {

                // setSelectAll(res?.data?.data?.response?.sensorDetailstData)
            }

            )
    };

    const groupSensorSubmit = (data) => {
        const payload = {
            "groupName": data?.GroupName,
            "groupDescription": data?.GroupDescription,
            "gatewayId": data?.GatewayId?.value,
            "userId": data?.User?.value,
            "sensorIds": checkedSensor,
        }

        if (editValue) {
            editSensorGroupDetails(payload, editId)

        } else {
            addSensorGroupDetails(payload)
        }

    }


    const handleSelectAll = (e) => {
        selecteAll.map(data => {

            if (!checkedSensor.includes(data.sensorId)) {
                checkedSensor.push(data.sensorId)
            }
            setCheckedSensor([...checkedSensor])
        })
    }


    const handleDeSelectAll = (e) => {
        setCheckedSensor([])
    }



    const handleCheck = (e, index, sensorId) => {
        if (e.target.checked) {
            checkedSensor.push(sensorId)
            setCheckedSensor([...checkedSensor])
        }
        else {
            checkedSensor.splice(checkedSensor.indexOf(sensorId), 1)
            setCheckedSensor([...checkedSensor])
        }

    }

    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        if (filtered[0]) {
            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const handleclick = (data) => {
        setEdit(data)
        setShow(true)
        setEditId(data?.id)
        setEditValue(true)
        setValue('GroupName', data?.groupName);
        setValue('GroupDescription', data?.groupDescription);
        filterObject("GatewayId", gatewayOption, data?.groupDetails[0]?.gatewayId);
        filterObject("User", userOption, data?.groupDetails[0]?.userId);
        data?.groupDetails.map(sensor => {
            checkedSensor.push(sensor.sensorId)

        })
        setCheckedSensor([...checkedSensor])

    }
    const handleClose = () => {
        setEdit({})
        setShow(false)
        setEditValue(false)
        reset()
        clearErrors()
        setCheckedSensor([])

    };

    const handleShow = () => {
        reset()
        setShow(true);
        setSelectAll([])

    }
    const navigate = useNavigate()

    const getGatewayById = (value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            gatewayOption &&
            gatewayOption?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );
        return filtered && filtered[0]?.label
    };


    const handleToastClose = () => {
        setAddSensorGroupSuccess({})
        setEditsensorGroupSuccess({})
        setdisplayToast(false)
        setErrorMessage({})


    };

    return (<>
        {displayToast && (<Toasters messages={addSensorGroupSuccess?.data?.data?.response?.status || editSensorGroupSuccess?.data?.data?.response?.status || errorMessage} handleToastClose={handleToastClose} />)}
        {!userLoaded ? (
        <div className="">
            <div className="space commonTable">
                <div className=" heading ">All Group Details
                    <div className="d-flex justify-content-end">
                        <Button className="gateWayAdd mb-1" size="sm" onClick={handleShow} > Add Group</Button>
                        <Modal show={show} onHide={handleClose} size="xl">
                            <form onSubmit={handleSubmit(groupSensorSubmit)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{!edit.groupName ? "Add Group" : "Edit Group"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form className="row">
                                        <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                            <Form.Label>Group Name</Form.Label>
                                            <Form.Control  {...register('GroupName')} type="Text" />
                                            <span className="error-text">
                                                {errors.GroupName?.message}
                                            </span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                            <Form.Label>Group Description</Form.Label>
                                            <Form.Control  {...register('GroupDescription')} type="Text" />
                                            <span className="error-text">
                                                {errors.GroupDescription?.message}
                                            </span>
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                            <Form.Label>Gateway Name</Form.Label>
                                            <Controller
                                                control={control}
                                                name="GatewayId"
                                                render={({ field }) =>
                                                    <Select
                                                        {...field}
                                                        name="GatewayId"
                                                        classNamePrefix="select"
                                                        options={gatewayOption}
                                                    />} />
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-12 col-md-6 col-lg-6 col-xl-6" >
                                            <Form.Label>User</Form.Label>
                                            <Controller
                                                control={control}
                                                name="User"
                                                render={({ field }) =>
                                                    <Select
                                                        {...field}
                                                        name="User"
                                                        classNamePrefix="select"
                                                        options={userOption}
                                                    />} />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Header className='groupHeader'>
                                    <Modal.Title>Sensor In Group</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selecteAll.length > 0 &&
                                        <div className="d-flex justify-content-around">
                                            <Button className='modalSave' onClick={(e) => handleSelectAll(e)} >Select All</Button>
                                            <Button className='modalSave' onClick={(e) => handleDeSelectAll(e)}>Deselect All</Button>
                                        </div>}
                                    <div className="commonTable">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12">
                                                {selecteAll.length > 0 ?
                                                    <Table className="table-responsive-xxl" hover bordered  >
                                                        <tbody className="table-body d-flex col-12 flex-wrap" >
                                                            {/* {gateWayId && <> */}
                                                            {selecteAll?.map((sensorData, index) => (
                                                                <td className='col-12 col-md-6 col-lg-4 col-xl-4' >
                                                                    <div>
                                                                        <Form.Check type="checkbox"
                                                                            {...register('SensorId')}
                                                                            checked={checkedSensor.includes(sensorData?.sensorId)}
                                                                            id={index + 1}
                                                                            value={sensorData?.sensorId}
                                                                            label={sensorData?.sensorName}
                                                                            onChange={(e) => handleCheck(e, index, sensorData?.sensorId)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    : <Modal.Title>No Sensor Available</Modal.Title>}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className='modalSave' type='submit' >Save Group</Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </div></div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <Table className="table-responsive-xxl">
                            <thead className="head">
                                <tr>
                                    <th>Sl.no</th>
                                    <th>Group Name</th>
                                    <th>Group Description</th>
                                    <th>Gateway Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-body point">
                                <>{sensorGroupDetails.sort((a, b) => b.id - a.id).slice(
                                    pagesVisited,
                                    pagesVisited +
                                    groupSensorDataPerPage
                                ).map((groupDetailsData, index) =>
                                (<tr >
                                    <td >{(pageNumber * groupSensorDataPerPage) + index + 1}</td>
                                    <td >{groupDetailsData.groupName}</td>
                                    <td >{groupDetailsData.groupDescription}</td>
                                    <td>{getGatewayById(groupDetailsData.groupDetails[0]?.gatewayId)}</td>
                                    <td><div className="d-flex justify-content-evenly point">
                                        <svg onClick={(e) => handleclick(groupDetailsData)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg> */}
                                    </div></td>
                                </tr>
                                ))}
                                </>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="">
                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={changePage}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            </div>
        </div>
         ) : (
            <Loader />
        )}
    </>
    )
}

export default Group