import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../assets/css/metricCard.css";


const TempDoughnut = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(loaderTimeout);
  }, []);

  const donut = [
    {
      donutTem: "highTemperature",
      name: "HT",
      color: "#a72a58e3",
      value: props?.temHumdonut?.tempHigh
    },
    {
      donutTem: "lowTemperature",
      name: "LT",
      color: "#307fdb",
      value: props?.temHumdonut?.tempLow
    },

  ];


  const noTemperature = [
    {
      donutTem: "highTemperature",
      name: "HT",
      color: "#d6d6d6",
      value: props?.temHumdonut?.tempHigh
    },
    {
      donutTem: "lowTemperature",
      name: "LT",
      color: "#d6d6d6",
      value: props?.temHumdonut?.tempLow
    },

  ];

  const cx = 50;
  const cy = 50;
  const strokeWidth = 20;
  const radius = 30;
  const dashArray = 2 * Math.PI * radius;
  const startAngle = -90;

  let filled = 0;

  const sum = donut.reduce((sum, item) => {
    return sum + item.value;
  }, 0);

  const ratio = 100 / sum;

  donut.forEach((obj) => {
    const itemRatio = ratio * obj.value;

    obj.itemRatio = itemRatio;
    obj.angle = (filled * 360) / 100 + startAngle;
    obj.offset = dashArray - (dashArray * itemRatio) / 100;
    obj.filled = filled;

    filled += itemRatio;
  });

  const handleNavigate = (TH) => {
    navigate("/admin/activesensors", {
      state: { ...props.temDonut, TH }
    })

  }

  const handleDisable = (data) => {
    navigate("/admin/activesensors", {
           state: { data }
         })
  }

  return (
    <>
      {showLoader ? (
        <div class="widgetLoader" id="loader-4">
          <span></span>
          <span></span>
          <span></span>
        </div>
      ) 
      :
        props?.temHumdonut?.tempHigh !== 0 || props?.temHumdonut?.tempLow !== 0 ? (
          <div className="donut-chart" >
            <svg className="point" width="130px" height="130px" viewBox="0 0 100 100">
              {donut.map((item, index) => (
                <circle onClick={() => handleNavigate(item.donutTem)}
                  key={index}
                  cx={cx}
                  cy={cy}
                  r={radius}
                  fill="transparent"
                  strokeWidth={strokeWidth}
                  stroke={item.color}
                  strokeDasharray={dashArray}
                  strokeDashoffset={item.offset}
                  transform={`rotate(${item.angle} ${cx} ${cy})`}
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from={`${startAngle} ${cx} ${cy}`}
                    to={`${item.angle} ${cx} ${cy}`}
                    dur="1s"
                  />
                  <title>
                    {item.name}: {item.value}
                  </title>
                </circle>
              ))}
            </svg>
            <ul className="items-names point">
              {donut.map((item, index) => (
                <li
                  key={index++}
                  className="item-name"
                  style={{
                    background: `linear-gradient(90deg, ${item.color} 0, ${item.color} 20px, transparent 20px, transparent 100%)`
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        ) : <div className="donut-chart" >
          <svg className="" width="130px" height="130px" viewBox="0 0 100 100">
            {noTemperature.map((item, index) => (
              <circle onClick={() => handleDisable("0")}
                key={index}
                cx={cx}
                cy={cy}
                r={radius}
                fill="transparent"
                strokeWidth={strokeWidth}
                stroke={item.color}
                strokeDasharray={dashArray}
                strokeDashoffset={item.offset}
                transform={`rotate(${item.angle} ${cx} ${cy})`}
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from={`${startAngle} ${cx} ${cy}`}
                  to={`${item.angle} ${cx} ${cy}`}
                  dur="1s"
                />

                <title>
                  {item.name}: {item.value}
                </title>
              </circle>
            ))}
          </svg>
          <ul className="items-names">
            {noTemperature.map((item, index) => (
              <li
                key={index++}
                className="item-name"
                style={{
                  background: `linear-gradient(90deg, ${item.color} 0, ${item.color} 20px, transparent 20px, transparent 100%)`
                }}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>}
    </>
  );
};
export default TempDoughnut;
