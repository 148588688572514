import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import CommonRoutes from "./routes/CommonRoutes";
import AdminRoutes from "./routes/AdminRoutes";

function App() {
    return (
        <>
            <BrowserRouter>
                <CommonRoutes />
                <AdminRoutes />
            </BrowserRouter>
        </>
    );
}

export default App;
