import React from "react";
import "../../assets/css/dashboard.css";
import { CircularProgressbar } from "react-circular-progressbar";
import { useState ,useEffect } from "react";
import SensorService from "../../Services/SensorService";
import moment from "moment";
import Loader from "../../common/Loader";
import { useLocation, useNavigate, useParams } from "react-router";
import { color } from "echarts";

const BatteryVoltage = () => {

      const[batteryreadings,setBatteryReadings] = useState([]);

      const minValue = 1.0;
      const maxValue = 3.9;

      // const voltageValues = [3.9,3.7,2.9,3]

      const batteryData = () => {
         SensorService.sensorRange().then((res) => (
         setBatteryReadings(res.data.data.response.sensorData)
         )).catch((err) => 
         console.log(err))
      };

      useEffect(() => {
        batteryData();
      }, [])

      const getDate = (dateStr) => {
        let result = moment(dateStr);
        return result.format('DD-MM-YYYY, h:mm:ss A');
      }

       const location = useLocation();

       const mainReadings = batteryreadings.filter(data => data.batteryVoltage != null && (location.state.noOfSensor == "positive" ? data.batteryVoltage >= 2.9 : data.batteryVoltage < 2.9)  &&
        data?.sensorDetails[0]?.sensorAvailability === "online")

 return (
    <>
    {batteryreadings.length > 0 ?  
        <div className="container">
            <div className="row">
              <div className="battery-cont">
                {mainReadings.length > 0 && <h5 className="view-text">Battery voltage</h5>}
                      <div className="row">
                                {mainReadings.length > 0 ? mainReadings?.map((voltage,index) => 
                                    {const sensorAvailability = voltage?.sensorDetails[0]?.sensorAvailability;
                                      const battery = voltage?.batteryVoltage; 
                                      const percentage = ((battery - minValue) / (maxValue - minValue)) * 100; 
                                      const color = battery <= 2.9 ? "#B73939" : "#18C18A";
                                return(
                                    <div key={index} className={`col-xs-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 ${sensorAvailability === "online" ? '' : 'disabled'}`}>
                                          <div className="battery-card">
                                                <div className="title-sec">
                                                        <p>{voltage?.sensorDetails[0]?.sensorName}</p>
                                                        <p>{voltage?.sensorDetails[0]?.sensorId}</p>
                                                </div>
                                                <div className="meter-sec">
                                                        <h5>Battery Voltage</h5>
                                                    <div style={{ width: "100px",marginTop:10,marginBottom:15 }}>
                                                        <CircularProgressbar counterClockwise = {true} value={percentage} text={`${battery}V`} styles={{ path: {stroke: color, },text:{fill:"#7572C1",fontSize:17}}}/>
                                                      {/* <CircularProgressbar counterClockwise = {true} value={percentage} text={`${voltage}V`} styles={{ path: {stroke: color, },text:{fill:"#7572C1",fontSize:17}}}/> */}
                                                    </div>
                                                </div>
                                                <div className="bottom-sec">
                                                    <p>{getDate(voltage?.readingDate)}</p>
                                                </div>
                                          </div> 
                                    </div>
                                    )})
                                    : 
                                    // <div className='space'>
                                    <div className="activeSensorNodata">
                                        <div className="d-flex justify-content-center align-items-center">
                                        <div className='view-text mt-5' >
                                            <h3>No Data Available</h3>
                                        </div>
                                        </div>
                                      
                                    </div>
                                /* </div> */
                                   
                                   
                                }
                        </div>
                    </div>
                  </div>
                </div> 
            : 
        <Loader />
        }
    </>
  );
};

export default BatteryVoltage;
