import React from "react";
import { useState, useEffect } from "react";
import "../../../assets/css/dataView.css";
import Temperature from "./Temperature";
import { useNavigate } from "react-router-dom"
import Batteryhealth from "./BatteryHealth";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import SensorService from "../../../Services/SensorService";
import Newgraph from "./Newgraph";
import { useLocation } from 'react-router'
import DeviceReportService from '../../../Services/DeviceReportService';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Toasters from "../../../common/Toaster";
import BasicSettingService from '../../../Services/BasicSettingService'
import Loader from "../../../common/Loader";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
// import Select from 'react-select'


export default function Index(props) {
    const [sensorDetails, setSensorDetails] = useState([])
    const [sensorOption, setSensorOption] = useState([])
    const [sensorDailyReportData, setSensorDailyReportData] = useState([])
    const [errorMessage, setErrorMessage] = useState({})
    const [displayToast, setdisplayToast] = useState(false)
    const [clientOption, setClientOption] = useState([])
    const [clientDetails, SetClientDetails] = useState([])
    const [userLoaded, setUserLoaded] = useState(true);
    const [tabSwitch, SetTabSwitch] = useState("Temperature");
    const [readings,setReadings] = useState({});
        

    const validation = Yup.object({
        date: Yup.date().max(new Date(), 'Select a Valid Date').min(new Date(new Date().setMonth(new Date().getMonth() - 3)), 'Available Data 3 Months Only'),
        SensorId: Yup.object().required('*Please Select Sensor'),
        Client: Yup.object().required('*Please Select Client')

    })

    const { register, clearErrors, handleSubmit, setValue, control, watch, reset, formState: { errors } } = useForm({
        mode: "onChange", resolver: yupResolver(validation)
    });

    const navigate = useNavigate();
    const location = useLocation()


    let watchClientId = watch("Client");
    let sensorId = watch("SensorId");
    let date = watch("date")

    const currentDate = new Date().toISOString().split('T')[0];

    const payload = {
        "clientId": watchClientId?.value || props?.sensorId?.sensorDetails[0]?.clientId,
        "sensorId": sensorId?.value || props?.sensorId?.sensorId,
        "date": date
    }

    useEffect(() => {
        if ((watchClientId?.value && sensorId && date) || (props?.sensorId?.sensorDetails[0]?.clientId && props?.sensorId?.sensorId && date)) {
            sensorDailyReportDetails(payload)
            setUserLoaded(true)
        }
    }, [sensorId, date, props?.sensorId?.sensorId]);

    useEffect(() => {
      if(sensorId?.value){
        const payload = {
            "sensorId": sensorId?.value
        }
        settemperatureReadings(payload);
      }
    }, [sensorId?.value])
    

    useEffect(() => {
        if (watchClientId) {
            allSensorDetails(watchClientId?.value)
            setSensorOption([])
        }

    }, [watchClientId])

    useEffect(() => {
        clientsInfoDetails()
    }, []);

    useEffect(() => {

        if (clientDetails) {
            let clientData = [];
            clientDetails !== undefined &&
                clientDetails?.length > 0 &&
                clientDetails.map((x, key) => {
                    var list = { label: x.clientName, value: x.id };
                    clientData.push(list);
                    setClientOption(clientData);
                });
        }

    }, [clientDetails]);

    useEffect(() => {

        if (sensorDetails) {
            let sensorDetailsData = [];
            sensorDetails !== undefined &&
                sensorDetails?.length > 0 &&
                sensorDetails.map((x, key) => {
                    var list = { label: x.sensorName, value: x.id };
                    sensorDetailsData.push(list);
                    setSensorOption(sensorDetailsData);
                });
        }

    }, [sensorDetails])



    useEffect(() => {

        if (sensorDetails) {
            let sensorDetailsData = [];
            sensorDetails !== undefined &&
                sensorDetails?.length > 0 &&
                sensorDetails.map((x, key) => {
                    var list = { label: x.sensorName, value: x.sensorId };
                    sensorDetailsData.push(list);
                    setSensorOption(sensorDetailsData);
                });
        }

    }, [sensorDetails])

    const allSensorDetails = (params) => {
        SensorService.getSensorByClientId(params)
            .then((res) => (
                setSensorDetails(res.data.data.response.sensorData),
                setUserLoaded(false)
            )).catch((error) => {

            })
    };

    const clientsInfoDetails = () => {
        BasicSettingService.getClients()
            .then((res) => (
                SetClientDetails(res.data.data.response.clientData),
                setUserLoaded(false)
            )
            ).catch((error) => {


            })
    };

    const sensorDailyReportDetails = (params) => {
        DeviceReportService.getDailyReport(params)
            .then((res) => (
                setSensorDailyReportData(res?.data?.data?.response?.sensorData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage({ 'message': error.response.data.message, "status": "error" })
                setdisplayToast(true)
                setUserLoaded(false)
            })

    }

    const settemperatureReadings = (sensorId) => {
        DeviceReportService.getDeviceReadings(sensorId)
        .then((res) => {
            setReadings(res?.data?.data?.response?.sensorData)
        }).catch((error) => {
            setErrorMessage({ 'message': error.response.data.message, "status": "error" })
            setdisplayToast(true)
            setUserLoaded(false)
        })
    }

    const handleToastClose = () => {
        setdisplayToast(false)
        setErrorMessage({})
    }

    const options = [
        { value: 'Temperature', label: 'Temperature' },
        { value: 'Humidity', label: 'Humidity' },
    ]

    const handleReadings = (selectedOption) => {
        SetTabSwitch(selectedOption?.value)
    }



    return (
        <>{displayToast && (<Toasters messages={errorMessage} handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <>
                    <div className="dataview">
                        <div className="row">
                            <div className="col-12 col-md-6  col-lg-2 col-xl-2">
                                <h3 className="dataview-title point" onClick={() => navigate(-1)}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"

                                        className="me-3"
                                        width="26"
                                        height="26"
                                        fill="currentColor"
                                        class="bi bi-arrow-left-short"
                                        viewBox="0 0 16 16"
                                        id="IconChangeColor"
                                    >
                                        {" "}
                                        <path
                                            fill-rule="evenodd"
                                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                            id="mainIconPathAttribute"
                                            stroke-width="6"
                                        ></path>{" "}
                                    </svg>
                                    Back
                                </h3>
                            </div>

                            {location?.state?.deviceReport == "DeviceReport" &&
                                <>
                                    <Form.Group className="col-12 col-md-6 col-lg-2 col-xl-2">
                                        <div >
                                            {
                                                sensorDailyReportData?.length > 0 && !props.sensorId?.sensorId ?
                                                    <>
                                                        <Form.Label>Select Condition</Form.Label>
                                                        <Select options={options} defaultValue={options?.find(option => option?.value === tabSwitch)} onChange={(e) => handleReadings(e)} />
                                                    </> : ""
                                            }
                                        </div>
                                        <Form.Label>Select Client</Form.Label>
                                        <Controller
                                            control={control}
                                            name="Client"
                                            render={({ field }) =>
                                                <Select
                                                    {...field}
                                                    name="Client"
                                                    classNamePrefix="select"
                                                    options={clientOption}
                                                />} />
                                        <span className="error-text">
                                            {errors.Client?.message}
                                        </span>
                                    </Form.Group>
                                    <Form.Group className="col-12 col-md-6 col-lg-2 col-xl-2">

                                        <Form.Label>Select Sensor</Form.Label>
                                        <Controller
                                            control={control}
                                            name="SensorId"
                                            render={({ field }) =>
                                                <Select
                                                    {...field}
                                                    name="SensorId"
                                                    classNamePrefix="select"
                                                    options={sensorOption}
                                                />} />
                                        <span className="error-text">
                                            {errors.SensorId?.message}
                                        </span>
                                    </Form.Group>
                                </>
                            }
                            {props.sensorId &&
                                <Form.Group className="col-12 col-md-6 col-lg-2 col-xl-2">
                                    <Form.Label>Sensor ID</Form.Label>
                                    <Form.Control disabled
                                        placeholder={props?.sensorId?.sensorId}
                                    />
                                </Form.Group>
                            }
                            <Form.Group className="dataview-select col-12 col-md-6 col-lg-2 col-xl-2">
                                <Form.Label>Select Date</Form.Label>
                                <Controller
                                    control={control}
                                    name="date"
                                    defaultValue={currentDate}
                                    render={({ field }) =>
                                        <Form.Control
                                            {...field}
                                            name="date"
                                            type="date"
                                            max={new Date().toISOString().split('T')[0]}
                                        />} />
                                <span className="error-text">
                                    {errors.date?.message}
                                </span>

                            </Form.Group>
                        </div>

                        <div className="chart">
                            <div className="row m-0">
                                <div className="col-lg-8 col-md-12 p-0">
                                    <div className="line-chart box-s bg-white">

                                        <div className="graph-outline">
                                            <div className="graph-outline2">
                                                <Newgraph graphDetails={sensorDailyReportData} selectedSensor={sensorId} selectGraphType={props?.selectSensorType || tabSwitch} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 p-0">
                                    <div className="temp-health">
                                        <div className="row m-0">
                                            <div className="col-lg-12 col-sm-6 p-0">
                                                <div className="charts-dataview rmr-10 box-s bg-white mb-dataview-20">
                                                    {/* <button onClick={() => handleClick()}></button> */}
                                                    <Temperature readingDetails = { props?.sensorId || readings } switchTab={props?.selectSensorType || tabSwitch}/>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-sm-6 p-0">
                                                <div className="charts-dataview rml-10 box-s bg-white mtr-20">
                                                    <Batteryhealth batteryHealth={sensorDailyReportData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<Loader />
            )} </>
    );
}
