import axios from "axios";
import AuthHeader from "./AuthHeader";

const API_URL = process.env.REACT_APP_BASE_URL;

const deviceDetails = () => {
    return axios.get(API_URL +"master/sensordetails/1",{ headers: AuthHeader() });
};

const dailySummaryDetails =async (params) => {
    return await axios.post(API_URL +"sensorresource/sensordata/dailysummary",params,{ headers: AuthHeader() });
};

const qaReportDetails =async (params) => {
    return await axios.post(API_URL +"sensorresource/sensordata/qasummary",params,{ headers: AuthHeader() });
};

const allDailySummaryDetails =async (params) => {
    return await axios.post(API_URL +"sensorresource/sensordata/dailysummary/all",params,{ headers: AuthHeader() });
};

const allQaReportDetails =async (params) => {
    return await axios.post(API_URL +"sensorresource/sensordata/qasummary/all",params,{ headers: AuthHeader() });
};

const getNotificationReportDetails = async (params) => {
    return await axios.post(API_URL + "notificatoinsresource/notifications/report", params, { headers: AuthHeader() });
};

const dailyHumiditySummaryDetails = async (params) => {
    return axios.post(API_URL +"sensorresource/humidity/dailysummary",params,{headers:AuthHeader()});
}

const allDailyHumiditySummaryDetails = async(params) => {
    return await axios.post(API_URL+"sensorresource/humidity/dailysummary/all",params,{headers:AuthHeader()})
}

const dailyHumidityQaSummary = async(params) => {
    return await axios.post(API_URL + "sensorresource/humidity/qasummary",params,{headers:AuthHeader()});
} 

const allSensorhumidityQaSummary = async(params) => {
    return await axios.post(API_URL+"sensorresource/humidity/qasummary/all",params,{headers:AuthHeader()})
}

const ReportServices = {
    deviceDetails,
    dailySummaryDetails,
    qaReportDetails,
    allDailySummaryDetails,
    allQaReportDetails,
    getNotificationReportDetails,

    dailyHumiditySummaryDetails,
    allDailyHumiditySummaryDetails,
    dailyHumidityQaSummary,
    allSensorhumidityQaSummary
};



export default ReportServices;