import React, { useState, useEffect } from 'react'
import { Badge, Button, FormGroup, Table } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import BasicSettingService from '../../Services/BasicSettingService'
import Toasters from "../../common/Toaster";
import ReactPaginate from "react-paginate";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Loader from '../../common/Loader';


const validation = Yup.object({
    roles: Yup.object().required('*Required').nullable(),
    screen: Yup.object().required('*Required').nullable(),

})

function Policy() {
    const [rolesDetails, setRolesDetails] = useState([])
    const [screenDetails, setScreenDetails] = useState([])
    const [widgetsDetails, setWidgetsDetails] = useState([])
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState({})
    const [rolesOption, setRolesOption] = useState([])
    const [permission, setPermission] = useState({})
    const [editPermission, setEditPermission] = useState({})
    const [editValue, setEditValue] = useState(false)
    const [editId, setEditId] = useState({})
    const [widgetScreenDetails, setWidgetScreenDetails] = useState([])
    const [displayToast, setdisplayToast] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState({})
    const [expanded, setExpanded] = useState(false);
    const [screenPermission, setScreenPermission] = useState([]);
    const [userLoaded, setUserLoaded] = useState(true);



    const handleChange = (panel, screenDataId) => (event, isExpanded) => {
        getWidgetsOption(screenDataId)
        setExpanded(isExpanded ? panel : false);
    };

    const { register, clearErrors, handleSubmit, setValue, watch, control, reset, formState: { errors } } = useForm(
        {
            resolver: yupResolver(validation)
        }
    );

    useEffect(() => {
        rolesInfoDetails()
        getScreenOptionData()
    }, []);

    useEffect(() => {
        if (permission?.status === 200) {
            handleClose()
            rolesInfoDetails();
            setdisplayToast(true)
        }
    }, [permission]);

    useEffect(() => {
        if (editPermission?.status === 200) {
            setScreenPermission([])
            handleClose()
            rolesInfoDetails();
            setWidgetScreenDetails([])
            setdisplayToast(true)
        }
    }, [editPermission]);

    useEffect(() => {

        if (rolesDetails) {
            let rolesData = [];
            rolesDetails !== undefined &&
                rolesDetails?.length > 0 &&
                rolesDetails.map((x, key) => {
                    var list = { label: x.roleName, value: x.id };
                    rolesData.push(list);
                    setRolesOption(rolesData);
                });
        }

    }, [rolesDetails]);


    const permissionDataPerPage = 10;
    const pagesVisited = pageNumber * permissionDataPerPage;
    const pageCount = Math.ceil(rolesDetails.length / permissionDataPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const rolesInfoDetails = () => {
        BasicSettingService.getRoles()
            .then((res) => (
                setRolesDetails(res.data.data.response.userRolesData),
                setUserLoaded(false)

            )).catch((error) => {
                setErrorMessage(error.response.data.message)
                setdisplayToast(true)

            })
    }

    const EditPermission = (params, editId) => {
        BasicSettingService.editPermission(params, editId).then((res) => (
            setEditPermission(res),
            setUserLoaded(false)
        )
        ).catch((error) => {
            setErrorMessage(error.response.data?.validationErrorList[0]?.message)
            setdisplayToast(true)

        })
    }
    const getScreenOptionData = () => {
        BasicSettingService.getScreenOption()
            .then((res) => (
                setScreenDetails(res?.data?.data?.response?.userScreenData),
                setUserLoaded(false)
            )).catch((error) => {
                setErrorMessage(error.response.data.message)
                setdisplayToast(true)

            })
    };

    const getWidgetsOption = (id) => {
        BasicSettingService.getWidgetsOption(id)
            .then((res) => (
                setWidgetsDetails(res?.data?.data?.response?.userScreenWidget),
                setUserLoaded(false)
            ))

    };

    const filterObject = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.value?.toString() === value?.toString()
            );

        if (filtered[0]) {

            setValue(title, filtered[0]);
        } else {
            setValue(title, { label: value, value: value })
        }
    };


    const handleclick = (data) => {
        data.screens.map((data, index) => {
            let obj = { ...screenPermissionData }
            obj["screenId"] = data.screenId;
            obj["widgetId"] = []; // Clear the widgetId array for each screenId

            data.widgets.map(widget => {
                if (widget?.widgetId && !obj["widgetId"]?.includes(widget?.widgetId)) {
                    obj["widgetId"].push(widget?.widgetId)
                }
            })
            screenPermission.push(obj)
        })
        setScreenPermission([...screenPermission])

        setEdit(data)
        setShow(true)
        filterObject("roles", rolesOption, data?.id);
        setEditValue(true)
        setEditId(data?.id)

    }


    const handleClose = () => {
        setScreenPermission([])
        reset()
        clearErrors();
        setEditValue(false)
        setShow(false)
        setWidgetScreenDetails([])
        setEdit({})

    };

    const declineDuplicateId = (screenIds) => {
        const uniqueScreenIds = [...new Set(screenIds.map(data => data.screenId))];
        let str = "";
        uniqueScreenIds.forEach((screenId, index) => {
            const filteredOptions = screenDetails.filter(option => option.id.toString() === screenId.toString());
            const labels = filteredOptions.map(option => option.displayName
            );

            str += labels.join(", ");

            if (index < uniqueScreenIds.length - 1) {
                str += ", ";
            }
        });

        return str;
    };

    const handleToastClose = () => {
        setPermission({})
        setEditPermission({})
        setdisplayToast(false)
        setErrorMessage({})
        setWidgetScreenDetails([])
    };


    const screenPermissionData = {
        screenId: null,
        widgetId: []
    }
    const handleCheckBoxChange = (e, widgetId, screenId) => {
        let index = screenPermission.findIndex(data => data.screenId == screenId);
        if (e.target.checked) {
            if (index === -1) {
                // Create a new screen permission object if it doesn't exist
                screenPermission.push({
                    screenId: screenId,
                    widgetId: [widgetId]
                });
            } else {
                // Update the existing screen permission object
                if (!screenPermission[index].widgetId.includes(widgetId)) {
                    screenPermission[index].widgetId.push(widgetId);
                }
            }
        } else {
            if (index !== -1) {
                // Remove the widget ID from the existing screen permission object
                screenPermission[index].widgetId.splice(screenPermission[index].widgetId.indexOf(widgetId), 1);

                // If no widget IDs are left for the screen, remove the screen permission object
                if (screenPermission[index].widgetId.length === 0) {
                    screenPermission.splice(index, 1);
                }
            }
        }
        setScreenPermission([...screenPermission]);
    };




    // const handleCheckBoxChange = (e,widgetId, screenId) => {
    //     if (screenPermission.findIndex(data => data.screenId !== screenId) === -1) {
    //         handleScreenCheck(e, index, screenId)
    //     }
    //     let index = screenPermission.findIndex(data => data.screenId == screenId )
    //     if (e.target.checked) {
    //         screenPermission[index]["screenId"] = screenId
    //         if (!screenPermission[index]["widgetId"]?.includes(widgetId)) {
    //             if (screenPermission[index]["widgetId"]) {
    //                 screenPermission[index]["widgetId"] = [...screenPermission[index]["widgetId"], widgetId]
    //             } else {
    //                 screenPermission[index]["widgetId"] = [widgetId]
    //             }
    //         }
    //     } else {
    //         if (screenPermission[index]["widgetId"]?.includes(widgetId)) {
    //             screenPermission[index]["widgetId"].splice(screenPermission[index]["widgetId"].indexOf(widgetId), 1)
    //         }
    //     }
    //     setScreenPermission([...screenPermission])
    // }

    const handleScreenCheck = (e, index, screenId) => {
        if (e.target.checked && !screenPermission.find(data => data.screenId == screenId)) {
            screenPermission.push(screenPermissionData)
            let i = screenPermission.lastIndexOf(screenPermissionData)
            screenPermission[i]["screenId"] = screenId
        } else if (!e.target.checked) {
            let data = screenPermission.find(data => data.screenId == screenId)
            let i = screenPermission.indexOf(data)
            if (i !== -1) {
                screenPermission.splice(i, 1)
            }



        }
        setScreenPermission([...screenPermission])
    }

    const onSubmit = (e, data) => {
        e.preventDefault()
        const payload = {
            "roleId": data?.id,
            "screens": screenPermission
        }

        if (editValue) {
            EditPermission(payload, editId)
        }
    };


    return (
        <>{displayToast && (<Toasters
            messages={permission?.data?.data?.response?.status || editPermission?.data?.data?.response?.status || errorMessage}
            handleToastClose={handleToastClose} />)}
            {!userLoaded ? (
                <div className="">
                    <div className="space commonTable">
                        <div className=" heading ">Permission
                            <div className="d-flex justify-content-end">
                                <Modal size='lg' show={show} onHide={handleClose}>

                                    <Modal.Header closeButton>
                                        <Modal.Title>{!edit.roleName ? "Add Permission" : "Edit Permission"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3 col-12" >
                                            <Form.Label>Roles</Form.Label>
                                            <Controller
                                                control={control}
                                                name="roles"
                                                render={({ field }) =>
                                                    <Select
                                                        {...field}
                                                        isDisabled={edit.roleName}
                                                        name="role"
                                                        classNamePrefix="select"
                                                        options={rolesOption} />} />
                                        </Form.Group>

                                        <div className='row p-2'>
                                            {screenDetails?.map((screenDataId, index) => {
                                                const panelId = `panel${index + 1}`;
                                                return (
                                                    <Accordion className='col-12 col-md-6 col-lg-4 col-xl-4 mb-2' expanded={widgetsDetails.length > 0 ? expanded === panelId : null}
                                                        onChange={handleChange(panelId, screenDataId.id)} key={panelId}>
                                                        <AccordionSummary
                                                            expandIcon={(screenPermission?.find(data => (data?.widgetId?.length > 0 && data?.screenId == screenDataId.id)) || widgetsDetails.length > 0 && widgetsDetails[0].screenId == screenDataId.id) ? <ExpandMoreIcon /> : <></>}
                                                            aria-controls={`${panelId}-content`}
                                                            id={`${panelId}-header`}
                                                        >
                                                            <Typography >
                                                                <Form.Check id={screenDataId?.id + "widget"}
                                                                    label={screenDataId?.displayName}
                                                                    checked={!!screenPermission?.find(data => (data?.screenId == screenDataId?.id))}
                                                                    onChange={(e) => handleScreenCheck(e, index, screenDataId.id)}
                                                                />
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                {widgetsDetails?.map((widgetIcon, widgetIndex) => {
                                                                    return (
                                                                        <div className='checkBoxPermision' key={widgetIndex}>
                                                                            <Form.Check id={widgetIcon?.id}
                                                                                label={widgetIcon?.displayName}
                                                                                checked={!!screenPermission?.find(widget => widget?.screenId == screenDataId.id)?.widgetId.includes(widgetIcon?.id)}
                                                                                onChange={(e) => handleCheckBoxChange(e, widgetIcon?.id, screenDataId.id)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })}
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className='modalSave' onClick={(e) => onSubmit(e, edit)}>Save Changes </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div></div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <Table className="table-responsive-xxl">
                                    <thead className="head">
                                        <tr>
                                            <th>Sl.no</th>
                                            <th>Roles</th>
                                            <th>Screen</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-body">
                                        <>{rolesDetails.sort((a, b) => b.id - a.id).slice(
                                            pagesVisited,
                                            pagesVisited +
                                            permissionDataPerPage
                                        ).map((rolesSelect, index) => (
                                            <tr>
                                                <td>{(pageNumber * permissionDataPerPage) + index + 1}</td>
                                                <td>{rolesSelect?.roleName}</td>
                                                <td>{declineDuplicateId(rolesSelect?.screens)}</td>
                                                <td><div className="d-flex justify-content-evenly point">
                                                    <svg onClick={(e) => handleclick(rolesSelect)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#307fdb" class="bi bi-pencil" viewBox="0 0 16 16">
                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                    </svg>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#b73939" class="bi bi-trash3" viewBox="0 0 16 16">
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg> */}
                                                </div></td>
                                            </tr>
                                        ))}
                                        </>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>)
}

export default Policy